import React, { Component, Fragment } from 'react';
import TradeSchemeService from '../../services/TradeSchemeService';
import { Table, Col, Row, Card, CardBody } from 'reactstrap';
import Ionicon from 'react-ionicons';
import Sidebar from 'react-sidebar';

import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

import TradeSchemeFilter from '../../modal/TradeSchemeFilter/TradeSchemeFilter';
import { generateRandomString, downlodFile } from '../../utils/utils';
import StorageService from '../../services/Storage';

import './TradeSchemeReport.css';
import GoogleAnalticsService from '../../services/GoogleAnalticsService';
import { Roles } from '../../constants/constant.role';

class TradeSchemeReport extends Component {

  state = {
    schemeReport: [],
    currentUser: null,
    manageUserLevel: [],
    dealerRole: null,
    rolesToDrillDown: [],
    randomKey: null,
    isLoading: false,
    sidebarOpen: false,
    filterControls: {},
    reviewAchievement: 'month',
    overall: {},
    label: null
  }
  /**
   *  sapId: currentData.sapId,
      uuid: currentData.uuid,
      businessName: currentData.business_name
      achievementSummary: const percentObj = {
      '0': 0,
      '10': 0,
      '20': 0,
      '30': 0,
      '40': 0,
      '50': 0,
      '60': 0,
      '70': 0,
      '80': 0,
      '90': 0,
      '100': 0,
    }
   * 
   * 
   */

  constructor() {
    super();
    this.childTradeSchemeFilter = React.createRef();
  }

  componentDidMount() {
    GoogleAnalticsService.event('Trade Scheme', 'Trade Scheme View');
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');
    let index = query.get('i');
    let reviewAchievement = query.get('review_achievement');
    reviewAchievement = reviewAchievement ? reviewAchievement : 'month';
    if (!(reviewAchievement === 'month' || reviewAchievement === 'quarter')) {
      reviewAchievement = 'month'
    }
    this.setState({ reviewAchievement })
    let manageUserLevel = [];
    // let currentUser = null;
    if (id && index) {
      index = isNaN(index) === false ? parseInt(index) : 0;
      this.setState({ randomKey: id });
      manageUserLevel = StorageService.getNavigationLevelDetail(id);
      this.setState({ manageUserLevel: manageUserLevel });
      this.getDataFromNavigation(index, manageUserLevel, reviewAchievement);
    } else {
      this.getSchemePercentage(null, reviewAchievement);
    }
    // if (id) {
    //   this.setState({ randomKey: id });
    //   manageUserLevel = StorageService.getNavigationLevelDetail(id);
    //   const length = manageUserLevel.length;
    //   if (length > 0) {
    //     currentUser = manageUserLevel[length - 2];
    //     const uuid = currentUser && currentUser.uuid ? currentUser.uuid : null
    //     this.getAllDealerReport(page, null, false, uuid);
    //   } else {
    //     this.getAllDealerReport(page);
    //   }
    // } else {
    //   this.getAllDealerReport(page);
    // }
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type });
    this.generateRolesToDrillDown(type);

  }

  generateRolesToDrillDown = (dealerRole) => {
    let rolesToDrillDown = []
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      rolesToDrillDown = ['ZM', 'SM', 'AM', 'dealer']
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      rolesToDrillDown = ['SM', 'AM', 'dealer']
    } else if (dealerRole === Roles.ZTM_ROLE) {
      rolesToDrillDown = ['SM', 'AM', 'dealer']
    }
    else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      rolesToDrillDown = ['AM', 'dealer']
    } else if (dealerRole === Roles.AREA_MANAGER_ROLE) {
      rolesToDrillDown = ['dealer']
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      rolesToDrillDown = ['dealer']
    }
    this.setState({ rolesToDrillDown });
  }

  getSelectedDealerDetail = (uuid, name, reviewAchievement) => {
    let { rolesToDrillDown, manageUserLevel } = this.state;
    let length = manageUserLevel.length;
    let role = rolesToDrillDown[length];
    const currentUser = {
      uuid,
      name,
      role
    }
    manageUserLevel.push(currentUser);
    this.setState({ page: 1, currentUser, manageUserLevel });
    this.getSchemePercentage(uuid, reviewAchievement);
  }

  getDataFromNavigation = (index, manageUserLevel = null, reviewAchievement) => {
    if (!manageUserLevel) {
      manageUserLevel = this.state.manageUserLevel;
    }
    manageUserLevel = manageUserLevel.slice(0, index);
    const userData = manageUserLevel[index - 1];
    if (userData && userData.uuid) {
      let uuid = userData.uuid;
      this.getSchemePercentage(uuid, reviewAchievement);
      // this.getAllDealerReport(1, null, null, uuid);
    } else {
      this.getSchemePercentage(null, reviewAchievement);
      // this.getAllDealerReport(1);
    }
    this.setState({
      page: 1, currentUser: userData,
      manageUserLevel: manageUserLevel
    });
  }


  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return 'All India'
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return 'Zone Manager'
    } else if (dealerRole === Roles.ZTM_ROLE) {
      return "Zonal Trade Marketing";
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return 'Region Manager'
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return 'Sales Officer'
    } else {
      return 'Territory Manager'
    }
  }

  getSchemePercentage = (uuid = null, reviewAchievement) => {
    this.setState({ isLoading: true });
    TradeSchemeService.getSchemePercentage(uuid, reviewAchievement)
      .then(data => {
        this.setState({ isLoading: false });
        const schemeReport = data.data.data.data;
        const overall = data.data.data.overall;
        const label = data.data.data.label;
        this.setState({ schemeReport, overall, label });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  selectDealer = (scheme) => {
    const { uuid } = scheme;
    const { reviewAchievement } = this.state;
    this.getSchemePercentage(uuid, reviewAchievement);
  }

  applyFilter = (controls, businessName) => {
    const reviewAchievement = controls.review_achievement.value;

    let uuid = null;
    let currentUser = null;
    this.onCloseSidebar();
    if (controls.zone.value) {
      uuid = controls.zone.value;
    }
    if (controls.region.value) {
      uuid = controls.region.value;
    }
    if (controls.area.value) {
      uuid = controls.area.value;
    }

    const navigation = [];
    if (controls.zone.value) {
      const navObj = {
        // name: mapZoneToZoneName[controls.zone.value],
        name: businessName.zone,
        role: 'ZM',
        uuid: controls.zone.value
      }
      currentUser = navObj;
      navigation.push(navObj);
    }

    if (controls.region.value) {
      const navObj = {
        name: businessName.region,
        role: 'SM',
        uuid: controls.region.value
      }
      currentUser = navObj;
      navigation.push(navObj);
    }

    // if (controls.area.value) {
    //   // const navObj = {
    //   //   name: businessName.area,
    //   //   role: 'AM',
    //   //   uuid: controls.area.value
    //   // }
    //   // navigation.push(navObj);
    // }

    this.setState({
      filterControls: controls,
      manageUserLevel: navigation,
      reviewAchievement: reviewAchievement,
      currentUser: currentUser
    }, () => {
      if (controls.area.value) {
        this.openTradeSchemeDealerPage(controls.area.value, businessName.area);
      } else {
        this.getSchemePercentage(uuid, reviewAchievement);
      }
    })
  }


  onCloseSidebar = () => {
    document.body.style.overflow = 'auto';
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: false };
    });
  }

  onSetSidebarOpen = () => {
    document.body.style.overflow = 'hidden';
    // if (this.childTradeSchemeFilter.current) {
    //   this.childTradeSchemeFilter.current.setFilter(this.state.filterControls, this.state.manageUserLevel);
    // }
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: true };
    });
  }


  openTradeSchemeDealerPage = (uuid, name, min = null) => {
    let { rolesToDrillDown, manageUserLevel, randomKey,
      // filterControls,
      reviewAchievement: reviewAchievementState } = this.state;
    // const reviewAchievement = filterControls &&
    //   filterControls.review_achievement &&
    //   filterControls.review_achievement.value ?
    //   filterControls.review_achievement.value : null;

    let length = manageUserLevel.length;
    let role = rolesToDrillDown[length];
    const currentUser = {
      uuid,
      name,
      role
    }
    manageUserLevel.push(currentUser);
    let randomId = null;
    if (randomKey) {
      randomId = randomKey;
    } else {
      randomId = generateRandomString();
    }
    StorageService.setNavigationLevelDetail(randomId, manageUserLevel);

    let url = `/trade-scheme-dealers?k=${randomId}&uuid=${uuid}`;
    if (min !== null) {
      url = `${url}&min=${min}`
    }
    // if (reviewAchievement) {
    //   url = `${url}&review_achievement=${reviewAchievement}`
    // } else 
    if (reviewAchievementState) {
      url = `${url}&review_achievement=${reviewAchievementState}`
    }
    this.props.history.push(url)
  }

  downloadReport = () => {
    const { currentUser } = this.state;
    let uuid = null;
    if (currentUser && currentUser.uuid) {
      uuid = currentUser.uuid;
    }
    TradeSchemeService.downloadTradeSchemeExcel(uuid)
      .then(data => {
        const file = data.data && data.data.data && data.data.data.file ? data.data.data.file : null;
        if (file) {
          downlodFile(file);
        }
      })
      .catch(e => {

      })
  }

  getDrillDownLabel = (dealerRole, currentUser) => {
    const currentUserRole = currentUser && currentUser.role ? currentUser.role : null;
    if (currentUserRole === 'ZM') {
      return `Drill down to Territory`;
    }
    if (currentUserRole === 'SM') {
      return `View Dealers Details`;
    }
    if (!currentUserRole) {
      if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
        return `Drill down to Region`;
      }
      if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
        return `Drill down to Territory`;
      }
      if (dealerRole === Roles.REGION_MANAGER_ROLE) {
        return `View Dealers Details`;
      }
      if (dealerRole === Roles.AREA_MANAGER_ROLE) {
        return `View Dealers Details`;
      }
    }
  }

  render() {
    const { schemeReport, manageUserLevel, currentUser, dealerRole, isLoading, sidebarOpen, overall, label, reviewAchievement } = this.state;
    const manageUserLevelLength = manageUserLevel ? manageUserLevel.length : 0;
    const pageTitle = this.getPageTitle();
    const drillDownLabel = this.getDrillDownLabel(dealerRole, currentUser);
    const mapToRoleLabel = {
      'SM': 'Region',
      'AM': 'Territory'
    }
    const navigation = this.state.manageUserLevel.map((m, index) => <Fragment>
      <span>
        <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
      </span>
      {index !== manageUserLevelLength - 1 && <span className="prev-page-link cursor-pointer title-case" onClick={this.getDataFromNavigation.bind(this, index + 1, null, reviewAchievement)}>
        {m.name}{' '}{m.role !== 'ZM' ? `(${mapToRoleLabel[m.role]})` : null}
      </span>}
      {index === manageUserLevelLength - 1 && <span className="current-page title-case">
        {m.name}{' '}{m.role !== 'ZM' ? `(${mapToRoleLabel[m.role]})` : null}
      </span>}
    </Fragment>);

    const prepareReportRows = schemeReport && schemeReport.map(s => {
      const achievementSummary = s.achievementSummary;
      return <Card className="margin-top-5">
        <CardBody>
          <Row className="margin-bottom-20">
            <Col className="text-align-left">
              <div className="single-dealer-table-card-header">
                <span className="title-case">{s.businessName} </span>
              </div>
            </Col>
            <Col className="text-align-right download-report-text">
              {/* <div onClick={this.props.openViewReportPage.bind(this, dealer.uuid, dealer.name)}>View detailed report </div> */}
              <span className="cursor-pointer" onClick={
                (currentUser && currentUser.role && currentUser.role === 'SM') || (dealerRole === Roles.REGION_MANAGER_ROLE || dealerRole === Roles.AREA_MANAGER_ROLE || dealerRole === Roles.SALES_OFFICER_ROLE) ?
                  this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, null) :
                  this.getSelectedDealerDetail.bind(this, s.uuid, s.businessName, reviewAchievement)
              }>{drillDownLabel} </span>
            </Col>
          </Row>
          <Table>
            <tr>
              <th>%Achievement</th>
              <th>Above 100%</th>
              <th>90-99%</th>
              <th>80-89%</th>
              <th>70-79%</th>
              <th>60-69%</th>
              <th>50-59%</th>
              <th>40-49%</th>
              <th>30-39%</th>
              <th>20-29%</th>
              <th>10-19%</th>
              <th>0-9%</th>
            </tr>
            <tr>
              <td>No. Of Active Dealers</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 100)} className="link-blue">{achievementSummary['100']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 90)} className="link-blue">{achievementSummary['90']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 80)} className="link-blue">{achievementSummary['80']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 70)} className="link-blue">{achievementSummary['70']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 60)} className="link-blue">{achievementSummary['60']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 50)} className="link-blue">{achievementSummary['50']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 40)} className="link-blue">{achievementSummary['40']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 30)} className="link-blue">{achievementSummary['30']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 20)} className="link-blue">{achievementSummary['20']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 10)} className="link-blue">{achievementSummary['10']}</td>
              <td onClick={this.openTradeSchemeDealerPage.bind(this, s.uuid, s.businessName, 0)} className="link-blue">{achievementSummary['0']}</td>
            </tr>
          </Table>
        </CardBody>
      </Card>
    })


    return <div id="trade-scheme-report">

      {
        sidebarOpen &&
        <Sidebar
          sidebar={<>
            <div className="drawer-header">
              <div>
                <span className="drawer-title">Filters</span>&nbsp;&nbsp;
                <img src="/images/filter.svg" alt="" />
              </div>
              <img className="cursor-pointer" src="images/close.svg" alt="" onClick={() => { this.onCloseSidebar(); }} />
            </div>
            <hr style={{ border: 'solid 1px #ced4da' }} />
            <TradeSchemeFilter
              filterControls={this.state.filterControls}
              applyFilter={this.applyFilter}
              navigation={this.state.manageUserLevel}
              reviewAchievement={this.state.reviewAchievement}
            // ref={this.childTradeSchemeFilter}
            />
          </>}
          open={sidebarOpen}
          sidebarClassName="ho-filter-sidebar"
          pullRight
          styles={{ sidebar: { background: 'white', height: '100%' } }}
        />
      }
      {manageUserLevel.length > 0 && <Row>
        <Col>
          <span className="prev-page-link cursor-pointer" onClick={this.getDataFromNavigation.bind(this, 0, null, reviewAchievement)}>{pageTitle}</span>
          {navigation}
        </Col>
      </Row>}

      {/* {navigation} */}
      {/* <span className="prev-page-link cursor-pointer" onClick={this.getDataFromNavigation.bind(this, 0, null)}>Offtake Scorecard</span> */}

      <Row>
        <Col sm="8">
          <div className="page-title">
            Trade Scheme Report {label}&nbsp;
            <img className="cursor-pointer" onClick={this.onSetSidebarOpen} src="/images/filter.svg" alt="" style={{ cursor: 'pointer' }} />
          </div>
        </Col>
        <Col xs="4" className="text-align-right">
          <img src="images/download.svg" alt="" className="download-report-icon margin-left-10" />
          <span className="download-report cursor-pointer" onClick={this.downloadReport}>{' '} Download Report</span>
        </Col>
      </Row>

      <br />
      {isLoading && <CustomSpinner></CustomSpinner>}

      <Card className="margin-top-5">
        <CardBody
        //  className="overall-card"
        >
          <Row className="margin-bottom-20">
            <Col className="text-align-left">
              <div className="single-dealer-table-card-header">
                <span className="title-case">{currentUser && currentUser.name ? currentUser.name : null} Summary </span>
              </div>
            </Col>

          </Row>
          <Table
          // className="overall-table-bg"
          >
            <tr className="overall-card">
              <th>%Achievement</th>
              <th>Above 100%</th>
              <th>90-99%</th>
              <th>80-89%</th>
              <th>70-79%</th>
              <th>60-69%</th>
              <th>50-59%</th>
              <th>40-49%</th>
              <th>30-39%</th>
              <th>20-29%</th>
              <th>10-19%</th>
              <th>0-9%</th>
            </tr>
            <tr
            // className="overall-card"
            >
              <td>No. Of Active Dealers</td>
              <td>{overall['100']}</td>
              <td>{overall['90']}</td>
              <td>{overall['80']}</td>
              <td>{overall['70']}</td>
              <td>{overall['60']}</td>
              <td>{overall['50']}</td>
              <td>{overall['40']}</td>
              <td>{overall['30']}</td>
              <td>{overall['20']}</td>
              <td>{overall['10']}</td>
              <td>{overall['0']}</td>
            </tr>
          </Table>
        </CardBody>
      </Card>

      {prepareReportRows}
    </div>
  }

}

export default TradeSchemeReport;
