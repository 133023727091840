import React, { useState } from "react";
import { useEffect } from "react";

import GenerativeAIService from "../../../services/GenerativeAI";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { DynamicCharts } from "../../../components/GenAI/DynamicCharts";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-resizable/css/styles.css';

import "./GenAIDashboard.css"


const staticData = [
  {
    "id": "5643534534",
    "name": "School Student Count Bar Chart",
    "user_prompt": "bar chart for student count school wise",
    "order": 2,
    "chartData": [
      {
        "school_name": "San Bernardino Valley College (SBVC)",
        "student_count": "28131"
      },
      {
        "school_name": "Crafton Hills College",
        "student_count": "10678"
      }
    ],
    "chartConfig": {
      "query": "SELECT s.name AS school_name, COUNT(DISTINCT c.student_id) AS student_count FROM ccc_search_1 c JOIN siembra_school s ON c.school_id = s.id GROUP BY s.name ORDER BY student_count DESC",
      "graph_type": "barchart",
      "graph_parameters": {
        "datakey_Bar_1": "student_count",
        "datakey_XAxis": "school_name"
      }
    }
  },
  {
    "id": "3443232323",
    "name": "School Student Count and Hispanic Count Bar Chart",
    "user_prompt": "bar chart for student count & ehnicity count school wise",
    "order": 1,
    "chartData": [
      {
        "school_name": "San Bernardino Valley College (SBVC)",
        "student_count": "28131",
        "hispanic_count": "18290"
      },
      {
        "school_name": "Crafton Hills College",
        "student_count": "10678",
        "hispanic_count": "5275"
      }
    ],
    "chartConfig": {
      "query": "SELECT s.name AS school_name, COUNT(DISTINCT c.student_id) AS student_count, COUNT(DISTINCT CASE WHEN LOWER(c.ethnicities) = 'hispanic' THEN c.student_id END) AS hispanic_count FROM ccc_search_1 c JOIN siembra_school s ON c.school_id = s.id GROUP BY s.name ORDER BY student_count DESC",
      "graph_type": "barchart",
      "graph_parameters": {
        "datakey_Bar_1": "student_count",
        "datakey_Bar_2": "hispanic_count",
        "datakey_XAxis": "school_name"
      }
    }
  }
]

const GenAIDashboard = () => {
  const [savedRecords, setSavedRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSavedRecordList();
  }, []);

  const getSavedRecordList = async () => {
    try {
      setIsLoading(true);
      const dataRes = await GenerativeAIService.getSavedQueries();
      if (dataRes) {
        // const resData = dataRes.data.User_charts || [];
        // const resData = staticData || [];
        const resData = dataRes.data || [];
        const sortdData = resData && resData.length > 0 ? resData.sort((a, b) => a.order - b.order) : [];
        setSavedRecords(sortdData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const saveChartOrder = async (updatedCharts) => {
    try {
      setIsLoading(true);
      const dataRes = await GenerativeAIService.saveChartOrderApi({ charts: updatedCharts });
      if (dataRes) {
        setIsLoading(false);
        await getSavedRecordList();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(savedRecords);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSavedRecords(items);

    // Save the new order to the backend
    saveChartOrder(items.map((chart, index) => ({ id: chart.id, order: index })));
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      <Card className="genAi w-100">
        <CardHeader>Gen AI Dashboard</CardHeader>
        <CardBody>
          {savedRecords && savedRecords.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="charts" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="container">
                    <div className="row">
                      {savedRecords.map((chart, index) => {
                        const isFullChart = ((chart.chartData || []).length > 5 && chart.chartConfig.graph_type === "barchart") ? true : false;
                        const chartClassName = isFullChart ? "col-12" : "col-lg-6 col-md-12";
                        const chartTitle = chart.name || "";
                        return (
                          <Draggable key={chart.id} draggableId={chart.id.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                // {...provided.dragHandleProps}
                                className={chartClassName}
                              >
                                <div className='chartBlock'>
                                  {chartTitle && <h2
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps} // Apply drag handle props to the header

                                    className="chart-title">{chartTitle}</h2>}
                                  <DynamicCharts record={chart} key={index} isFullChart={isFullChart} iSZoom={true} />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div>{isLoading ? "Loading charts..." : "No data"}</div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default GenAIDashboard;
