import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import askToAi from "../../assets/images/AskToAI.jpg";
import resetSearch from "../../assets/images/resetSearch.jpg";
import { FaSave } from "react-icons/fa";
import icon1 from "../../assets/images/generative ai/icon1.png";
import icon2 from "../../assets/images/generative ai/icon2.png";
import icon3 from "../../assets/images/generative ai/icon3.png";
import icon4 from "../../assets/images/generative ai/icon4.png";
import icon5 from "../../assets/images/generative ai/icon5.png";
import icon6 from "../../assets/images/generative ai/icon6.png";
import icon7 from "../../assets/images/generative ai/icon7.png";
import icon8 from "../../assets/images/generative ai/icon8.png";
import icon9 from "../../assets/images/generative ai/icon9.png";
import "./GenerativeAI.css";
import GenerativeAICard from "./GenerativeAICard";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import GenerativeAIService from "../../services/GenerativeAI";
import * as Recharts from "recharts";
import { colorsArr } from "../../utils/colorArr";
import ModalService from "../../services/ModalService";
import { DynamicCharts } from "../../components/GenAI/DynamicCharts";

function GenerativeAI() {
  const [askInput, setAskInput] = useState("");
  const [tyreCategory, setTyreCategory] = useState("PCR");
  const [submitSearch, setSubmitSearch] = useState(false);
  const [apiANS, setApiAns] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSaveQueryLoading, setIsSaveQueryLoading] = useState(false);
  const initialFormData = {
    name: {
      value: "",
      touched: false,
    },
  };
  const [form, setForm] = useState(initialFormData);

  const [chartRes, setChartRes] = useState(null);
  const [isShowSaveQuery, setIsShowSaveQuery] = useState(false);

  const [cardList, setCardList] = useState([
    {
      name: "Area-wise Active dealer count",
      iconIMG: icon1,
      discription:
        'Share the count of dealers from Ahmedabad Area, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "green",
        backgroundColor: "#E2F0D9",
      },
    },
    {
      name: "Area-wise Steel Wheels count",
      iconIMG: icon2,
      discription:
        "Share the count of dealer with Steel Wheels classification in Delhi Region in Q4, FY 2022-23",
      // "This prompt create a question + answer structure for answering  question based on existing...",
      iconStyleObj: {
        color: "orange",
        backgroundColor: "#FFD9D9",
      },
    },
    {
      name: "Q-o-Q Offtake drop comparison",
      iconIMG: icon3,
      discription:
        "Share the count of dealers with Steel Wheels classification, where quarterly offtake reduced by 30%, from Q1 FY 2022-23 to Q1 FY 2023-24",
      iconStyleObj: {
        color: "green",
        backgroundColor: "#DAE3F3",
      },
    },
    {
      name: "Q-o-Q Offtake surge comparison",
      iconIMG: icon4,
      discription:
        "Share the count of dealers with SW classification, where Quarterly offtake increased by 20%, from Q1 FY 2022-23 to Q1 FY 2023-24",
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#DEEBF7",
      },
    },
    {
      name: "Region-wise Active dealer count",
      iconIMG: icon5,
      discription:
        'Share the count of dealers from Delhi Region, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "#7a00ff",
        backgroundColor: "#EADCF4",
      },
    },
    {
      name: "Zone-wise Active dealer count",
      iconIMG: icon6,
      discription:
        'Share the count of dealers from West Zone, Whose Active Inactive status is "active" in dealer master in Q1, FY 2023-24',
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#E2F0D9",
      },
    },
    {
      name: "APM of Steel Wheels",
      iconIMG: icon7,
      discription:
        "Share the average monthly offtake and dealer count with Steel Wheels classification in Q1, FY 2023-24",
      iconStyleObj: {
        color: "rgb(229 11 11)",
        backgroundColor: "#FBE5D6",
      },
    },
    {
      name: "APM of Xpress Wheels",
      iconIMG: icon8,
      discription:
        "Share the average monthly offtake and dealer count with Xpress Wheels classification in FY 2022-23",
      iconStyleObj: {
        color: "#007eff",
        backgroundColor: "#FFF2CC",
      },
    },
    {
      name: "Dealer SAP wise quarterly business",
      iconIMG: icon9,
      discription: "Q1 FY 2023-24 total offtake of dealer code 1115816",
      iconStyleObj: {
        color: "rgb(229 11 11)",
        backgroundColor: "#A7E8FF",
      },
    },
  ]);
  const [tyreCategoryList, setTyreCategoryList] = useState([
    "PCR",
    "TRUCK",
    "TBB",
    "TBR",
    "SCV",
    "LCV",
    "2-3W",
    "OTR",
    "Retread",
  ]);

  const handleSubmitSearch = async (v) => {
    setSubmitSearch(v);
    if (v) {
      let body = {
        question: askInput,
        test_mode: false,
      };

      try {
        setLoading(true);
        const dataRes = await GenerativeAIService.getAnswer(body);
        if (dataRes) {
          setLoading(false);
          if (dataRes && dataRes.data && dataRes.data.chart_result) {
            setChartRes(dataRes.data.chart_result);
            setApiAns("");
          } else {
            setChartRes(null);
            setApiAns(dataRes.data);
          }
        }
      } catch (error) {
        setLoading(false);
        setChartRes(null);
        setApiAns("");
        console.log(error);
      }
      // setAskInput("Number of active dealers in Ahmedabad region in June'23");
      // setAskInput("get all quantitiy of 2022 and 2023 and find diff between this two");
    } else {
      setAskInput("");
    }
  };

  const handleSaveSearch = async () => {
    const body = {
      prompt: askInput,
      name: form.name.value,
      query: chartRes && chartRes.query ? chartRes.query : "",
      graph_type: chartRes && chartRes.graph_type ? chartRes.graph_type : "",
      graph_parameters: chartRes && chartRes.graph_parameters ? chartRes.graph_parameters : null,
    };

    try {
      setIsSaveQueryLoading(true);
      const dataRes = await GenerativeAIService.saveQuery(body);
      if (dataRes) {
        setIsSaveQueryLoading(false);
        ModalService.openAlert("", dataRes.data.message || "Chart saved succesfully", "success");
        setForm(initialFormData);
        setIsShowSaveQuery(false);
      }
    } catch (error) {
      setIsSaveQueryLoading(false);
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const controls = { ...form };
    controls[controlName].value = controlValue;
    setForm(controls);
  };

  return (
    <div className="generativeAIPage" id="generativeAIPage">
      {/* Page Heading */}
      <div className="page-title-wrapper">
        <div className="page-title-block d-flex align-items-center flex-wrap">
          <div>
            <img src={askToAi} alt="img" height="70px" width="70px" />
          </div>
          <div className="pageTitleText">
            <div className="page-title">
              Ask Vikash your query for JK Tyre PCR Business?
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="chatWrap card">
          <div className="searchInputWrap">
            <div className="searchInputWrap2">
              <textarea
                name="searchInput"
                className="searchInput"
                rows={2}
                value={askInput}
                onChange={(e) => {
                  setAskInput(e.target.value);
                  setSubmitSearch(false);
                }}
                placeholder="Ask Vikash…………"
              />
              {submitSearch ? (
                <>
                  <span
                    className="resetIcon"
                    onClick={() => handleSubmitSearch(false)}
                  >
                    <img src={resetSearch} height="36px" />
                  </span>
                  <FaSave
                    className="saveIcon"
                    title="Save Query"
                    onClick={() => setIsShowSaveQuery(true)}
                  />
                </>
              ) : (
                <span
                  className="searchIcon"
                  onClick={() => handleSubmitSearch(true)}
                >
                  <FaRegArrowAltCircleRight />
                </span>
              )}
            </div>
            <div style={{ width: "180px" }}>
              <select
                className="select"
                name="tyreCategory"
                value={tyreCategory}
                onChange={(e) => {
                  setTyreCategory(e.target.value);
                }}
              >
                <option value="All" disabled>
                  All
                </option>
                {tyreCategoryList.length > 0 &&
                  tyreCategoryList.map((item) => (
                    <option disabled value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {submitSearch && apiANS ? (
            <div className="ans-card">
              <p className="mb-0">
                <b>Vikash:</b>
              </p>
              {/* <p>There are 32 active dealers in Ahmedabad region in June'23</p> */}
              <p>{apiANS}</p>
            </div>
          ) : submitSearch && chartRes ? (
            <div className="p-4 pt-0 border-1 border-round p-border-primary genAi">
              {(() => {
                const formattedRecord = {
                  ...chartRes,
                  "chartConfig": {
                    "graph_parameters": chartRes.graph_parameters || {},
                    "graph_type": chartRes.graph_type || "",
                    "query": chartRes.query || "",
                  },
                  "chartData": chartRes.data || [],
                };
                return (
                  <div className='chartBlock'>
                    <DynamicCharts record={formattedRecord} isFullChart={true} />
                  </div>
                )

              })()}
            </div>
          ) : (
            <Row className="p-3">
              {cardList.map((item, index) => (
                <GenerativeAICard
                  name={item.name}
                  iconIMG={item.iconIMG}
                  discription={item.discription}
                  iconStyleObj={item.iconStyleObj}
                  setAskInput={setAskInput}
                />
              ))}
            </Row>
          )}
        </div>
      )}
      <Modal
        id="save-query"
        isOpen={isShowSaveQuery}
        toggle={() => setIsShowSaveQuery(!isShowSaveQuery)}
        centered
      >
        {isSaveQueryLoading && <CustomSpinner />}
        <ModalBody>
          <Form>
            <Row className="modal-title-border padding-bottom-10">
              <Col className="modal-title">SAVE QUERY</Col>
            </Row>
            <div></div>
            <Row>
              <Col>
                <FormGroup className="margin-top-10">
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name.value}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: "right" }}>
              <Button
                className="mb-1 mr-1 btn-pill Rectangle-button cancel-button"
                onClick={() => {
                  setIsShowSaveQuery(false);
                  setForm(initialFormData);
                }}
                color="dark"
              >
                Cancel
              </Button>
              <Button
                className="mb-1 mr-1 btn-pill Rectangle-button send-button"
                onClick={handleSaveSearch}
                color="warning"
                disabled={!form.name.value}
              >
                Save
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default GenerativeAI;
