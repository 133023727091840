import React, { Component } from "react";
import lodash from "lodash";
import { bindActionCreators } from "redux";
import Ionicon from "react-ionicons";
import { Row, Col, CardBody, Label, FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import CampaignService from "../../services/CampaignService";
import "./RecceManagementMiniFilter.css";
import {
  resetfilterData,
  setFilterAppliedStatus,
  setFilterData,
  setFilterOpenClose,
  // setRecceStatus,
} from "../../actions/RetailManagementSystemAction";
import {
  getFilterAppliedStatus,
  getFilterData,
  // getRecceStatus,
} from "../RetailMarketingSystem/selector";
import Autosuggest from "react-autosuggest";
import RetailMarketingSystemService from "./../../services/RetailMarketingSystemService";
import UserService from './../../services/UserService';
import { Roles } from "../../constants/constant.role";

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  return <div>{`${suggestion.sap_id} - ${suggestion.owner_name}`}</div>;
};

class RecceManagementMiniFilter extends Component {
  constructor() {
    super();
    this.state = {
      status: "Select",
      userType: false,
      openRegion: false,
      openState: false,
      openArea: false,
      openCity: false,
      isLoading: false,
      zoneType: '',
      userZoneId: '',
      controls: {
        zone_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        region_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        area_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city_id: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        status: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        vendor_id: {
          value: "",
          displayValue: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      zoneData: [],
      regionData: [],
      areaData: [],
      stateData: [],
      cityData: [],
      vendorData: [],
      jkUserFilterData: {},
      isSetDataFirstTime: true,
    };
    this.dropDownRegion = React.createRef();
    this.dropDownArea = React.createRef();
    this.dropDownState = React.createRef();
    this.dropDownCity = React.createRef();
  }
  statusData = [
    "Select",
    "Recce Pending",
    "Recce Modification",
    "Recce Rejected",
    "Installation Pending",
    "Installation Approved",
    "Installation Modification",
    "Installation Completed",
  ];

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user-detail"));
    if (user.type === Roles.ZTM_ROLE) {
      this.getUserInfo()
    }
    this.getZone();
    this.getState();
    this.getVendor();
    this.initAPI();
  }

  getUserInfo = () => {
    UserService.getUserInfo()
      .then(data => {
        let userData = data.data.data;
        if (userData && userData.zone_uuid) {
          this.getRegion(userData.zone_uuid);
          this.setState({
            userZoneId: userData.zone_uuid
          })
        }
        if (userData && userData.type) {
          this.setState({
            zoneType: userData.type
          })
        }
      })
      .catch(e => {
        console.error("e", e);
      })
  }

  initAPI = async () => {
    const user = JSON.parse(localStorage.getItem("user-detail"));
    if (user.type === Roles.HEAD_OFFICE_ROLE || user.type === Roles.ZTM_ROLE || user.type === Roles.PROJECT_MANAGER_ROLE) {
      this.setState({ userType: true });
    }
    if (this.props.filterData) {
      this.setState({ controls: this.props.filterData });
    }
  };

  // close Filter
  onCloseSidebar = () => {
    this.props.onCloseSidebar();
  };

  // handle Club Name
  handleChangeVendor = (e) => {
    let { controls } = this.state;
    controls["vendor_id"].value = e.target.value;
    this.setState({ controls });
  };

  // handle Zone Change

  handleInputChange = (e, index = -1) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    let {
      controls,
      zoneData,
      regionData,
      areaData,
      isSetDataFirstTime,
    } = this.state;
    isSetDataFirstTime = false;
    if (controlName === "region_id") {
      this.refArea.onMenuClose();
      controls[controlName].value = controlValue;
      controls.area_id.value = [];
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          (x) => x.region_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = regionData;
          } else if (controlValue.length === regionData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
          this.refRegion.onMenuClose();
        } else if (index_all > 0) {
          controls[controlName].value = regionData;
        } else if (controlValue.length === regionData.length - 1) {
          controls[controlName].value = [];
        }
      }

      let zone_all = lodash.filter(zoneData, (element) => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach((element) => {
          if (element.isChecked) {
            zone_id.push(element.zone_id);
          }
        });
      }

      let index_all = controls[controlName].value.findIndex(
        (x) => x.region_name === "All"
      );
      if (index_all > -1) {
        this.getArea({ region_ids: [], zone_id: zone_id });
      } else {
        let region_id = [];
        controls[controlName].value.forEach((element) => {
          region_id.push(element.region_id);
        });
        this.getArea({ region_ids: region_id, zone_id: zone_id });
      }
    } else if (controlName === "area_id") {
      this.refRegion.onMenuClose();
      controls[controlName].value = controlValue;
      if (controlValue.length > 0) {
        let index_all = controls[controlName].value.findIndex(
          (x) => x.area_name === "All"
        );
        if (index_all === 0) {
          if (controlValue.length === 1) {
            controls[controlName].value = areaData;
            this.refArea.onMenuClose();
          } else if (controlValue.length === areaData.length - 1) {
            controls[controlName].value.splice(index_all, 1);
          }
        } else if (index_all > 0) {
          controls[controlName].value = areaData;
          this.refArea.onMenuClose();
        } else if (controlValue.length === areaData.length - 1) {
          controls[controlName].value = [];
          this.refArea.onMenuClose();
        }
      }
    } else if (controlName === "zone_id") {
      controls.area_id.value = [];
      controls.region_id.value = [];
      regionData = [];
      areaData = [];
      if (index === 0) {
        zoneData[0].isChecked = true;
        zoneData.forEach((element, i) => {
          if (i !== 0) {
            element.isChecked = false;
          }
        });
      } else if (index > 0) {
        zoneData[index].isChecked = !zoneData[index].isChecked;
        zoneData.forEach((element, i) => {
          if (i !== index) {
            element.isChecked = false;
          }
        });
      }
      let zone_all = lodash.filter(zoneData, (element) => {
        return element.zone_name === "All";
      });
      let zone_id = [];
      if (!zone_all[0].isChecked) {
        zoneData.forEach((element) => {
          if (element.isChecked) {
            // zone_id.push(element.zone_id);
            zone_id = [element.zone_id];
          }
        });
      }
      controls[controlName].value = zone_id;
      controls[controlName].touched = true;
      this.getRegion(zone_id);
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    this.setState({
      controls,
      zoneData,
      regionData,
      areaData,
    });
  };

  // Apply Filter Store value in redux store

  applyFilter = () => {
    const { controls } = this.state;
    localStorage.setItem("filterData", JSON.stringify(controls));
    localStorage.setItem("pageNumber", 1);
    this.props.setFilterDataAction(controls);
    this.props.setFilterAppliedStatusAction(true);
    this.props.setFilterOpenCloseAction(false);
    this.onCloseSidebar();
  };

  // Reset Filter

  resetFilter = () => {
    localStorage.setItem("serearchQuery", "");
    this.props.resetfilterDataAction(this.props.filterData);
    this.props.setFilterOpenCloseAction(false);
    this.props.setFilterAppliedStatusAction(false);
    this.onCloseSidebar();
  };

  // Get Zone

  getZone = () => {
    CampaignService.getZone()
      .then((data) => {
        if (data.data.data) {
          data.data.data.splice(0, 0, {
            zone_id: [],
            zone_name: "All",
            zone_code: "all",
          });
        }
        if (this.state.isSetDataFirstTime) {
          if (
            this.props.filterAppliedStatus &&
            Object.keys(this.props.filterData).length > 0
          ) {
            if (this.props.filterData.zone_id.value.length > 0) {
              let zone_id = [];

              this.props.filterData.zone_id.value.forEach((filter_zone_ids) => {
                data.data.data.forEach((element) => {
                  if (element.zone_id === filter_zone_ids) {
                    element.isChecked = true;
                    zone_id.push(element.zone_id);
                  }
                });
              });
              this.getRegion(zone_id);
            } else if (this.props.filterData.zone_id.value.length === 0) {
              data.data.data[0].isChecked = true;
              this.getRegion(this.state.userZoneId || []);
            }
          } else {
            this.getRegion(this.state.userZoneId || []);
            data.data.data[0].isChecked = true;
          }
        } else {
          data.data.data[0].isChecked = true;
        }

        this.setState({
          zoneData: data.data.data,
        });
      })
      .catch((err) => {
        return err;
      });
  };

  // Get Regions

  getRegion = (zone_ids) => {
    let reqData = {
      zone_id: zone_ids,
    };
    CampaignService.getRegion(reqData)
      .then((data) => {
        let { controls } = this.state;
        if (data.data.data) {

          if (
            this.props.filterAppliedStatus &&
            Object.keys(this.props.filterData).length > 0
          ) {
            if (
              this.props.filterData.region_id.value &&
              this.props.filterData.region_id.value.length > 0
            ) {
              let region_id = [];

              // debugger;
              // this.props.filterData.region_id.value.forEach(
              //   (filter_region_ids) => {
              //     data.data.data.forEach((element) => {
              //       if (element.region_id === filter_region_ids) {
              //         controls.region_id.value.push(element);
              //         region_id.push(element.region_id);
              //       }
              //     });
              //   }
              // );
              region_id.push(this.props.filterData.region_id.value);

              let zone_all = lodash.filter(this.state.zoneData, (element) => {
                return element.zone_name === "All";
              });
              let zone_id = [];
              if (!zone_all[0].isChecked) {
                this.state.zoneData.forEach((element) => {
                  if (element.isChecked) {
                    zone_id.push(element.zone_id);
                  }
                });
              }
              this.getArea(region_id, zone_id);
            }
          }
          this.setState({
            regionData: data.data.data,
            controls,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  // Get Area
  getArea = (region_id = "", zone_id = "") => {
    const { controls } = this.state;
    let reqData = {
      region_id: region_id ? region_id : controls.region_id.value,
      zone_id: zone_id ? zone_id : controls.zone_id.value,
    };
    this.setState({
      isLoading: true,
    });
    CampaignService.getArea(reqData)
      .then((data) => {
        let { controls } = this.state;

        if (data.data.data) {
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_area_ids &&
              this.state.jkUserFilterData.filter_area_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_area_ids.forEach(
                (filter_area_ids) => {
                  data.data.data.forEach((element) => {
                    if (element.area_id === filter_area_ids) {
                      controls.area_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            this.getState();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          areaData: data.data.data,
          controls,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  // Get State
  getState = () => {
    this.setState({
      isLoading: true,
    });
    CampaignService.getState()
      .then((data) => {
        let { controls } = this.state;
        if (data.data.data) {
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_state_ids &&
              this.state.jkUserFilterData.filter_state_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_state_ids.forEach(
                (filter_state_ids) => {
                  data.data.data.forEach((element) => {
                    if (element.state_id === filter_state_ids) {
                      controls.state_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            this.getCity();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          stateData: data.data.data,
          controls,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  // Get City
  getCity = () => {
    const { controls } = this.state;
    let reqData = {
      state_id: controls.state_id.value,
    };
    this.setState({
      isLoading: true,
    });
    CampaignService.getCity(reqData)
      .then((data) => {
        let { controls } = this.state;
        if (data.data.data) {
          if (
            this.state.isSetDataFirstTime &&
            this.state.jkUserFilterData &&
            Object.keys(this.state.jkUserFilterData).length > 0
          ) {
            if (
              this.state.jkUserFilterData.filter_city_ids &&
              this.state.jkUserFilterData.filter_city_ids.length > 0
            ) {
              this.state.jkUserFilterData.filter_city_ids.forEach(
                (filter_city_ids) => {
                  data.data.data.forEach((element) => {
                    if (element.city_id === filter_city_ids) {
                      controls.city_id.value.push(element);
                    }
                  });
                }
              );
            }
          } else {
            // this.getState();
            data.data.data[0].isChecked = true;
          }
        }
        this.setState({
          cityData: data.data.data,
          controls,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  // Get City
  getVendor = () => {
    this.setState({
      isLoading: true,
    });
    RetailMarketingSystemService.getVendorList()
      .then((data) => {
        let { controls } = this.state;
        if (data.data.data) {
          this.setState({
            vendorData: data.data.data,
            controls,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  // handle Region Change
  handleRegionChange = (e) => {
    const { controls } = this.state;
    controls["region_id"].value = e.target.value;
    controls.area_id.value = [];
    controls.state_id.value = [];
    controls.city_id.value = [];

    this.setState(
      {
        controls,
      },
      () => {
        this.getArea();
      }
    );
  };
  // Handle Area Changes
  handleAreaChange = (e) => {
    const { controls } = this.state;
    controls["area_id"].value = e.target.value;
    controls.state_id.value = [];
    controls.city_id.value = [];
    this.setState(
      {
        controls,
      },
      () => {
        this.getState();
      }
    );
  };

  // Handle State Change
  handleStateChange = (e) => {
    const { controls } = this.state;
    controls["state_id"].value = e.target.value;
    controls.city_id.value = [];
    this.setState(
      {
        controls,
      },
      () => {
        this.getCity();
      }
    );
  };
  // handle City Changes
  handleCityChange = (e) => {
    const { controls } = this.state;

    controls["city_id"].value = e.target.value;

    // const index = controls["city_id"].value.findIndex(
    //   (x) => x === city.city_id
    // );
    // if (index > -1) {
    //   controls["city_id"].value.splice(index, 1);
    // } else if (Array.isArray(city.city_id) && city.city_id.length <= 0) {
    //   controls["city_id"].value = [];
    // } else {
    //   controls["city_id"].value.push(city.city_id);
    // }
    // controls.state_id.value = [];
    // controls.city_id.value = [];
    this.setState({
      controls,
    });
  };

  // Close All Drop Down of region,area,state and city
  // closeAllDropDown = (event) => {
  //   if (
  //     this.dropDownRegion.current &&
  //     !this.dropDownRegion.current.contains(event.target) &&
  //     this.state.openRegion
  //   ) {
  //     this.setState({ openRegion: false });
  //   }
  //   if (
  //     this.dropDownArea.current &&
  //     !this.dropDownArea.current.contains(event.target) &&
  //     this.state.openArea
  //   ) {
  //     this.setState({ openArea: false });
  //   }
  //   if (
  //     this.dropDownState.current &&
  //     !this.dropDownState.current.contains(event.target) &&
  //     this.state.openState
  //   ) {
  //     this.setState({ openState: false });
  //   }
  //   if (
  //     this.dropDownCity.current &&
  //     !this.dropDownCity.current.contains(event.target) &&
  //     this.state.openCity
  //   ) {
  //     this.setState({ openCity: false });
  //   }
  // };

  onChange = (event, { newValue }) => {
    const { controls } = this.state;
    if (!newValue) {
      controls["vendor_id"].value = "";
      controls["vendor_id"].touched = true;
    }
    controls["vendor_id"].displayValue = newValue;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      vendorData: [],
    });
  };

  searchDealer = (e, type) => {
    let reqData = {
      q: e,
    };
    this.setState({ isLoading: true });
    RetailMarketingSystemService.getVendorList(reqData)
      .then((data) => {
        let { controls } = this.state;
        this.setState({
          isLoading: false,
        });
        if (data.data.data && data.data.data.length > 0) {
          this.setState({
            vendorData: data.data.data,
            controls,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  debouncedLoadSuggestions = lodash.debounce((value) => {
    this.searchDealer(value);
  }, 100);

  getSuggestionValue = (suggestion) => {
    let { controls } = this.state;
    controls["vendor_id"].value = suggestion.uuid ? suggestion.uuid : "";
    this.setState({ controls });
    return suggestion.sap_id + " - " + suggestion.owner_name;
  };

  render() {
    const { regionData, controls, vendorData, zoneType } = this.state;
    const { region_id, area_id, state_id, city_id } = controls;

    const inputProps = {
      placeholder: "Enter Vendor name or id",
      value: controls.vendor_id.displayValue,
      onChange: this.onChange,
    };

    return (
      <div
        id="customer-management-filter-mini"
        className="height-100"
        onMouseDown={this.closeAllDropDown}
      >
        <div className="drawer-header" style={{ paddingTop: "18px" }}>
          <span className="drawer-title">Filter</span>
          <Ionicon
            className="cursor-pointer"
            icon="md-close"
            fontSize="24px"
            color="#fdbb1f"
            onClick={() => this.onCloseSidebar()}
          />
        </div>
        <hr style={{ border: "solid 1px #ced4da", marginTop: "8px" }} />
        {this.state.isLoading && <CustomSpinner />}

        {/* Part One */}
        <div className="data-container">
          <div className="filter-vehicle-detail">
            <CardBody className="padding-top-bottom-0">
              {this.state.userType ? (
                <>
                  {zoneType !== Roles.ZTM_ROLE
                    ? this.state.zoneData.length > 0 && (
                      <>
                        <div className="mb-2">
                          <span className="filter-type-lbl ml-0">
                            Select Zone
                          </span>
                        </div>
                        <div>
                          <div
                            className="d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            {(this.state.zoneData || []).map(
                              (zone, index) => {
                                return (
                                  <div
                                    className="d-flex"
                                    style={{ paddingRight: "20px" }}
                                  >
                                    <label className="container-checkbox">
                                      <input
                                        type="radio"
                                        name="zone_id"
                                        checked={zone.isChecked}
                                        id="checkbox"
                                        value={zone.zone_id}
                                        onChange={(e) =>
                                          this.handleInputChange(e, index)
                                        }
                                      />
                                      &nbsp;
                                      <span className="checkmark" />
                                    </label>
                                    <div>{zone.zone_name}</div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </>
                    )
                    : null}

                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="field-label">Region</Label>
                        <br />
                        <select
                          name="region_id"
                          className="form-control custom-dropdown"
                          value={region_id.value}
                          onChange={(e) => {
                            this.handleRegionChange(e);
                          }}
                        >
                          <option value="" disabled="true" selected>
                            Select a region
                          </option>
                          {regionData.map((item, j) => {
                            return (
                              <option
                                className="text-capitalize"
                                value={item.region_id}
                              >
                                {item.region_name}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                    </Col>

                    <Col sm="6">
                      <FormGroup>
                        <Label className="field-label">Territory</Label>
                        <br />
                        <select
                          name="area_id"
                          className="form-control custom-dropdown"
                          value={area_id.value}
                          onChange={(e) => {
                            this.handleAreaChange(e);
                          }}
                        >
                          <option value="" disabled="true" selected>
                            Select a area
                          </option>
                          {this.state.areaData &&
                            this.state.areaData.map((item, j) => {
                              return (
                                <option
                                  className="text-capitalize"
                                  value={item.area_id}
                                >
                                  {item.area_name}
                                </option>
                              );
                            })}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="field-label">State</Label>
                        <br />
                        <select
                          name="region_id"
                          className="form-control custom-dropdown"
                          value={state_id.value}
                          onChange={(e) => {
                            this.handleStateChange(e);
                          }}
                        >
                          <option value="" disabled="true" selected>
                            Select a state
                          </option>
                          {this.state.stateData.map((item, j) => {
                            return (
                              <option
                                className="text-capitalize"
                                value={item.state_id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="field-label">City</Label>
                        <br />
                        <select
                          name="city_id"
                          className="form-control custom-dropdown"
                          value={city_id.value}
                          onChange={(e) => {
                            this.handleCityChange(e);
                          }}
                        >
                          <option value="" disabled="true" selected>
                            Select a city
                          </option>
                          {this.state.cityData.map((item, j) => {
                            return (
                              <option
                                className="text-capitalize"
                                value={item.city_id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label className="field-label">Vendor Id</Label>
                        <div className="auto-suggest">
                          <Autosuggest
                            suggestions={vendorData}
                            onSuggestionsFetchRequested={
                              this.onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              this.onSuggestionsClearRequested
                            }
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                          />
                          <img
                            src="images/search.svg"
                            alt=''
                            className="report-search icon-width"
                            style={{ top: "45px" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              {/* </Row> */}
            </CardBody>
          </div>
          {/* <CardBody> */}
          <Row
            style={{ marginLeft: "0", marginRight: "0" }}
            className="freeze-footer"
          >
            <Col
              xs="12"
              md="12"
              lg="12"
              className="text-align-right footer-drawer"
              style={{ marginTop: "0" }}
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                color="dark"
                onClick={() => this.resetFilter()}
                style={{ width: "116px" }}
              >
                Reset Filter
              </Button>
              &nbsp;&nbsp;
              <Button
                className="mb-1 mr-1 btn-pill action-button font-weight-bold"
                color="warning"
                onClick={this.applyFilter}
                style={{ width: "116px" }}
              >
                Apply
              </Button>
            </Col>
          </Row>
          {/* </CardBody> */}
          {/* </div> */}
        </div>

        {/* </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFilterDataAction: setFilterData,
      setFilterOpenCloseAction: setFilterOpenClose,
      resetfilterDataAction: resetfilterData,
      // setRecceStatusAction: setRecceStatus,
      setFilterAppliedStatusAction: setFilterAppliedStatus,
    },
    dispatch
  );

const mapStateToProp = (state) => ({
  // recceStatus: getRecceStatus(state),
  filterData: getFilterData(state),
  filterAppliedStatus: getFilterAppliedStatus(state),
});

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(RecceManagementMiniFilter);
