import React, { useCallback, useRef } from 'react'
import * as Recharts from "recharts";
import { colorsArr } from '../../utils/colorArr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import './DynamicChart.css'

export const DynamicCharts = ({ record, isFullChart = false, iSZoom = false }) => {
  const chartRef = useRef(null);

  const formatLabel = (label = "") => {
    return label ? label.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) : "";
  };

  const downloadCSV = (data) => {
    // Convert the data to CSV format
    const csvContent = [
      // Header row
      Object.keys(data[0]).join(','),
      // Data rows
      ...data.map(row => Object.values(row).join(','))
    ].join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'chart_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadPNG = () => {
    const svgElement = chartRef.current.container.children[0];
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'chart.png';
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  };

  const renderCharts = (record) => {
    const keyVal = record.chartConfig.graph_type || null;
    const chartDescription = record.chart_description || null;
    const chartTitle = record.name || "";

    const commonProps = {
      ref: chartRef,
      data: record.chartData,
    };

    const CustomXAxisTick = ({ x, y, payload, angle }) => {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fill="#666" fontSize={12}
            textAnchor={angle ? "end" : "middle"}
            transform={`rotate(${angle})`}
          >
            {payload.value.split(' ').map((word, index, arr) =>
              <tspan x="0" dy={index ? 12 : 0} key={index}>
                {word}
              </tspan>
            )}
          </text>
        </g>
      );
    };

    const downloadButtons = (
      // <div className='d-flex justify-content-end'>
      //   <button className='mr-3' onClick={() => downloadCSV(record.chartData || [])}>Download CSV</button>
      //   <button onClick={downloadPNG}>Download PNG</button>
      // </div>
      <div className="chart-controls">
        <button onClick={downloadPNG} className="chart-button">
          <FontAwesomeIcon icon={faFileExcel} /> Download PNG
        </button>
        <button onClick={() => downloadCSV(record.chartData || [])} className="chart-button">
          <FontAwesomeIcon icon={faDownload} /> Download CSV
        </button>
      </div>
    );
    switch (keyVal) {
      case "barchart": {
        // display graph for number of student per school
        const barKeysArr =
          Object.keys(record.chartConfig.graph_parameters).filter(
            (item) => item !== "datakey_XAxis" && record.chartConfig.graph_parameters[item] !== null
          ) || [];
        return (
          <>
            <p>{chartDescription}</p>
            {downloadButtons}
            <Recharts.ResponsiveContainer width="100%" height={400}>
              <Recharts.BarChart {...commonProps}>
                <Recharts.CartesianGrid strokeDasharray="3 3" />
                <Recharts.XAxis
                  dataKey={record.chartConfig.graph_parameters.datakey_XAxis}
                  interval={0}
                  tick={<CustomXAxisTick angle={isFullChart ? -45 : 0} />} // Pass angle as a prop
                  height={70} // Increased height for labels
                  tickMargin={10}
                  angle={-45}
                // textAnchor="end"
                />
                <Recharts.YAxis />
                <Recharts.Tooltip />
                <Recharts.Legend />
                {barKeysArr.map((item, index) => {
                  return (
                    <Recharts.Bar
                      dataKey={record.chartConfig.graph_parameters[item]}
                      fill={colorsArr[index] || '#000'}
                      key={index}
                      name={formatLabel(record.chartConfig.graph_parameters[item])}
                    />
                  );
                })}
                {iSZoom && (
                  <Recharts.Brush dataKey={record.chartConfig.graph_parameters.datakey_XAxis} height={30} stroke="#8884d8" />
                )}
              </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
          </>
        );
      }
      case "piechart": {
        return (
          <>
            <p>{chartDescription}</p>
            {downloadButtons}
            <Recharts.ResponsiveContainer width={400} height={400}>
              <Recharts.PieChart {...commonProps}>
                <Recharts.Pie
                  data={record.chartData || []}
                  dataKey={record.chartConfig.graph_parameters.datakey_Pie_1}
                  nameKey={record.chartConfig.graph_parameters.namekey_Pie_1}
                  fill="#8884d8"
                  label
                >
                  {record.chartData.map((entry, index) => (
                    // <Recharts.Cell key={`cell-${index}`} fill={entry.color || "#5733FF"} />
                    <Recharts.Cell
                      key={`cell-${index}`}
                      fill={colorsArr[index] || "#5733FF"}
                    />
                  ))}
                </Recharts.Pie>
                <Recharts.Tooltip />
              </Recharts.PieChart>
            </Recharts.ResponsiveContainer>
          </>
        );
      }
      case "linechart": {
        const lineKeysArr =
          Object.keys(record.chartConfig.graph_parameters).filter(
            (item) => item !== "datakey_XAxis" && record.chartConfig.graph_parameters[item] !== null
          ) || [];
        return (
          <>
            <p>{chartDescription}</p>
            {downloadButtons}
            <Recharts.ResponsiveContainer width="100%" height={300}>
              <Recharts.LineChart {...commonProps}>
                <Recharts.CartesianGrid strokeDasharray="3 3" />
                <Recharts.XAxis
                  dataKey={record.chartConfig.graph_parameters.datakey_XAxis}
                />
                <Recharts.YAxis />
                <Recharts.Tooltip />
                <Recharts.Legend />
                {lineKeysArr.map((item, index) => {
                  return (
                    <Recharts.Line
                      dataKey={record.chartConfig.graph_parameters[item]}
                      stroke={colorsArr[index]}
                      key={`cell-${index}`}
                    />
                  );
                })}
              </Recharts.LineChart>
            </Recharts.ResponsiveContainer>
          </>
        );
      }
      case "scatterchart": {
        return (
          <>
            <p>{chartDescription}</p>
            {downloadButtons}
            <Recharts.ResponsiveContainer width="100%" height={300}>
              <Recharts.ScatterChart
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                {...commonProps}
              >
                <Recharts.CartesianGrid />
                <Recharts.XAxis
                  type="number"
                  dataKey={record.chartConfig.graph_parameters.datakey_XAxis}
                  name={record.chartConfig.graph_parameters.datakey_XAxis}
                />
                <Recharts.YAxis
                  type="number"
                  dataKey={record.chartConfig.graph_parameters.datakey_YAxis}
                  name={record.chartConfig.graph_parameters.datakey_YAxis}
                />
                <Recharts.Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Recharts.Scatter
                  name={record.chartConfig.graph_parameters.namekey_Scatter}
                  data={record.chartData || []}
                  fill="#8884d8"
                />
              </Recharts.ScatterChart>
            </Recharts.ResponsiveContainer>
          </>
        );
      }
      default:
        return null;
    }
  };
  return (
    <>
      {renderCharts(record)}
    </>
  )
}
