import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
} from "reactstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from "react-datepicker";
import ProposalSerivice from "../../services/ProposalSerivice";
import swal from "sweetalert";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Moment from "react-moment";
import moment from "moment-timezone";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import { Roles } from "../../constants/constant.role";
class ProposalStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: props.userDetail,
      proposalByIdData: props.proposalByIdData,
      proposalStageStatus: props.proposalStageStatus,
      proposalStageDetail: props.proposalStageDetail
        ? props.proposalStageDetail
        : {
          proposal_status: [],
        },
      controls_inauguration: {
        inauguration_date: "",
        isUpdate: false,
      },
      proposalStatusControl: {
        outlet_type: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        address: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        mobile: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        region: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        area: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
        so: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
        },
      },
      controls_proposal_status_update: {
        proposal_status: "",
        remarks: "",
      },
    };
    if (this.state.userDetail.access_level === "architect") {
      this.state.isPreInauguration = true;
      this.state.isProposalStatusinaugurationDateReadOnly = true;
      this.state.isProposalStatusRemarksReadOnly = true;
    }
  }

  setPreInauguration_date = () => {
    let { controls_inauguration, controls_proposal_status_update } = this.state;
    if (
      this.props.proposalStageDetail &&
      this.props.proposalStageDetail.proposal_status &&
      this.props.proposalStageDetail.proposal_status.length > 0
    ) {
      controls_inauguration.inauguration_date = this.props.proposalStageDetail
        .proposal_status[0].inauguration_date
        ? new Date(
          this.props.proposalStageDetail.proposal_status[0].inauguration_date
        )
        : null;
      if (controls_inauguration.inauguration_date) {
        controls_inauguration.isUpdate = true;
      }
      // controls_proposal_status_update.remarks = this.props.proposalStageDetail.proposal_status[0].remarks ?
      //   this.props.proposalStageDetail.proposal_status[0].remarks : '';
      controls_proposal_status_update.proposal_status = this.props
        .proposalStageDetail.proposal_status[0].status
        ? this.props.proposalStageDetail.proposal_status[0].status
        : "";
      this.setState({ controls_inauguration, controls_proposal_status_update });
    }
  };

  checkActiveStatus = () => {
    const { proposalStageDetail } = this.props;
    const { proposal_status } = proposalStageDetail;
    if (
      proposal_status &&
      Array.isArray(proposal_status) &&
      proposal_status[0].status &&
      proposal_status[0].status.toLowerCase() !== "active"
    ) {
      ModalService.openAlert(
        "Proposal Status",
        proposal_status[0].status.toLowerCase() === "on hold"
          ? messages.PROSSAL_DASHBOARDRD.ON_HOLD
          : messages.PROSSAL_DASHBOARDRD.ON_DROPPED,
        "info"
      );
      return false;
    }
    return true;
  };

  submitInaugration = () => {
    if (!this.checkActiveStatus()) {
      return false;
    }
    let { controls_inauguration } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    let obj = controls_inauguration;
    if (!controls_inauguration.inauguration_date) {
      ModalService.openAlert(
        "",
        "Please select date of inauguration.",
        "error"
      );
      return;
    }
    if (controls_inauguration.inauguration_date) {
      obj.inauguration_date = moment(
        new Date(controls_inauguration.inauguration_date)
      ).format("YYYY-MM-DD");
    }
    controls_inauguration.isUpdate = true;
    // if (this.props.proposalByIdData.stage === 'Hand Over' || this.props.proposalByIdData.stage === 'Pre inauguration') {
    //   controls_inauguration.isUpdate = false;
    // } else {
    //   controls_inauguration.isUpdate = true;
    // }
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true, controls_inauguration });

    ProposalSerivice.saveInaugration(obj)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
        });
        this.setState({ isLoading: false, controls_inauguration });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  handleInputChange = (e) => {
    const { proposalStageDetail } = this.props;
    const controlName = e.target.name;
    const { proposal_status } = proposalStageDetail;
    proposal_status[0][controlName] = e.target.value;
    this.setState({ proposal_status });
  };

  handleInputChangeStatus = (e) => {
    const controlName = e.target.name;
    let { controls_proposal_status_update } = this.state;
    controls_proposal_status_update[controlName] = e.target.value;
    this.setState({ controls_proposal_status_update });
  };

  submitPrposalStatus = () => {
    let { controls_proposal_status_update } = this.state;
    if (!this.state.proposalStageStatus) {
      return;
    }
    let obj = controls_proposal_status_update;
    obj.remark = controls_proposal_status_update.remarks;
    obj.uuid = this.state.proposalStageStatus.uuid;
    this.setState({ isLoading: true });
    ProposalSerivice.updateProposalStatus(obj)
      .then((res) => {
        this.setState({ isLoading: false });
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.initApi();
          let projectStagetFilter = ProposalSerivice.getFilterProjectStage();
          if (
            projectStagetFilter &&
            Object.keys(projectStagetFilter).length > 0
          ) {
            // projectStagetFilter.project_status=controls.stage.value;
            // if(controls.stage.value === 'Proposal accepted' &&
            // projectStagetFilter.stage === 'Recci in progress'){
            //   projectStagetFilter.stage = 'Layout & agreement';
            // }
            // ProposalSerivice.setFilterInProjectStage(projectStagetFilter)
            projectStagetFilter.proposal_status =
              controls_proposal_status_update.proposal_status;
            ProposalSerivice.setFilterInProjectStage(projectStagetFilter);
          } else {
            ProposalSerivice.setFilterInProjectStage({
              proposal_status: controls_proposal_status_update.proposal_status,
            });
          }
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // let err = e.response && e.response.data && e.response.data.error[0] ? e.response.data.error[0] : null;
        // const message = err && err.message ? err.message : ERROR_MESSAGE;
        // this.setState({ isLoading: false });
      });
  };

  onChangeInauguration_date = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    if (!this.checkActiveStatus()) {
      return false;
    }
    const { controls_inauguration } = this.state;
    controls_inauguration["inauguration_date"] = e;
    this.setState({ controls_inauguration });
  };

  openViewCloseViewDetail = () => {
    this.props.openViewCloseViewDetail();
  };
  render() {
    const { proposalStageDetail, isCompletedAllStages } = this.props;

    const { proposal_status } = proposalStageDetail
      ? proposalStageDetail
      : {
        proposal_status: [],
      };
    const {
      controls_inauguration,
      controls_proposal_status_update,
    } = this.state;
    let isDisplayInaugration = false;
    if (
      proposalStageDetail.proposal_status &&
      proposalStageDetail.proposal_status.length > 0 &&
      proposalStageDetail.proposal_status[0].project_status &&
      proposalStageDetail.proposal_status[0].project_status ===
      "Proposal accepted"
    ) {
      isDisplayInaugration = true;
    }
    // if (
    //   proposalStageDetail.stages_details &&
    //   proposalStageDetail.stages_details.length > 0
    // ) {
    //   let indexInaugration = -1;
    //   if (
    //     proposal_status.length > 0 &&
    //     proposal_status[0].outlet_type === "PTP"
    //   ) {
    //     indexInaugration = proposalStageDetail.stages_details.findIndex(
    //       (x) => x.stage && x.stage.toLowerCase() === "branding completed"
    //     );
    //   } else {
    //     indexInaugration = proposalStageDetail.stages_details.findIndex(
    //       (x) => x.stage && (x.stage.toLowerCase() === "hand over" ||
    //         x.stage.toLowerCase() === "pre inauguration" || x.stage.toLowerCase() === "inaugurated"
    //         || x.stage.toLowerCase() === "project completed")
    //     );
    //   }
    //   if (indexInaugration > -1) {
    //     isDisplayInaugration = true;
    //   }
    // }

    let handover = [];
    // let minDateInaugration = null,
    //   project_completed = [],
    //   isProject_completed = false,
    //   pre_inauguration = [];
    if (
      proposal_status.length > 0 &&
      proposal_status[0].outlet_type !== "PTP"
    ) {
      handover = proposalStageDetail.stages_details.filter(
        (x) => x.stage && x.stage === "Hand Over"
      );
    } else {
      handover = proposalStageDetail.stages_details.filter(
        (x) => x.stage && x.stage === "Branding Completed"
      );
    }

    // if (handover.length > 0) {
    //   // let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
    //   //minDateInaugration = new Date(handover[0].completed_date.replace(pattern, '$3-$2-$1'));
    // }
    // project_completed = proposalStageDetail.stages_details.filter(
    //   (x) => x.stage && x.stage === "Project completed"
    // );
    // if (project_completed.length > 0 && project_completed[0].completed_date) {
    //   isProject_completed = true;
    // }
    // pre_inauguration = proposalStageDetail.stages_details.filter(
    //   (x) => x.stage && x.stage === "Pre inauguration"
    // );

    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        {proposal_status && proposal_status.length > 0 && (
          <>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6" md="12" xs="6">
                    <b>Proposal Overview</b>
                  </Col>
                  <Col
                    xl="6"
                    md="12"
                    xs="6"
                    className="d-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                      color="warning"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={(e) => this.openViewCloseViewDetail()}
                    >
                      {this.props.is_display_view_deatil
                        ? "Hide Detail"
                        : "View Detail"}
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Proposal Type</Label>
                      <span className="text-capitalize">
                        {proposal_status[0].proposal_type}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Type of Outlet</Label>
                      <span>{proposal_status[0].outlet_type}</span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Name of Dealer</Label>
                      <span className="text-capitalize">
                        {proposal_status[0].name}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Contact No</Label>
                      <span>{proposal_status[0].mobile}</span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Address</Label>
                      <span className="text-capitalize">
                        {proposal_status[0].address}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Territory</Label>
                      <span className="text-capitalize">
                        {proposal_status[0].area}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">Region</Label>
                      <span className="text-capitalize">
                        {proposal_status[0].region}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl="2" md="12" xs="12">
                    <FormGroup className="d-flex-column">
                      <Label className="font-weight-bold">
                        Contact-JK Tyre rep(Sales Officer)
                      </Label>
                      <span className="text-capitalize">
                        {proposal_status[0].so ? (
                          proposal_status[0].so
                        ) : (
                          <span className="no-data-hypen"></span>
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  {
                    <Col xl="2" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label className="font-weight-bold">Territory Manager</Label>
                        <span className="text-capitalize">
                          {proposal_status[0].area_manager}
                        </span>
                      </FormGroup>
                    </Col>
                  }
                  {
                    <Col xl="2" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label className="font-weight-bold">
                          Territory Manager Mobile
                        </Label>
                        <span>{proposal_status[0].manager_phone}</span>
                      </FormGroup>
                    </Col>
                  }
                  {/* {
                    proposal_status[0].architect_name &&
                    <Col xl="2" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label className="font-weight-bold">Architect</Label>
                        <span className="text-capitalize">{proposal_status[0].architect_name}</span>
                      </FormGroup>
                    </Col>
                  }
                  {
                    proposal_status[0].architect_phone &&
                    <Col xl="2" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label className="font-weight-bold">Architect Mobile</Label>
                        <span>{proposal_status[0].architect_phone}</span>
                      </FormGroup>
                    </Col>
                  } */}
                  {controls_inauguration.inauguration_date && (
                    <Col xl="2" md="12" xs="12">
                      <FormGroup className="d-flex-column">
                        <Label className="font-weight-bold">
                          Date of Inauguration
                        </Label>
                        <span>
                          {controls_inauguration.inauguration_date ? (
                            <Moment format="DD-MM-YYYY">
                              {controls_inauguration.inauguration_date}
                            </Moment>
                          ) : (
                            <span className="no-data-hypen"></span>
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {this.state.userDetail.type &&
                  this.state.userDetail.type.toLowerCase() !== Roles.AGENCY_ROLE &&
                  isDisplayInaugration && (
                    <>
                      <Row>
                        <Col xl="3" md="12" xs="12">
                          <FormGroup className="d-flex-column">
                            <Label>Date of Inauguration</Label>
                            <Label
                              onClick={(e) =>
                                this.calendar1.state.open && e.preventDefault()
                              }
                            >
                              <InputGroup>
                                <DatePicker
                                  className={"form-control"}
                                  onChange={(e) =>
                                    this.onChangeInauguration_date(e)
                                  }
                                  name="inauguration_date"
                                  selected={
                                    controls_inauguration.inauguration_date
                                      ? Date.parse(
                                        controls_inauguration.inauguration_date
                                      )
                                      : null
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  showMonthDropdown
                                  ref={(r) => (this.calendar1 = r)}
                                  shouldCloseOnSelect={true}
                                  showYearDropdown
                                  // minDate={minDateInaugration ? minDateInaugration : new Date()}
                                  dropdownMode="select"
                                  disabled={
                                    this.state.userDetail.access_level ===
                                    "brand_store"
                                  }
                                />
                                <InputGroupAddon addonType="append">
                                  <div className="input-group-text">
                                    <FontAwesomeIcon
                                      htmlFor="datepicker"
                                      icon={faCalendarAlt}
                                    />
                                  </div>
                                </InputGroupAddon>
                              </InputGroup>
                            </Label>
                          </FormGroup>
                        </Col>
                        {this.state.userDetail.type.toLowerCase() !==
                          Roles.AGENCY_ROLE &&
                          this.state.userDetail.access_level !== "region" &&
                          this.state.userDetail.access_level !== "zone" &&
                          this.state.userDetail.access_level !== "brand_store" ? (
                          <Col
                            xl="3"
                            md="12"
                            xs="12"
                            className="d-flex-column"
                            style={{ marginBottom: "1%" }}
                          >
                            <div>
                              <Button
                                className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                                color="warning"
                                type="submit"
                                onClick={(e) => this.submitInaugration()}
                                style={{
                                  paddingLeft: "25px",
                                  paddingRight: "25px",
                                  marginTop: "30px",
                                }}
                              >
                                {controls_inauguration.isUpdate
                                  ? "update"
                                  : "submit"}
                              </Button>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </>
                  )}
              </CardBody>
            </Card>
            <br />
            {(this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE ||
              (this.state.userDetail.type &&
                this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE)) &&
              !isCompletedAllStages && (
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" md="12" xs="12">
                        <b>Update Proposal</b>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xl="3" md="12" xs="12">
                        <FormGroup>
                          <Label for="phone">Active / On Hold / Dropped</Label>
                          <select
                            className="form-control select-height-70rem"
                            name="proposal_status"
                            value={
                              controls_proposal_status_update.proposal_status
                            }
                            onChange={(e) => {
                              this.handleInputChangeStatus(e);
                            }}
                          >
                            <option value="Active">Active</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Dropped">Dropped</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {this.state.userDetail.access_level !== "area" &&
                        this.state.userDetail.access_level !== "region" &&
                        this.state.userDetail.access_level !== "zone" ? (
                        <Col xl="3" md="12" xs="12">
                          <FormGroup>
                            <Label>Remarks</Label>
                            <Input
                              type="text"
                              name="remarks"
                              placeholder=""
                              onChange={(e) => {
                                this.handleInputChangeStatus(e);
                              }}
                              value={controls_proposal_status_update.remarks}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        ""
                      )}
                      {this.state.userDetail.access_level !== "area" &&
                        this.state.userDetail.access_level !== "region" &&
                        this.state.userDetail.access_level !== "zone" ? (
                        <Col
                          xl="6"
                          md="12"
                          xs="12"
                          className="d-flex-column"
                          style={{ marginBottom: "1%" }}
                        >
                          <div
                            style={{ marginTop: "auto" }}
                            className="ml-auto"
                          >
                            <Button
                              className="mb-1 mr-1 btn-pill action-button action-ob-button font-weight-bold"
                              color="warning"
                              type="submit"
                              onClick={() => this.submitPrposalStatus()}
                              style={{
                                paddingLeft: "25px",
                                paddingRight: "25px",
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )}
          </>
        )}
        {/* 
        <br />
        <Card >
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Interim visit</b>
              </Col>
            </Row>
            <hr />
            <Table className="mb-0  tbl-about tbl-about table-responsive">
              <thead>
                <tr>
                  <th scope="col">Picture Interim Visit</th><th scope="col">Picture Weekly Visit</th><th scope="col">Interim Visit Date</th><th scope="col">Interim Visit Report</th><th scope="col">Weekly report date</th><th scope="col">Weekly status report</th><th scope="col">Insert Date </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>03 Sep 2019</td><td>Branding work in progress handover the site 5th September</td><td>11 Sep 2019</td>
                </tr><tr>
                  <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>27 Aug 2019</td><td>Branding work in progress.  Agency handover on 4-Aug-2019.</td><td>11 Sep 2019</td>
                </tr><tr>
                  <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>20 Aug 2019</td><td>Civil work completed.  Branding material will dispatch on 22-Aug-2019.  </td><td>20 Aug 2019</td>
                </tr><tr>
                  <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>13 Aug 2019</td><td>POP and Paint work in progress dealer handover the site with in 3 days</td><td>13 Aug 2019</td>
                </tr><tr>
                  <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>26 Jul 2019</td><td>Estimate received and approved.  Civil work is complete.  </td><td>29 Jul 2019</td>
                </tr>

              </tbody>
            </Table>
            <br /><br />
            <Table className="mb-0  tbl-about tbl-about table-responsive">
              <thead>
                <tr>
                  <th scope="col">Work Completed</th><th scope="col">Remarks</th><th scope="col">Images</th><th scope="col">Work Completed Date</th><th scope="col">ReportDate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Yes</td><td>Civil work is complete at site on 23-Aug-2019.  Agency The design grid will start the work from 25-A</td><td> <a className='aa' target='_blank'  rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/Dealer_work_in_progress_b09028a3_2669_IMG-20190812-WA0003.jpg'>
                    <img src='https://www.jktyreadvantage.com/SocialPhoto/ProposalFile/Arch/Dealer_work_in_progress_b09028a3_2669_IMG-20190812-WA0003.jpg' alt='' height='60px' width='60px' /></a></td><td>9/11/2019 7:10:40 PM</td><td>9/11/2019 7:10:40 PM</td>
                </tr>

              </tbody>
            </Table>
          </CardBody>
        </Card>
        <br />
        <Card >
          <CardBody>
            <Row>
              <Col xl="12" md="12" xs="12">
                <b>Layout Stage</b>
              </Col>
            </Row>
            <hr />
            <Table className="mb-0  tbl-about tbl-about table-responsive">
              <thead>
                <tr>
                  <th scope="col"> </th><th scope="col">Site Picture 1</th><th scope="col">Basic Drawing 1</th><th scope="col">Detailed Drawing 1</th><th scope="col">Pert Chart 1</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Layout</td><td> <a className='aa' target='_blank' rel="noopener noreferrer"  href='SocialPhoto/ProposalFile/Arch/SitePicture_SW49ccce03_2669_PURBANCHALTYRES,GUWAHATI(wecompress.com)PPT.pdf'><img src='images/pdf_format.png' alt='' height='60px' width='60px' /></a></td><td> <a className='aa' target='_blank'  rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/layout4e37f7cb_2669_PURBANCHALTYRES,GUWAHATILayoutPlan.pdf'><img src='images/pdf_format.png' alt='' height='60px' width='60px' /></a></td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/drawings_5fa8c853_2669_DETAILDRAWING.pdf'><img src='images/pdf_format.png' height='60px' width='60px' alt='' /></a></td><td> <a className='aa' target='_blank' rel="noopener noreferrer" href='SocialPhoto/ProposalFile/Arch/pert_chart_a025339c_2669_PertChart.pdf'><img src='images/pdf_format.png' alt='' height='60px' width='60px' /></a></td>
                </tr><tr>
                  <td>Date</td><td>Jun 20 2019  4:31:32:050PM</td><td>Jun 20 2019  3:45:33:280PM</td><td>Jun 25 2019  3:08:33:193PM</td><td>Sep 12 2019  3:03:28:973PM</td>
                </tr>

              </tbody>
            </Table>
          </CardBody>
        </Card> */}
        <br />
      </>
    );
  }
}

export default ProposalStatus;
