import React, { Component, Fragment } from "react";
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Chart from "react-apexcharts";
import Ionicon from 'react-ionicons';
import Sidebar from "react-sidebar";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import PrimaryPointsReportModal from "../../modal/PrimaryPointsReportModal/PrimaryPointsReportModal";
import PcrSchemeDealerModal from "../../modal/PcrSchemeDealerModal/PcrSchemeDealerModal";
import DashboardService from "../../services/DashboardService";
import PrimaryPointsReportService from '../../services/PrimaryPointsReportService';
import StorageService from "../../services/Storage";
import TradeSchemeService from '../../services/TradeSchemeService';
import DealerProfile from "../../components/DealerProfile/DealerProfile";

import {
  formatNumber,
  getCurrentQuarter,
  getCurrentYear
} from "../../utils/utils";

import './PrimaryPointsReportDealer.css';
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

let sapId = null;
// let dealerSapId = null;
let delearName = null;
let dealerType = null;
let from = null;

class PrimaryPointsReportDealer extends Component {

  state = {
    offtakeTrendChartData: null,
    areaTrendChartData: null,
    warrantyTrendChartData: null,
    allMonthOfftakeTrendChartData: null,
    dealerTargetAchievementData: {},
    offtakePoints: {},
    year: 2019,
    category: 'pcr',
    displayLabels: null,
    manageUserLevel: [],
    currentUser: null,
    randomKey: null,
    dealerRole: null,
    isPrimaryPointReportModalOpen: false,
    isDealerProfileModalOpen: false,
    isTradeSchemeModalOpen: false,
    isTradeSchemeButtonEnable: false,
    isLoading: false,
    months: [
      {
        key: 'Jan',
        value: 1
      },
      {
        key: 'Feb',
        value: 2
      },
      {
        key: 'Mar',
        value: 3
      },
      {
        key: 'April',
        value: 4
      },
      {
        key: 'May',
        value: 5
      },
      {
        key: 'Jun',
        value: 6
      },
      {
        key: 'Jul',
        value: 7
      },
      {
        key: 'Aug',
        value: 8
      },
      {
        key: 'Sep',
        value: 9
      },
      {
        key: 'Oct',
        value: 10
      },
      {
        key: 'Nov',
        value: 11
      },
      {
        key: 'Dec',
        value: 12
      }
    ],
    years: [],
    controls: {
      month: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        invalidLength: null,
        showErrorMsg: false
      },
      year: {
        value: null,
        valid: true,
        touched: false,
        required: false,
        invalidLength: null,
        showErrorMsg: false
      },
    },
    userDetail: null,
    dealerDetail: null,
    address: null
  };

  // constructor() {
  //   super();
  //   // this.chartApi();
  // }

  componentDidMount() {
    // this.checkUserAgent();
    this.setInitialDropdownValue();
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type, userDetail });
    const query = new URLSearchParams(this.props.location.search);
    sapId = query.get("id");
    delearName = query.get("name");
    dealerType = query.get("type");
    from = query.get("from");
    const id = query.get("k");

    let manageUserLevel = [];
    let currentUser = null;
    if (id) {
      manageUserLevel = StorageService.getNavigationLevelDetail(id);
      if (manageUserLevel.length > 0) {
        currentUser = manageUserLevel[manageUserLevel.length - 1];
        if (currentUser && currentUser.uuid) {
          sapId = currentUser.uuid;
          delearName = currentUser.name;
          dealerType = currentUser.type;
        }
      }
      this.setState({ currentUser, manageUserLevel, randomKey: id });
    }
    this.initApi(sapId);
  }

  getUserDetail(sapId) {
    DashboardService.getUserInfoByUUId(sapId).then(response => {
      if (response.data && response.data.data) {
        let { dealerDetail, address } = this.state;
        dealerDetail = response.data.data;
        address = ``;
        if (dealerDetail.address_line_1) {
          address = `${dealerDetail.address_line_1}`;
        }
        if (dealerDetail.address_line_2) {
          address = `${address}, ${dealerDetail.address_line_2}`;
        }
        if (dealerDetail.address_line_3) {
          address = `${address}, ${dealerDetail.address_line_3}`;
        }
        if (dealerDetail.address_line_4) {
          address = `${address}, ${dealerDetail.address_line_4}`;
        }
        this.setState({ dealerDetail, address });
      }
    }).catch(e => {
      console.error("e", e);
    })
  }

  setInitialDropdownValue = () => {
    const { controls } = this.state;
    const { month, year } = controls;
    // let startYear = 2019;
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    let currentMonth = todayDate.getMonth() + 1;
    month.value = currentMonth;
    year.value = currentYear;
    let years = this.setIntitalYearDropDownList();
    this.setState({ controls, years });
  }

  setIntitalYearDropDownList = () => {
    let startYear = 2019;
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    let years = [];
    for (let i = startYear; i <= currentYear; i++) {
      let obj = {
        key: i,
        value: i
      }
      years.push(obj);
    }
    return years;
  }

  initApi = (Id) => {
    this.setState({ isLoading: true });
    return this.filterAPI()
      .then(() => {
        return Promise.all([
          this.getDisplayLabel(),
          this.getDealerOfftakeAndPoints(),
          this.getDealerReportTargetAchievement(),
          this.chartApi(),
          this.getUserDetail(Id)
        ])
      })
      .then(data => {
        this.setState({ isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      })
  }

  chartApi = () => {
    return new Promise((resolve, reject) => {
      return Promise.all([
        this.getTopSkuOfftakeTrend(),
        this.getTopSkuAreaTrend(),
        this.getTopSkuWarrantyTrend(),
        this.getAllMonthOfftakeTrend(),
        this.getTradeSchemes()
      ]).then(() => {
        return resolve();
      })
        .catch(e => {
          return reject();
        })
    })
  }

  // api's
  getTopSkuOfftakeTrend = () => {
    const { year, category } = this.state;
    return new Promise((resolve, reject) => {
      PrimaryPointsReportService.getTopSkuOfftakeTrend(sapId, year, category)
        .then(data => {
          if (data.data && data.data.data) {
            this.setState({ offtakeTrendChartData: data.data.data });
          }
          return resolve();
        })
        .catch(e => {
          this.setState({ offtakeTrendChartData: null });
          return reject(e);
        })
    })
  }

  getTopSkuAreaTrend = () => {
    const { year, category } = this.state;
    return new Promise((resolve, reject) => {
      PrimaryPointsReportService.getTopSkuAreaTrend(sapId, year, category)
        .then(data => {
          if (data.data && data.data.data) {
            this.setState({ areaTrendChartData: data.data.data });
          }
          return resolve();
        })
        .catch(e => {
          this.setState({ areaTrendChartData: null });
          return reject();
        })
    })
  }

  getTopSkuWarrantyTrend = () => {
    const { year, category } = this.state;
    return new Promise((resolve, reject) => {
      PrimaryPointsReportService.getTopSkuWarrantyTrend(sapId, year, category)
        .then(data => {
          if (data.data && data.data.data) {
            this.setState({ warrantyTrendChartData: data.data.data });
          } else {
            this.setState({ warrantyTrendChartData: null });
          }
          return resolve();
        })
        .catch(e => {
          this.setState({ warrantyTrendChartData: null });
          return reject();
        })
    })
  }

  getAllMonthOfftakeTrend = () => {
    const { year, category } = this.state;
    return new Promise((resolve, reject) => {
      PrimaryPointsReportService.getAllMonthOfftakeTrend(sapId, year, category)
        .then(data => {
          if (data.data && data.data.data) {
            this.setState({ allMonthOfftakeTrendChartData: data.data.data });
          }
          return resolve();
        })
        .catch(e => {
          return reject();
        })
    })
  }

  getDisplayLabel = () => {
    const { year } = this.state;
    return new Promise((resolve, reject) => {
      DashboardService.getDisplayLabel(year)
        .then(data => {
          const labels = data.data && data.data.data ? data.data.data : null;
          this.setState({ displayLabels: labels });
          return resolve();
        })
        .catch(e => {
          return reject();
        });
    })
  };

  getDealerOfftakeAndPoints = () => {
    const { year } = this.state;
    return new Promise((resolve, reject) => {
      DashboardService.getNewDashboardOfftake({ y: year, u: sapId })
        .then(res => {
          let { offtakePoints } = this.state;
          if (res.data.data) {
            offtakePoints = res.data.data;
          }
          this.setState({
            offtakePoints
          });
          return resolve();
        })
        .catch(e => {
          return reject();
        });
    })
  }

  getDealerReportTargetAchievement = () => {
    const { category, controls } = this.state;
    const { month, year } = controls;

    return new Promise((resolve, reject) => {
      PrimaryPointsReportService.getDealerReportTargetAchievement(sapId, month.value, year.value, category)
        .then(data => {
          if (data.data && data.data.data) {
            this.setState({ dealerTargetAchievementData: data.data.data });
          }
          return resolve();
        })
        .catch(e => {
          return reject();
        })
    })
  }

  getTradeSchemes = () => {
    const { userDetail } = this.state;
    return new Promise((resolve, reject) => {
      TradeSchemeService.getSchemes(this.state.category)
        .then(data => {
          const schemes = data.data && data.data.data ? data.data.data : [];
          if (schemes.length > 0) {
            let lastSchemeYear = schemes[0].year;
            let lastSchemeQuarter = schemes[0].quarter;
            let currentYear = getCurrentYear();
            let currentQuarter = getCurrentQuarter();
            let permissions = [];
            if (userDetail && userDetail.permissions) {
              permissions = userDetail.permissions;
            }
            if (
              lastSchemeYear === currentYear
              && currentQuarter === lastSchemeQuarter
              && (
                permissions.includes(Permissions.TRADE_SCHEME_REPORT)
                || permissions.includes(Permissions.TRADE_SCHEME)
              )
            ) {
              this.setState({ isTradeSchemeButtonEnable: true })
            } else {
              this.setState({ isTradeSchemeButtonEnable: false })
            }
          } else {
            this.setState({ isTradeSchemeButtonEnable: false })
          }
          this.setState({ schemes });
          return resolve();
        })
        .catch(e => {
          return reject(e);
          // const err = e.response && e.response.data && e.response.data.error && e.response.data.error[0] ? e.response.data.error[0] : null;
          // const message = err && err.message ? err.message : ERROR_MESSAGE;
          // ModalService.openAlert('', message, 'error');
        })
    })
  }

  filterAPI = () => {
    const filter = {
      y: null
    }
    return new Promise((resolve, reject) => {
      DashboardService.filterAPI(filter)
        .then(res => {
          if (res.data.data) {
            let yearOptions = res.data.data.year ? res.data.data.year : [];
            let year = yearOptions[0].value;
            this.setState({ year }, () => {
              return resolve();
            })
          } else {
            return resolve();
          }
        })
        .catch(err => {
          console.log("err", err);
          return reject();
        });
    })
  }

  // graph function
  generateTweleveMonthSalesTrendChart = (chartData) => {
    if (chartData) {
      return {

        series: [{
          name: '12 Month Data',
          type: 'column',
          data: chartData.allMonthData
        }, {
          name: 'Target',
          type: 'line',
          data: chartData.targetData
        },
        {
          name: 'Max',
          type: 'line',
          data: chartData.maxData
        }],
        options: {
          chart: {
            // height: 200,
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
          },

          colors: ['#CAD7E0', '#fdbb1f', '#545cd8'],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              dataLabels: {
                position: 'top'
              }
            }
          },
          stroke: {
            width: [0, 1, 0],
            curve: ['stepline', 'stepline', 'stepline'],
          },
          markers: {
            size: [0, 0, 7],
            showNullDataPoints: false,
            offsetX: 0,
            offsetY: 0,
          },
          // title: {
          //   show:false,
          //   text: 'Traffic Sources'
          // },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0],//charts where you dont want label
            formatter: function (val, opts) {
              if (!(val === null || val === 'null')) {
                return val;
              }
            },
            offsetY: -12,
            style: {
              fontSize: '10px',
              fontFamily: 'Helvetica',
              fontWeight: 'bold',
              colors: ['#495057'],
            },
            background: {
              enabled: false
            }
          },
          labels: chartData.label,
          xaxis: {
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0
            }
          },
          yaxis: [
            {
              tickAmount: 3,
              show: false,
              min: 0,
              max: chartData.max,
            },
            {
              tickAmount: 3,
              opposite: true,
              show: false,
              min: 0,
              max: chartData.max,
            },
            {
              tickAmount: 3,
              opposite: true,
              show: false,
              min: 0,
              max: chartData.max,
            }
          ],
          legend: {
            show: false
          },
          grid: {
            show: true,
            yaxis: {
              lines: {
                show: false
              }
            }
          },
          tooltip:
          {
            x: {
              show: false
            },
            y: {
              formatter: (val, opt) => {
                return val;
              },
              title: {
                formatter: (seriesName, opt) => {
                  if (seriesName === '12 Month Data') {
                    return opt.w.globals.categoryLabels[opt.dataPointIndex]
                  } else {
                    return seriesName;
                  }
                }
              },
            },
            marker: {
              show: false,
            }
          }
        },
      }
    } else {
      return null
    }
  }

  generateAreaWarrantyOfftakeTrendChart = (chartData, chartType) => {
    if (chartData) {
      return {

        series: [{
          name: 'Offtake',
          data: chartData.data
          // data: [100,200,300]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 380,
            toolbar: {
              show: false
            },
            // offsetY:20,
            offsetX: -10
          },
          plotOptions: {
            bar: {
              barHeight: '60%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom'
              }
            }
          },
          colors: ['#33b2df'],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              fontSize: '10px',
              fontFamily: 'Helvetica',
              fontWeight: 'normal',
              colors: ['#495057'],
            },
            formatter: function (val, opt) {
              return chartData.label[opt.dataPointIndex]
            },
            // offsetX: 10,
            dropShadow: {
              enabled: false
            }
          },
          // stroke: {
          //   width: 1,
          //   colors: ['#fff']
          // },
          xaxis: {
            categories: chartData.data,
            // chartData.label,
            // categories: ['Jan','Feb','Mar'],
            show: false,
            labels: {
              show: false
            },
            // axisBorder: {
            //   show: false,
            //   color: '#78909C',
            //   height: 1,
            //   width: '100%',
            //   offsetX: 0,
            //   offsetY: 0
            // },
            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: false
            },
            position: 'bottom'
          },
          yaxis: {
            labels: {
              show: chartType === 'area' ? false : true
            },
            axisBorder: {
              show: true,
              color: '#78909C',
              offsetX: 0,
              offsetY: 0,
              width: 0
            },
          },
          grid: {
            show: true,
            yaxis: {
              lines: {
                show: false
              }
            }
          },
          tooltip: {
            enabled: true,
            theme: 'dark',
            x: {
              show: true,
              formatter: (val, opt) => chartData.label[opt.dataPointIndex]
            },
            y: {
              formatter: (val) => chartType === 'area' ? null : val,
              show: false,
              title: {
                formatter: (seriesName) => chartType === 'area' ? null : 'Offtake',
              },
            },
            marker: {
              show: false,
            },
          },
          legend: {
            show: false
          },
          fill: {
            type: 'gradient',
            colors: ['#f4df6f', '#f3e184', '#f9dd95', '#f6dd9d', '#f8e2ab', '#f9e7b8', '#faecc7', '#fcf1d5', '#fcf3dc', '#fef8ea'],
            gradient: {
              shade: 'light',
              type: "horizontal",
              // shadeIntensity: 0.5,
              gradientToColors: ['#fccc50', '#f2ce72', '#f3e6a0', '#f7eaa9', '#f8edb6', '#f9f0c2', '#fbf3ce', '#fcf6da', '#fcf7e0', '#fefbed'],
              inverseColors: true,
              stops: [0, 50, 100],
              colorStops: []
            }
          }
        }
      }
    } else {
      return null
    }
  }

  generateChartToDisplay = (chartData, chart, chartType) => {
    let chartTitle = '';
    if (chartType === 'area') {
      chartTitle = 'Area'
    }
    if (chartType === 'offtake') {
      chartTitle = 'Offtake'
    }
    if (chartType === 'warranty') {
      chartTitle = 'Warranty'
    }
    return chartData.data
      && Array.isArray(chartData.data)
      && chartData.data.length > 0 ?
      <>
        <div className="hide-x-axis"></div>
        <Chart options={chart.options} series={chart.series} type="bar" height={250} />
        {/* <div className="hide-x-axis">&nbsp;</div> */}
        <div className="text-align-center chart-title">{chartTitle} Trend</div>
      </> :
      <>
        <div className="display-content-center height-100 text-align-center" style={{ height: '265px' }}>There is no {chartType} data to display</div>
        <div className="text-align-center chart-title">{chartTitle} Trend</div>
      </>
  }

  changeTab = (e) => {
    let mapIndexToCategory = {
      0: 'pcr',
      1: 'tbb',
      2: 'tbr',
      3: 'lcv',
      4: 'scv',
      5: 'two_three_wheelers',
      6: 'farm'
    }
    this.setState({ category: mapIndexToCategory[e], isLoading: true }, () => {
      this.chartApi().then(() => {
        this.setState({ isLoading: false });
      })
        .catch(e => {
          this.setState({ isLoading: false });
        })
    });
  }

  handleInputChange = (e) => {
    let todayDate = new Date();
    let currentMonth = todayDate.getMonth() + 1;
    let currentYear = todayDate.getFullYear();

    const controlName = e.target.name;
    const controlValue = e.target.value;
    let { controls, years } = this.state;
    const { year } = controls;
    let previousControlValue = controls[controlName].value;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    if (controlName === 'month' && parseInt(controlValue) > currentMonth) {
      if (parseInt(previousControlValue) <= currentMonth) {
        year.value = currentYear - 1;
        years.pop();
      }
    } else {
      years = this.setIntitalYearDropDownList();
    }
    this.setState({ controls, years, isLoading: true }, () => {
      this.getDealerReportTargetAchievement()
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(e => {
          this.setState({ isLoading: false });
        })
    });

  }

  getDataFromNavigation = (index) => {
    let { randomKey,
      // manageUserLevel
    } = this.state;
    // manageUserLevel = manageUserLevel.slice(0, index)
    // const userData = manageUserLevel[index - 1];
    // if (userData && userData.uuid) {
    this.props.history.push(
      `/offtake-score-card-report?id=${randomKey}&i=${index}&y=${this.state.year}`
    );
    // let uuid = userData.uuid;
    // this.setState({ page: 1, currentUser: userData, manageUserLevel });
    // this.getAllDealerReport(1, null, null, uuid);
    // }
  };

  getPageTitle = () => {
    const { dealerRole } = this.state;
    let title = `Offtake Scorecard`
    if ((from === 'point-dashboard' || from === 'ho-dashboard')) {
      title = 'Dashboard'
    }
    title += ' Of '
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      title += "Head Office";
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      title += "Zone Manager";
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      title += "Region Manager";
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      title += "Sales Officer";
    } else if (dealerRole === Roles.ZTM_ROLE) {
      title += "Zonal Trade Marketing";
    }
    else {
      title += "Territory Manager";
    }
    return title
  };

  gotoPrimaryReportAllPage = () => {
    let url = `/offtake-score-card-report?y=${this.state.year}`
    if (from === 'ho-dashboard') {
      url = `/dashboard?y=${this.state.year}`
    }
    if (from === 'point-dashboard') {
      url = `/point-dashboard?y=${this.state.year}`
    }
    this.props.history.push(url);
  };

  openPrimaryPointReportModal = () => {
    this.setState({ isPrimaryPointReportModalOpen: true });
  }

  closePrimaryPointReportModal = () => {
    this.setState({ isPrimaryPointReportModalOpen: false });
  }

  openTradeSchemeDealerModal = () => {
    this.setState({ isTradeSchemeModalOpen: true });
  }

  closeTradeSchemeModal = () => {
    this.setState({ isTradeSchemeModalOpen: false });
  }

  openDealerProfileModal = () => {
    this.setState({ isDealerProfileModalOpen: true });
  }

  closeDealerProfileModal = () => {
    this.setState({ isDealerProfileModalOpen: false });
  }

  prepareDealerTargetAchievementTableRows = () => {
    const { dealerTargetAchievementData = {} } = this.state;
    const { isTradeScheme = null, isSpecialOffer1 = null, isSpecialOffer2 = null } = dealerTargetAchievementData;
    let length = 0;
    if (dealerTargetAchievementData.data) {
      length = dealerTargetAchievementData.data.length
    }
    return dealerTargetAchievementData.data && dealerTargetAchievementData.data.map((d, index) => {
      return <tr>
        <td className={`table-data-text table-left-no-border ${index === length - 1 ? 'table-bottom-no-border' : ''} text-align-left bold-border`}>{d.category}</td>
        <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.offtakeTarget)}</td>
        <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.offtakeAchievement)}</td>
        <td
          className={`table-data-text table-balance ${index === length - 1 ? 'table-bottom-no-border' : ''} ${!isTradeScheme ? 'table-right-no-border' : ''}`}
        >{this.getNumberToDisplay(d.offtakeBalance)}</td>
        {isTradeScheme && <>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.tradeSchemeTarget)}</td>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.tradeSchemeAchievement)}</td>
          <td className={`table-data-text table-balance ${index === length - 1 ? 'table-bottom-no-border' : ''} ${!isSpecialOffer1 ? 'table-right-no-border' : ''}`}>{this.getNumberToDisplay(d.tradeSchemeBalance)}</td>
        </>
        }
        {isSpecialOffer1 && <>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.specialOffer1Target)}</td>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.specialOffer1Achievement)}</td>
          <td className={`table-data-text table-balance ${index === length - 1 ? 'table-bottom-no-border' : ''} ${!isSpecialOffer2 ? 'table-right-no-border' : ''}`}>{this.getNumberToDisplay(d.specialOffer1Balance)}</td>
        </>
        }
        {isSpecialOffer2 && <>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.specialOffer2Target)}</td>
          <td className={`table-data-text ${index === length - 1 ? 'table-bottom-no-border' : ''}`}>{this.getNumberToDisplay(d.specialOffer2Achievement)}</td>
          <td className={`table-data-text table-balance ${index === length - 1 ? 'table-bottom-no-border' : ''} table-right-no-border`}>{this.getNumberToDisplay(d.specialOffer2Balance)}</td>
        </>
        }
      </tr>
    })
  }

  getNumberToDisplay = (value) => {
    if (value > 0) {
      return value
    } else {
      return '-'
    }
  }

  redirectToTradeSchemeReport = () => {
    this.props.history.push('/trade-scheme-report');
  }

  render() {
    const {
      allMonthOfftakeTrendChartData,
      offtakeTrendChartData,
      areaTrendChartData,
      warrantyTrendChartData,
      displayLabels,
      dealerTargetAchievementData = {},
      offtakePoints = {},
      isPrimaryPointReportModalOpen,
      isTradeSchemeModalOpen,
      isDealerProfileModalOpen,
      isTradeSchemeButtonEnable,
      isLoading,
      category,
      controls,
      months,
      years,
      dealerDetail,
      address
    } = this.state;
    const { month, year } = controls;
    const { isTradeScheme = null, isSpecialOffer1 = null, isSpecialOffer2 = null } = dealerTargetAchievementData;
    const pageTitle = this.getPageTitle();

    let twelveMonthSalesTrendReport = this.generateTweleveMonthSalesTrendChart(allMonthOfftakeTrendChartData);
    let offtakeTrendChart = this.generateAreaWarrantyOfftakeTrendChart(offtakeTrendChartData, 'offtake');
    let warrantyTrendChart = this.generateAreaWarrantyOfftakeTrendChart(warrantyTrendChartData, 'warranty');
    let areaTrendChart = this.generateAreaWarrantyOfftakeTrendChart(areaTrendChartData, 'area');

    const navigation =
      this.state.manageUserLevel.length > 0 ? (
        this.state.manageUserLevel.map((m, index) => (
          <Fragment>
            <span>
              <Ionicon
                icon="ios-arrow-forward"
                fontSize="34px"
                color="#fdbb1f"
              />
            </span>
            {m.role !== "dealer" && (
              <span
                className="prev-page-link cursor-pointer title-case"
                onClick={this.getDataFromNavigation.bind(this, index + 1)}
              >
                {`Offtake scorecard of ${m.name} (${m.role})`}
              </span>
            )}
            {m.role === "dealer" && (
              <>
                <span className="current-page title-case">
                  {`Report of ${m.name}`}
                </span>
                {dealerType && <span className="dealer-type title-case">({dealerType})</span>}
              </>
            )}
          </Fragment>
        ))
      ) : (
        <Fragment>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span className="current-page title-case">
            Report of {delearName}
            {(dealerDetail && dealerDetail.display_type) && <span className="dealer-type">({dealerDetail && dealerDetail.display_type})</span>}
            &nbsp; SAS: {dealerDetail && dealerDetail.sas_average_turn_over ? dealerDetail.sas_average_turn_over : '--'}
          </span>
          {dealerDetail && dealerDetail.type.toLowerCase() === "dealer" && (
            <span className="prev-page-link cursor-pointer title-case"
              onClick={this.openDealerProfileModal}
            >&nbsp; &nbsp;View Profile</span>
          )}
        </Fragment>
      );

    return <div id="primary-point-report-dealer">
      {isLoading && <CustomSpinner />}
      {isPrimaryPointReportModalOpen && <PrimaryPointsReportModal
        show={isPrimaryPointReportModalOpen}
        closeModal={this.closePrimaryPointReportModal}
        sapId={sapId}
      >
      </PrimaryPointsReportModal>}

      {isTradeSchemeModalOpen && category === 'pcr' && <PcrSchemeDealerModal
        show={isTradeSchemeModalOpen}
        closeModal={this.closeTradeSchemeModal}
        sapId={sapId}
      >
      </PcrSchemeDealerModal>}

      {isDealerProfileModalOpen && (
        <Sidebar
          sidebar={
            <>
              <DealerProfile
                closeModal={this.closeDealerProfileModal}
                dealerDetail={dealerDetail}
                address={address}
              />
            </>
          }
          open={isDealerProfileModalOpen}
          sidebarClassName="dealer-profile"
          pullRight
          styles={{
            sidebar: { background: "white", height: "90%", top: "60px", overflowY: 'auto !important' }
          }}
        />
      )}

      {sapId && (
        <Row>
          <Col>
            <span
              className="prev-page-link cursor-pointer title-case"
              onClick={this.gotoPrimaryReportAllPage}
            >
              {pageTitle}
            </span>
            {navigation}
            {/* <span>
              <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
            </span>
            <span className="current-page">Report of {delearName}</span> */}
          </Col>
        </Row>
      )}
      <br />
      <Row>
        <Col sm="3" className="padding-right-0">
          <Card className="offtake-card">
            <CardBody>
              <div className="title-text title-margin">Offtake Report</div>
              {/* <Row>
              <Col sm="4"> */}
              <div className="offtake-data-container-main">
                <div className="offtake-data-container">
                  <div className="offtake-card-big-label">Year</div>
                  <div className="offtake-card-small-label">
                    {displayLabels && displayLabels.year}
                  </div>
                  <div className="offtake-card-count">
                    {offtakePoints.total_offtake ? formatNumber(offtakePoints.total_offtake) : 0}
                  </div>
                </div>
                {/* </Col> */}
                {/* <Col> */}
                <div className="offtake-data-container">
                  <div className="offtake-card-big-label">Quarter</div>
                  <div className="offtake-card-small-label">
                    {displayLabels && displayLabels.quarter}
                  </div>
                  <div className="offtake-card-count">
                    {offtakePoints.quarter_total_offtake ? formatNumber(offtakePoints.quarter_total_offtake) : 0}</div>
                </div>
                {/* </Col> */}
                {/* <Col> */}
                <div className="offtake-data-container">
                  <div className="offtake-card-big-label">Month</div>
                  <div className="offtake-card-small-label">
                    {displayLabels && displayLabels.month}
                  </div>
                  <div className="offtake-card-count">
                    {offtakePoints.month_total_offtake ? formatNumber(offtakePoints.month_total_offtake) : 0}
                  </div>
                </div>
              </div>
              {/* </Col> */}
              {/* </Row> */}
              <Row>
                <Col sm="5">
                  <div className="offtake-card-big-label">Total Points</div>
                  <div className="offtake-card-count">
                    {offtakePoints.points ? formatNumber(offtakePoints.points) : 0}
                  </div>
                </Col>
                <Col className="point-report-button-container" sm="7">
                  <button className="point-report-button" onClick={this.openPrimaryPointReportModal}>
                    <Row>
                      <Col sm="9" className="padding-right-0">
                        <span>Points Report</span>
                      </Col>
                      <Col className="text-alignn-right padding-left-5" sm="3">
                        <span>
                          <Ionicon
                            icon="ios-arrow-forward"
                            fontSize="19px"
                            color="#545cd8"
                          />
                        </span>
                      </Col>
                    </Row>

                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="9">
          <Card className="target-achievement-table-card">
            <CardBody className="target-achievement-table-card-body">
              <Table>
                <thead>
                  <th className="table-header-text table-left-no-border table-top-no-border category-cell bold-border card-radius-4 category-width">Categories</th>
                  <th colSpan="3" className={`table-header-text table-top-no-border ${!isTradeScheme ? 'table-right-no-border' : ''} bold-border`}>Offtake</th>
                  {isTradeScheme && <th colSpan="3" className={`table-header-text table-top-no-border ${!isSpecialOffer1 ? 'table-right-no-border' : ''} bold-border`}>Trade Scheme</th>}
                  {isSpecialOffer1 && <th colSpan="3" className={`table-header-text table-top-no-border ${!isSpecialOffer2 ? 'table-right-no-border' : ''} bold-border`}>Special Offer 1</th>}
                  {isSpecialOffer2 && <th colSpan="3" className={`table-header-text table-top-no-border table-right-no-border bold-border`}>Special Offer 2</th>}
                </thead>
                <tbody>
                  <td className="table-header-text table-left-no-border text-align-left table-top-no-border  bold-border">
                    <select
                      className="year-month-drop-down month-drop-down"
                      name="month"
                      value={month.value}
                      onChange={this.handleInputChange}
                    >
                      {months.map(m =>
                        <option value={m.value}>{m.key}</option>
                      )}
                    </select>
                    &nbsp;
                    <select
                      className="year-month-drop-down year-drop-down"
                      name="year"
                      value={year.value}
                      onChange={this.handleInputChange}
                    >
                      {years.map(m =>
                        <option value={m.value}>{m.key}</option>
                      )}
                    </select>
                    {/* {dealerTargetAchievementData.month} */}
                  </td>
                  <td className="table-header-text">Target</td>
                  <td className="table-header-text">Achv.</td>
                  <td className={`table-header-text ${!isTradeScheme ? 'table-right-no-border' : ''} bold-border`}>Balance</td>
                  {isTradeScheme && <>
                    <td className="table-header-text">Target</td>
                    <td className="table-header-text">Achv.</td>
                    <td className={`table-header-text ${!isSpecialOffer1 ? 'table-right-no-border' : ''} bold-border`}>Balance</td>
                  </>
                  }
                  {isSpecialOffer1 && <>
                    <td className="table-header-text">Target</td>
                    <td className="table-header-text">Achv.</td>
                    <td className={`table-header-text ${!isSpecialOffer2 ? 'table-right-no-border' : ''} bold-border`}>Balance</td>
                  </>
                  }
                  {isSpecialOffer2 && <>
                    <td className="table-header-text">Target</td>
                    <td className="table-header-text">Achv.</td>
                    <td className={`table-header-text table-right-no-border bold-border`}>Balance</td>
                  </>
                  }
                  {this.prepareDealerTargetAchievementTableRows()}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="7">
          <Tabs
            defaultActiveKey="0"
            className="primary-report-tabs"
            onChange={this.changeTab}
            renderTabBar={() => (
              <ScrollableInkTabBar
                extraContent={
                  <></>
                  //   <div className="d-flex-row Download-Full-Report-wrapp">
                  //     <img src="images/group-6.svg" alt="" />
                  //     &nbsp;
                  // <span className="Download-Full-Report">Download Full Report</span>
                  //   </div>
                }
              />
            )}
            renderTabContent={() => <TabContent />}
            style={{ zIndex: 0 }}
          >
            <TabPane tab="PCR" key="0"></TabPane>
            <TabPane tab="TBB" key="1"></TabPane>
            <TabPane tab="TBR" key="2"></TabPane>
            <TabPane tab="LCV" key="3"></TabPane>
            <TabPane tab="SCV" key="4"></TabPane>
            <TabPane tab="2 / 3 Wheeler" key="5"></TabPane>
            <TabPane tab="Farm" key="6"></TabPane>
          </Tabs>
        </Col>
        <Col className='text-align-right button-link-container'>
          {isTradeSchemeButtonEnable && <span className="trade-scheme-report-link" onClick={this.openTradeSchemeDealerModal}>
            <img src="/images/trade-scheme-report-icon.svg" alt="" />&nbsp;
            Trade Scheme Report</span>}
          {false && <button className="current-offer-button">Current Offers</button>}
        </Col>
      </Row>
      <Row>
        <Col sm="5">
          <Card>
            <CardBody className="chart-card-body">
              <Row>
                <Col sm="6">
                  <div className="title-text" style={{ paddingRight: '0px' }}>Last 12 Months Sales Trend</div>
                  <div className="count-offtake-title">{allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.yearLabel}</div>
                </Col>
                <Col sm="6">
                  <Row>
                    <Col>
                      <div className="count-offtake-title">Yearly APM</div>
                      {/* <div className="quarter-label">({allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.yearLabel})</div> */}
                      <div className="title-text">{allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.yearlyOfftake}</div>
                    </Col>
                    <Col className="quarter-apm-col">
                      <div className="count-offtake-title">Quarter APM</div>
                      <div>
                        <span className="title-text">{allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.quarterlyOfftake}</span>
                        <span className="quarter-label">{" "}({allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.quarterLabel})</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div id="chart">
                {allMonthOfftakeTrendChartData &&
                  <Chart
                    width="100%"
                    height="200px"
                    options={twelveMonthSalesTrendReport.options} series={twelveMonthSalesTrendReport.series} type="line"
                  />}
              </div>
              {allMonthOfftakeTrendChartData && allMonthOfftakeTrendChartData.isTargetAvailable && <div className="target-indicator-container">
                <span className="target-indicator">___</span>
                <span className="target-indicator-text">{allMonthOfftakeTrendChartData.target} - Target as per agreement</span>
              </div>}
            </CardBody>
          </Card>
        </Col>
        <Col sm="7">
          <Card>
            <CardBody className="chart-card-padding">
              <div className="chart-main-title title-text">Top 10 SKU ({displayLabels && displayLabels.year})</div>
              <Row className="chart-row">
                {areaTrendChartData && <Col
                  sm={['pcr', 'two_three_wheelers'].includes(category) ? "4" : "6"}
                  className="chart-col-padding">
                  {this.generateChartToDisplay(areaTrendChartData, areaTrendChart, 'area')}
                </Col>}
                {offtakeTrendChartData && <Col sm={['pcr', 'two_three_wheelers'].includes(category) ? "4" : "6"} className="chart-col-padding">
                  {this.generateChartToDisplay(offtakeTrendChartData, offtakeTrendChart, 'offtake')}
                </Col>}
                {warrantyTrendChartData && ['pcr', 'two_three_wheelers'].includes(category) && <Col sm="4" className="chart-col-padding">
                  {this.generateChartToDisplay(warrantyTrendChartData, warrantyTrendChart, 'warranty')}
                </Col>}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  }
}
export default PrimaryPointsReportDealer;
