import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
// import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import { roundValue, formatNumber } from "../../utils/utils";
class HODashboardCategoryWiseCard extends Component {
  getCategoryWiseReport = (o) => {
    window.scroll(0, 0);
    this.props.getHeadOfficeReport(o);
  };

  arraymove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  render() {
    const {
      offtake: offtakeFromDb,
      totalOfftake,
      // findFirstQuarterMonthsToCurrentMonths,
      // currentQuarterMonths,
      // currentMonth,
      label,
    } = this.props;
    const { year, quarter, month } = totalOfftake;

    let offtake = offtakeFromDb;
    if (
      offtake &&
      offtake.length > 0 &&
      offtake[0].type &&
      offtake[0].type === "zone"
    ) {
      const arr = ["nz", "sz1", "sz2", "ez", "wz", "cz"];
      const offtakeArray = [];
      let obj = {};
      for (let i = 0; i < offtake.length; i++) {
        obj[offtake[i].refName] = offtake[i];
      }
      for (let i = 0; i < arr.length; i++) {
        if (obj.hasOwnProperty(arr[i])) {
          offtakeArray.push(obj[arr[i]]);
        }
      }
      offtake = offtakeArray;
    }

    // const prepareRows = offtake.map(o =>
    //   <Row className="dashboard-page-card-title-small margin-top-20 margin-bottom-10">
    //     <Col sm="2" onClick={o.dealerName ? this.props.getHeadOfficeReport.bind(this, o) : null}>
    //       {o.category ? o.category : o.dealerName}
    //     </Col>
    //     <Col sm="3">
    //       <div>{roundValue(o.year)}</div>
    //     </Col>
    //     <Col sm="4">
    //       <div>{roundValue(o.quarter)}</div>
    //     </Col>
    //     <Col sm="3">
    //       <div>{roundValue(o.month)}</div>
    //     </Col>
    //   </Row>)
    return (
      <Fragment>
        {/* {this.props.isLoading && <CustomSpinner></CustomSpinner>} */}

        <Row>
          <Col className="sub-title">OFFTAKE</Col>
        </Row>
        <Card className="heigh-95-percentage">
          <CardBody style={{ padding: "0px" }}>
            {/* <Row className="card-custom-header">
            <Col sm="2">
            </Col>
            <Col sm="3">
              <div className="dashboard-page-card-title-big">Year Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr-Sept'19</div>
              <div className="count-small margin-top-5">{year}</div>
            </Col>
            <Col sm="4">
              <div className="dashboard-page-card-title-big">Quarter Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr-Sept'19</div>
              <div className="count-small margin-top-5">{quarter}</div>
            </Col>
            <Col sm="3">
              <div className="dashboard-page-card-title-big">Month Till Date</div>
              <div className="dashboard-page-card-title-small margin-top-5">Apr'19</div>
              <div className="count-small margin-top-5">{month}</div>
            </Col>
          </Row>
          {prepareRows} */}
            <div
              className="d-flex-column"
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Table className="table-responsive">
                <thead
                  style={{
                    backgroundImage:
                      "linear-gradient(101deg, #f8e184, #f7d174)",
                  }}
                >
                  <tr className="border-0">
                    <th className="border-0" style={{ width: "100px" }}></th>
                    <th className="text-align-center border-0 width-172px">
                      <span className="dashboard-page-card-title-big">
                        Year Till Date
                      </span>
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.year}
                        {/* {findFirstQuarterMonthsToCurrentMonths} */}
                      </span>
                      <br />
                      <span className="count-small">{formatNumber(year)}</span>
                    </th>
                    <th className="text-align-center border-0 width-172px">
                      <span className="dashboard-page-card-title-big">
                        Quarter Till Date
                      </span>
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.quarter}
                        {/* {currentQuarterMonths} */}
                      </span>
                      <br />
                      <span className="count-small">
                        {formatNumber(quarter)}
                      </span>
                    </th>
                    <th className="text-align-center border-0 width-172px">
                      <span className="dashboard-page-card-title-big">
                        Month Till Date
                      </span>
                      <br />
                      <span className="dashboard-page-card-title-small">
                        {label.month}
                        {/* {currentMonth} */}
                      </span>
                      <br />
                      <span className="count-small">{formatNumber(month)}</span>
                    </th>
                  </tr>
                  <tr className="border-0">
                    <th colspan="2" className="border-0"></th>
                    <th
                      colspan="2"
                      className="Note-This-figures-s border-0 text-align-center"
                    >
                      &nbsp; Note: This figures shows the total offtake for
                      tenure.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {offtake.map((o) => (
                    <tr className="border-0">
                      <td
                        className={
                          o.dealerName
                            ? "hyper-link border-top-0 title-small text-align-left cursor-pointer title-case pr-3"
                            : "border-top-0 title-small text-align-left cursor-pointer title-case pr-3"
                        }
                        onClick={
                          o.dealerName
                            ? this.getCategoryWiseReport.bind(this, o, false)
                            : null
                        }
                      >
                        {" "}
                        {o.category ? o.category : o.dealerName}
                      </td>
                      <td className="border-top-0 title-small text-align-right padding-right-47px">
                        {formatNumber(roundValue(o.year))}
                      </td>
                      <td className="border-top-0 title-small text-align-right padding-right-47px">
                        {formatNumber(roundValue(o.quarter))}
                      </td>
                      <td className="border-top-0 title-small text-align-right padding-right-47px">
                        {formatNumber(roundValue(o.month))}
                      </td>
                    </tr>
                  ))}

                  {/* <td></td> */}
                </tbody>
              </Table>
              <div className="Note-This-figures-s text-align-right">
                Note: Offtake entry shows the average per month data.
              </div>
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default HODashboardCategoryWiseCard;
