import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
} from "reactstrap";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import ReactTooltip from "react-tooltip";
import { Input, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import RetailMarketingSystemService from "../../../services/RetailMarketingSystemService";
import Pagination from "../../Pagination/Pagination";
import "./RetailMarketingList.css";
import { Redirect } from "react-router-dom";
import lodash from "lodash";
import {
  getFilterAppliedStatus,
  getFilterData,
  getRecceStatus,
  getRetailMarketingStoreInformationState,
} from "../selector";
import {
  resetStoreDetailControls,
  setFilterAppliedStatus,
  setRecceStatus,
  setRecceStatusCount,
  setRecceStatusCountFilter,
  setVendorScreen,
} from "../../../actions/RetailManagementSystemAction";
import Sidebar from "react-sidebar";
import RetailMarketingSignageInformation from "../RetailMarketingSignageInformation/RetailMarketingSignageInformation";

function RetailMarketingList({
  resetStoreDetailControlsAction,
  storeDetailControls,
  role = "",
  openRecceFilter,
  status_data = "all",
  recceStatus,
  setRecceStatusAction,
  setRecceStatusCountAction,
  setRecceStatusCountFilterAction,
  setVendorScreenAction,
  filterData,
  filterAppliedStatus,
  setFilterAppliedStatusAction,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [retailListData, setRetailListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRetailCounts, setTotalRetailCounts] = useState(0);
  const [redirectToAddEditRecce, setRedirectToAddEditRecce] = useState(false);
  const [redirectToViewRecce, setRedirectToViewRecce] = useState(false);
  const [
    redirectToCompleteInstallation,
    setRedirectToCompleteInstallation,
  ] = useState(false);
  const [retailDetail, setRetailDetail] = useState({});
  const [hoInstallation, setHoInstallation] = useState(false);
  const [
    vendorInstallationModification,
    setVendorInstallationModification,
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const delayedQuery = useCallback(
    lodash.debounce((q) => searchData(q), 500),
    []
  );
  const [
    isFirsTimeLoadPageDataNotFound,
    setIsFirsTimeLoadPageDataNotFound,
  ] = useState(true);
  const [vendorViewOnly, setVendorViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isInstallationApproved, setIsInstallationApproved] = useState(false);
  const [ho, setHo] = useState(false);
  const [vendor, setVendor] = useState(false);
  // const [vendorRemark, setVendorRemark] = useState("");
  const [openSignageSidebar, setOpenSignageSidebar] = useState(false);

  const serearchQuery = localStorage.getItem("serearchQuery");
  const pageNumber = localStorage.getItem("pageNumber");
  // const [title,setTitle]  = useState();
  // const toggle = (row = "") => {
  //   setVendorRemark(row.vendor_comment);
  // };
  // Handle Search Query

  const handleSearchChange = (e) => {
    setPage(1);
    localStorage.setItem("serearchQuery", e.target.value);
    localStorage.setItem("pageNumber", 1);
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  useEffect(() => {
    resetStoreDetailControlsAction(storeDetailControls);
  }, []);
  useEffect(() => {
    getAllRetail();
  }, [page * pageSize, recceStatus, filterData]);

  useEffect(() => {
    getAllRetail();
    setPageSize(10);
    setPage(Number(pageNumber) ? Number(pageNumber) : 1);
  }, [searchQuery, recceStatus, serearchQuery]);
  const searchData = (search) => {
    setSearchQuery(search);
  };

  const onSetSidebarOpen = () => {
    document.body.style.overflow = "hidden";
    setOpenSignageSidebar(true);
  };

  const onCloseSidebar = () => {
    document.body.style.overflow = "auto";
    setOpenSignageSidebar(false);
    resetStoreDetailControlsAction(storeDetailControls);
    localStorage.setItem("serearchQuery", "");
  };

  // Get All retail Detail API.

  const getAllRetail = () => {
    setIsLoading(true);
    let reqData = {
      page: pageNumber ? pageNumber : page,
      pageSize,
      q: serearchQuery ? serearchQuery : searchQuery,
      // status: filterData.status.value ? filterData.status.value : recceStatus,
      // zone_id: filterData.zone_id.value,
      // region_id: filterData.region_id.value,
      // area_id: filterData.area_id.value,
      // state_id: filterData.state_id.value,
      // city_id: filterData.city_id.value,
      // created_at_start_date: created_at_start_date,
      // created_at_end_date: created_at_end_date,
      // recce_start_date: recce_start_date,
      // recce_end_date: recce_end_date,
      // installation_start_date: installation_start_date,
      // installation_end_date: installation_end_date,
      // club_name: filterData.club_name.value,
    };
    RetailMarketingSystemService.getMiniRetailDetail(reqData)
      .then((data) => {
        let { data: list } = data.data;
        // setRecceStatusCountFilterAction(list.status);
        if (list.total_count === null) {
          setRecceStatusCountAction(0);
        } else {
          setRecceStatusCountAction(list.total_count);
        }

        if (
          list.elements &&
          Array.isArray(list.elements) &&
          list.elements.length > 0
        ) {
          setRetailListData(list.elements);
          if (isFirsTimeLoadPageDataNotFound) {
            setIsFirsTimeLoadPageDataNotFound(false);
          }
        } else {
          setRetailListData([]);
        }
        const totalCountsTemp = data.data.data.total_count;
        const totalPagesTemp = Math.ceil(totalCountsTemp / pageSize);
        setTotalRetailCounts(totalCountsTemp);
        setTotalPages(totalPagesTemp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  // Set Page Number in Pagination
  const handlePageChange = (page) => {
    localStorage.setItem("pageNumber", Number(page));
    setPage(page);
  };
  // Set Page Size in Pagination
  const handleInputChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  // Edit Recce
  const editRecce = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Edit Recce Form");
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
    }
    setRetailDetail(row);
    setRedirectToAddEditRecce(true);
  };

  // Head Office Screen handle status of Recce and redirect to respective state.
  const handleRecceStatus = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Recce Approval");
      localStorage.setItem("screenStatus", "recce_pending");
      setHoInstallation(false);
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
      localStorage.setItem("screenStatus", "recce_modified");
    }
    if (row.status === "recce_rejected") {
      localStorage.setItem("screenTitle", "Recce Rejected");
      localStorage.setItem("screenStatus", "recce_rejected");
    }
    if (row.status === "installation_pending") {
      localStorage.setItem("screenTitle", "Installation Pending");
      localStorage.setItem("screenStatus", "installation_pending");
      setHoInstallation(false);
      setVendorInstallationModification(true);
    }
    if (row.status === "installation_completed") {
      localStorage.setItem("screenTitle", "Installation Approval");
      localStorage.setItem("screenStatus", "installation_completed");
      setHoInstallation(true);
    }
    if (row.status === "installation_modified") {
      localStorage.setItem("screenTitle", "Installation Modification Required");
      localStorage.setItem("screenStatus", "installation_modified");
      setHoInstallation(true);
      setVendorInstallationModification(true);
    }
    if (row.status === "instllation_approved") {
      localStorage.setItem("screenTitle", "Installation Approved");
      localStorage.setItem("screenStatus", "instllation_approved");
      setVendorInstallationModification(true);
      setHoInstallation(true);
    }
    setRetailDetail(row);
    setRedirectToViewRecce(true);
    setVendorViewOnly(false);
    setHo(true);
    setVendorScreenAction(true);
    localStorage.setItem("screenState", true);
  };
  const editRecceInstallation = (row) => {
    localStorage.setItem("screenTitle", "Edit Installation Form");
    localStorage.setItem("screenStatus", "installation_modified");
    if (row.status === "installation_modified") {
      localStorage.setItem(
        "screenTitle",
        "Edit Installation Form (Modification Required)"
      );
    }
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(true);
    setIsEdit(true);
    setHoInstallation(false);
    setVendor(true);
    localStorage.setItem("screenState", false);
  };
  const viewRecceInstallation = (row) => {
    localStorage.setItem("screenTitle", "Installation Approved");
    localStorage.setItem("screenStatus", "installation_completed");
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(true);
    setIsEdit(false);
    setIsInstallationApproved(true);
    setHoInstallation(true);
    setVendor(true);
    setVendorScreenAction(true);
    localStorage.setItem("screenState", false);
  };
  const completeRecceInstallation = (row) => {
    if (row.status === "installation_pending") {
      localStorage.setItem("screenTitle", "Installation Pending");
      localStorage.setItem("screenStatus", "installation_pending");
    }
    setVendor(true);
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(false);
    localStorage.setItem("screenState", false);
  };
  const viewRecce = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Recce Approval");
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
    }
    if (row.status === "recce_rejected") {
      localStorage.setItem("screenTitle", "Recce Rejected");
    }
    if (row.status === "installation_pending") {
      localStorage.setItem("screenStatus", "installation_pending");
      localStorage.setItem("screenTitle", "Installation Pending");
    }
    setVendor(true);
    setRetailDetail(row);
    setRedirectToViewRecce(true);
    setVendorViewOnly(true);
    localStorage.setItem("screenState", false);
  };

  // Listing page set Icon according to Action.
  const getAction = (row) => {
    let prepareRow = (
      <div className="action">
        <img
          className="cursor-pointer"
          style={{ height: "13px" }}
          onClick={() => viewRecce(row)}
          src="images/eye.png"
          alt=''
        />
        &nbsp;&nbsp;
      </div>
    );
    if (row.status === "recce_pending") {
      //
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            alt=''
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecce(row)}
          />
        </div>
      );
    }
    if (row.status === "installation_completed") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            alt=''
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecceInstallation(row)}
          />
          &nbsp;&nbsp;
        </div>
      );
    }
    if (row.status === "recce_modified") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            alt=''
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecce(row)}
          />
          &nbsp;&nbsp;
          <img
            src="images/group-29.png"
            alt=''
            className="Group-3 "
            style={{ cursor: "pointer" }}
            data-tip={row.modification_comments}
            data-for={"tooltip-tyre-replacement"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "installation_pending") {
      prepareRow = (
        <div className="action">
          <img
            className="cursor-pointer"
            style={{ height: "13px" }}
            onClick={() => viewRecce(row)}
            src="images/eye.png"
            alt=''
          />{" "}
          &nbsp;&nbsp;
          <img
            src="/images/installation_pending.png"
            alt=''
            onClick={() => completeRecceInstallation(row)}
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            data-tip="Click to finish the Installation"
            data-for={"tooltip-tyre-replacementtt"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacementtt"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
          &nbsp;&nbsp;
          {row.modification_comments && (
            <img
              src="images/group-29.png"
              alt=''
              className="Group-3 "
              style={{ cursor: "pointer" }}
              data-tip={row.modification_comments}
              data-for={"tooltip-tyre-replacement"}
            />
          )}
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "installation_modified") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/installation_pending.png"
            alt=''
            onClick={() => editRecceInstallation(row)}
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            data-tip="Click to finish the Installation"
            data-for={"tooltip-tyre-replacementt"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacementt"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
          &nbsp;&nbsp;
          <img
            src="images/group-29.png"
            alt=''
            className="Group-3 "
            style={{ cursor: "pointer" }}
            data-tip={row.modification_comments}
            data-for={"tooltip-tyre-replacement"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "instllation_approved") {
      prepareRow = (
        <div className="action">
          <img
            className="cursor-pointer"
            style={{ height: "13px" }}
            onClick={() => viewRecceInstallation(row)}
            src="images/eye.png"
            alt=''
          />{" "}
          &nbsp;&nbsp;
          {row.modification_comments && (
            <img
              src="images/group-29.png"
              alt=''
              className="Group-3 "
              style={{ cursor: "pointer" }}
              data-tip={row.modification_comments}
              data-for={"tooltip-tyre-replacement"}
            />
          )}
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    return prepareRow;
  };
  return (
    <div style={{ position: "relative" }}>
      {redirectToAddEditRecce && (
        <Redirect
          to={{
            pathname: "/retail-marketing-recce",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            ho,
            vendor,
          }}
        />
      )}
      {vendorInstallationModification && (
        <Redirect
          to={{
            pathname: "/retail-marketing-recce",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            vendorInstallationModification,
            ho,
            vendor,
          }}
        />
      )}
      {redirectToViewRecce && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.id}`,
            vendorViewOnly,
            ho,
            vendor,
            vendorInstallationModification,
            hoInstallation,
          }}
        />
      )}
      {redirectToCompleteInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            isInstallationApproved,
            ho,
            vendor,
          }}
        />
      )}
      {hoInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            ho,
            vendor,
          }}
        />
      )}
      {isLoading && <CustomSpinner />}
      <Card id="RetailMarketingStoreInformation">
        <CardBody>
          {!isFirsTimeLoadPageDataNotFound && (
            <div className="d-inline-block">
              <div
                style={{ width: "301px" }}
                className="d-flex-row search-input"
              >
                <Input
                  placeholder="Search by SAP ID or Business name"
                  className="login-input"
                  type="text"
                  name="search"
                  onChange={handleSearchChange}
                  value={serearchQuery ? serearchQuery : userQuery}
                ></Input>
                <img
                  src="images/search.svg"
                  alt=''
                  className="icon-width"
                  style={{ marginLeft: "-25px" }}
                />
              </div>
            </div>
          )}

          <div style={{ display: "contents" }}>
            <Button
              className="mb-2 mr-1 btn-pill action-button action-ob-button font-weight-bold"
              color="warning"
              type="submit"
              style={{
                float: "right",
                width: "160px",
                marginTop: "0px",
              }}
              onClick={onSetSidebarOpen}
            >
              Add New Signage
            </Button>
          </div>

          {openSignageSidebar && (
            <Sidebar
              sidebar={
                <>
                  <RetailMarketingSignageInformation
                    onCloseSidebar={onCloseSidebar}
                    getAllRetail={getAllRetail}
                  />
                </>
              }
              open={openSignageSidebar}
              sidebarClassName="customer-management-filter"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: "94%",
                  width: "500px",
                  top: "60px",
                  overflowY: "auto !important",
                },
              }}
            />
          )}

          <table
            className="mb-0 table-responsive tbl-f6f6f6"
            bordered
            style={{ marginTop: "24px" }}
          >
            <thead>
              <tr className="tr-f6f6f">
                <th style={{ width: "100px" }}>SAP ID</th>
                <th style={{ width: "100px" }}>Business Name</th>
                {role !== "HO" && (
                  <th style={{ width: "140px" }}>Installation Period</th>
                )}
                <th style={{ width: "155px" }}>Recce Photos</th>
                <th style={{ width: "155px" }}>Installation Photos</th>
                <th style={{ width: "155px" }}>PIN</th>
                <th style={{ width: "155px" }}>City</th>
                <th style={{ width: "155px" }}>State</th>
                <th style={{ width: "155px" }}>Zone</th>
                <th style={{ width: "155px" }}>Region</th>
                <th style={{ width: "155px" }}>Territory</th>

                {/* <th>Sap Code</th>
                <th>Dealer Name</th> */}
                {/* {(role !== "HO" || recceStatus === "all") && <th>Status</th>} */}
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {retailListData[0] !== null &&
                retailListData.map((row, i) => {
                  return (
                    <tr>
                      <td>{row.sap_id}</td>
                      <td>{row.business_name || "-"}</td>
                      {role !== "HO" && (
                        <td>
                          {row.installation_month && row.installation_year
                            ? row.installation_month +
                            "/" +
                            row.installation_year
                            : "-"}
                        </td>
                      )}
                      <td>
                        <div className="d-flex">
                          {row.recce_image_1.length ? (
                            <img
                              src={row.recce_image_1}
                              alt="img1"
                              className="thumnail-img"
                            />
                          ) : null}

                          {row.recce_image_2.length ? (
                            <img
                              src={row.recce_image_2}
                              alt="img1"
                              className="thumnail-img"
                            />
                          ) : null}
                          {row.recce_image_3.length ? (
                            <img
                              src={row.recce_image_3}
                              alt="img1"
                              className="thumnail-img"
                            />
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="d-flex">
                            {row.recce_installation_image_1.length ? (
                              <img
                                src={row.recce_installation_image_1}
                                alt="img1"
                                className="thumnail-img"
                              />
                            ) : null}

                            {row.recce_installation_image_2.length ? (
                              <img
                                src={row.recce_installation_image_2}
                                alt="img1"
                                className="thumnail-img"
                              />
                            ) : null}

                            {row.recce_installation_image_3.length ? (
                              <img
                                src={row.recce_installation_image_3}
                                alt="img1"
                                className="thumnail-img"
                              />
                            ) : null}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div>{row.pin_code || "-"}</div>
                      </td>
                      <td>{row.city || "-"}</td>
                      <td>{row.state || "-"}</td>
                      <td>{row.zone || "-"}</td>
                      <td>{row.region || "-"}</td>
                      <td>{row.area || "-"}</td>

                      {/* <td>
                      {role === "HO" ? (
                        <Row>
                          <Col sm="2">
                            <img
                              src="images/group-28.png"
                               alt=''
                              className="Group-3 "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleRecceStatus(row);
                              }}
                            />
                          </Col>
                          <Col sm="2">
                            {row.vendor_comment &&
                              !row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={row.vendor_comment}
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            {!row.vendor_comment &&
                              row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={row.vendor_comment_installation}
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            {row.vendor_comment &&
                              row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={
                                    (row.vendor_comment,
                                    row.vendor_comment_installation)
                                  }
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            <ReactTooltip
                              id={"tooltip-tyre-replacement"}
                              className="customer-list-tooltip"
                              effect="solid"
                            ></ReactTooltip>
                          </Col>
                        </Row>
                      ) : (
                        getAction(row)
                      )}
                    </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {retailListData.length === 0 && (
            <div className="text-align-center card-point-header mt-2">
              Currently, there are not any recce.
            </div>
          )}
          {totalPages > 0 && (
            <div
              className="d-flex-row"
              style={{ marginTop: "10px", justifyContent: "flex-end" }}
            >
              <span className="Rows-per-page">Rows per page:</span>
              <select
                className="form-control rows-per-page-select"
                name="pageSize"
                value={pageSize}
                onChange={(e) => handleInputChange(e)}
                style={{ width: "65px" }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              &nbsp;&nbsp;
              <div style={{ marginTop: "5px" }}>
                <Pagination
                  margin={2}
                  page={page}
                  count={totalPages}
                  pageSize={pageSize}
                  totalRecords={totalRetailCounts}
                  onPageChange={handlePageChange}
                ></Pagination>
              </div>
            </div>
          )}
          {retailListData.length === 0 && (
            <div className="text-align-center card-point-header"></div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

RetailMarketingList.propTypes = {
  resetStoreDetailControlsAction: PropTypes.func.isRequired,
  setRecceStatusAction: PropTypes.func.isRequired,
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  role: PropTypes.string,
  openRecceFilter: PropTypes.func,
  status_data: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
    recceStatus: getRecceStatus(state),
    filterData: getFilterData(state),
    filterAppliedStatus: getFilterAppliedStatus(state),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStoreDetailControlsAction: resetStoreDetailControls,
      setRecceStatusAction: setRecceStatus,
      setRecceStatusCountAction: setRecceStatusCount,
      setRecceStatusCountFilterAction: setRecceStatusCountFilter,
      setVendorScreenAction: setVendorScreen,
      setFilterAppliedStatusAction: setFilterAppliedStatus,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarketingList);
