import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Select, { components } from "react-select";
import Ionicon from "react-ionicons";
import DatePicker from "react-datepicker";
import { downlodFile } from "../../utils/utils";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import { messages } from "../../constants/constant.message";
import MarketingCouponService from '../../services/MarketingCouponService';
import UserService from '../../services/UserService';
import { Typeahead } from 'react-bootstrap-typeahead';
import PosterView from "../../components/PosterPreview/PosterView";
import swal from 'sweetalert';

const { ERROR_MESSAGE } = messages.COMMON;
class GenerateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      controls: {
        uuid: '',
        program_name: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        offer_detail: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        type: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        purpose: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        coupon_quantity: {
          value: 1,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        discount: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        multiReddemLimit: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        tyre_per_coupon: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        one_tyre_price: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false,
          disabled: false
        },
        zone: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        region: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        area: {
          value: [],
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        start_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        end_date: {
          value: '',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        selectedFile: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null
        },
        checkAll: true,
        checkSW: false,
        checkXW: false,
        checkTW: false,
        checkPTP: false,
        checkMBO: false,
        checkHY: false,
        checkClassification: false,
        paid_by_jk: {
          value: true,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        redeemable: {
          value: true,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        redeemableMulti: {
          value: true,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        filter: {
          value: 'all',
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        content_url: {
          value: null,
          valid: null,
          touched: false,
          nullValue: null,
          invalidEmail: null,
          required: true,
          showErrorMsg: false,
          autoFocus: false
        },
        classification: []
      },
      discount_disable: 0,
      multiRedeem_disable: 0,
      image_url: null,
      customerVehicleData: [],
      programData: [],
      type: [],
      purpose: [],
      allZone: false,
      allRegion: false,
      allArea: false,
      zone: [],
      region: [],
      area: [],
      serachValueZone: '',
      serachValueRegion: '',
      serachValueArea: '',
      fileName: '',
      action: 'new',
      classificationShowErrorMsg: false,
      disabled_button: false,
      dealer: null,
      click_on_url: false,
      multiredeem: true,
      previousLimit: '',
      previousLimitError: false
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleRadioChangeReportFormat = this.handleRadioChangeReportFormat.bind(this);
    if (props.getDuplicateCouponData && Object.keys(props.getDuplicateCouponData).length > 0) {
      this.state.action = props.getDuplicateCouponData.action;
      if (props.getDuplicateCouponData.action === 'edit') {
        this.state.controls.uuid = props.getDuplicateCouponData.uuid;
        this.state.controls.program_name.disabled = true;
        this.state.controls.type.disabled = true;
        this.state.controls.purpose.disabled = true;
        this.state.controls.coupon_quantity.disabled = true;
        this.state.controls.discount.disabled = true;
        // this.state.controls.multiReddemLimit.disabled = true;
        this.state.controls.tyre_per_coupon.disabled = true;
        this.state.controls.end_date.value = new Date(props.getDuplicateCouponData.coupon_validity_end_date);
        this.state.controls.start_date.value = new Date(props.getDuplicateCouponData.coupon_validity_start_date);
      }
      this.state.controls.program_name.value = props.getDuplicateCouponData.program_name;
      this.state.controls.offer_detail.value = props.getDuplicateCouponData.offer_details;
      this.state.controls.type.value = props.getDuplicateCouponData.type;
      this.state.controls.purpose.value = props.getDuplicateCouponData.purpose;
      this.state.controls.coupon_quantity.value = props.getDuplicateCouponData.coupon_quantity;
      this.state.controls.discount.value = props.getDuplicateCouponData.discount_per_coupon;
      this.state.controls.multiReddemLimit.value = props.getDuplicateCouponData.repeat_count;
      this.state.previousLimit = props.getDuplicateCouponData.repeat_count;
      this.state.controls.tyre_per_coupon.value = props.getDuplicateCouponData.maximum_tyres_per_coupon;
      this.state.controls.one_tyre_price.value = props.getDuplicateCouponData.one_tyre_price;
      this.state.controls.content_url.value = props.getDuplicateCouponData.content_url;
      this.state.controls.zone.value = props.getDuplicateCouponData.zone;
      this.state.controls.region.value = props.getDuplicateCouponData.region;
      this.state.controls.area.value = props.getDuplicateCouponData.area;
      this.state.controls.paid_by_jk.value = props.getDuplicateCouponData.paid_by_jk;
      this.state.controls.redeemable.value = props.getDuplicateCouponData.redeemable;
      this.state.controls.redeemableMulti.value = props.getDuplicateCouponData.is_multiple_repeat;
      this.state.controls.filter.value = props.getDuplicateCouponData.filter;
      this.state.image_url = props.getDuplicateCouponData.image_url;

      if (!props.getDuplicateCouponData.is_multiple_repeat) {
        this.state.controls.multiReddemLimit.disabled = true;
      }
      if ((props.getDuplicateCouponData.classification).length === 0) {
        this.state.controls.checkAll = true;
      } else {
        this.state.controls.checkClassification = true;

        this.state.controls.checkAll = false;
        if ((props.getDuplicateCouponData.classification).includes('sw')) {
          this.state.controls.classification.push('sw');
          this.state.controls.checkSW = true;
        }
        if ((props.getDuplicateCouponData.classification).includes('xw')) {
          this.state.controls.classification.push('xw');
          this.state.controls.checkXW = true;
        }
        if ((props.getDuplicateCouponData.classification).includes('tw')) {
          this.state.controls.classification.push('tw');
          this.state.controls.checkTW = true;
        }
        if ((props.getDuplicateCouponData.classification).includes('ptp')) {
          this.state.controls.classification.push('ptp');
          this.state.controls.checkPTP = true;
        }
        if ((props.getDuplicateCouponData.classification).includes('mbo')) {
          this.state.controls.classification.push('mbo');
          this.state.controls.checkMBO = true;
        }
        if ((props.getDuplicateCouponData.classification).includes('hy')) {
          this.state.controls.classification.push('hy');
          this.state.controls.checkHY = true;
        }
      }

      if ((props.getDuplicateCouponData.zone).length > 0) {
        this.getRegion(true, props.getDuplicateCouponData.zone);
      }
      if ((props.getDuplicateCouponData.region).length > 0) {
        this.getArea(true, props.getDuplicateCouponData.region);
      }

    } else {
    }
  }

  handleRadioChange(e) {
    let { controls } = this.state;
    controls.paid_by_jk.value = e;
    this.setState({ controls });
  };

  handleRadioChangeReedeemable(e) {
    let { controls } = this.state;
    let discount_disable;
    controls.redeemable.value = e;

    if (!e) {
      controls.discount.value = 0;
      discount_disable = true;
    } else {
      discount_disable = false;
    }

    this.setState({ controls, discount_disable });
  };

  handleRadioChangeMultiReedeemable(e) {
    let { controls } = this.state;
    let multiRedeem_disable;
    controls.redeemableMulti.value = e;
    if (!e) {
      controls.multiReddemLimit.value = 0;
      multiRedeem_disable = true;
      controls.multiReddemLimit.showErrorMsg = false;
    } else {
      controls.multiReddemLimit.disabled = false;
      multiRedeem_disable = false;
    }
    controls.coupon_quantity.value = '';
    this.setState({ controls, multiRedeem_disable });
  };

  handleRadioChangefilter(e) {
    let { controls } = this.state;
    controls.filter.value = e;

    if (e === 'customize') {
      let { controls } = this.state;
      controls.zone.value = [];
      controls.region.value = [];
      controls.area.value = [];
      controls.checkSW = false;
      controls.checkXW = false;
      controls.checkTW = false;
      controls.checkPTP = false;
      controls.checkMBO = false;
      controls.classification = [];
    }

    this.setState({ controls });

  };

  handleRadioChangeReportFormat(e) {
    let { controls } = this.state;
    controls.format.value = e;
    this.setState({ controls });
  };

  componentDidMount() {
    this.getProgram();
    this.getTypeAndData();
    this.getTypeFromCampaign();
  };

  getProgram = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getProgram({ search_query: '' })
      .then(res => {

        let { programData } = this.state;
        if (res.data.data && res.data.data && res.data.data.length > 0) {
          programData = res.data.data;
        } else {
          programData = [];
        }
        this.setState({
          programData,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };

  getTypeAndData = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeAndData()
      .then(res => {
        let { purpose, zone, dealer } = this.state;

        if (res.data.data) {
          purpose = res.data.data.purpose;
          dealer = res.data.data.dealer;
          zone = res.data.data.zone;
          zone.unshift({ label: "All", value: null });
        } else {
          purpose = [];
          zone = [];
        }

        this.setState({
          purpose,
          zone,
          isLoading: false,
          dealer
        })

        /* if(this.state.action === 'new'){
            controls['zone'].value = this.state.zone;
            this.setState({
                allZone : true,
                controls
            });
            this.getRegion(false, controls['zone'].value);
        } */

        this.allZone(false, 'zone', this.state.controls.zone.value);

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  getTypeFromCampaign = () => {
    this.setState({ isLoading: true });
    MarketingCouponService.getTypeFromCampaign()
      .then(res => {
        let { type } = this.state;

        if (res.data.data) {
          type = res.data.data;
        } else {
          type = [];
        }

        this.setState({
          type,
          isLoading: false
        })

      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  }

  handleInputChange = e => {
    let controlName = e.target.name;
    let controlValue = e.target.value;
    if (controlName === 'discount' || controlName === 'multiReddemLimit' || controlName === 'tyre_per_coupon' || controlName === 'coupon_quantity') {
      controlValue = controlValue.replace(/[^0-9]/g, '');
      if (controlValue < this.state.previousLimit && this.state.action === 'edit') {
        this.state.previousLimitError = true;
      }
      else {
        this.state.previousLimitError = false;
      }
    }
    // if(controls)
    let { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;

    if (e.target.name === 'program_name') {
      // controls[controlName].value = e.target.value.program_name;
    }


    if (controls[controlName].touched === true && (controls[controlName].value === '' || controls[controlName].value === null)) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }

    if (controlName === 'selectedFile' && e.target.value) {
      this.setState({ fileName: e.target.value.name });
    }

    this.setState({ controls });

    if (controlName === "zone") {
      this.allZone(true, controlName, controlValue);
    }

    if (controlName === 'region') {
      this.allRegion(true, controlName, controlValue);
    }

    if (controlName === 'area') {
      this.allArea(true, controlName, controlValue);
    }

  };

  handleInputChangeFile = e => {
    let { controls } = this.state;

    this.setState({ isLoading: true });

    const file = e.target.value;
    const obj = {
      file
    };
    this.setState({ isImageUpdating: true });
    UserService.uploadImage(obj, 'coupon_image')
      .then(data => {

        controls['content_url'].value = data.data.data.imageUrl;

        this.setState({
          controls,
          image_url: data.data.data.imageUrl ? (data.data.data.imageUrl.match(/\.(jpeg|jpg|gif|png)$/) !== null) : null,
          isLoading: false
        }, () => {
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  };

  deleteImage = () => {
    let { controls } = this.state;
    controls['content_url'].value = null;
    this.setState({
      controls,
      image_url: null
    }, () => {
    });
  }

  openViewModal = () => {
    let data = {
      posterDeatil: this.state.controls.content_url.value,
      reference_poster_url: this.state.controls.content_url.value,
      name: 'Image'
    };
    this.refs.openViewModal.toggle(data);
  }

  handleInputChangeUrl = () => {
    let { controls } = this.state;
    controls['content_url'].value = null;
    this.setState({
      controls,
      image_url: false,
      click_on_url: true
    }, () => {
    });
  }

  handleChangeUrl = () => {
    let { controls } = this.state;
    controls['content_url'].value = null;
    this.setState({
      controls,
      image_url: null,
      click_on_url: true
    }, () => {
    });
  }

  allZone = (find, controlName, controlValue) => {
    let { controls, allZone } = this.state;

    if (controlValue.length > 0) {
      let index_all = controls[controlName].value.findIndex(x => x.label === "All");

      if (index_all === 0) {
        if (controlValue.length === 1) {
          allZone = true;
          controls[controlName].value = this.state.zone;
        } else if (controlValue.length === (this.state.zone).length - 1) {
          allZone = false;
          controls[controlName].value.splice(index_all, 1);
        }
      } else if (index_all > 0) {
        allZone = true;
        controls[controlName].value = this.state.zone;
      } else if (allZone && (controlValue.length === (this.state.zone).length - 1)) {
        allZone = false;
        controls[controlName].value = [];
      } else if ((!allZone || !find) && (controlValue.length === (this.state.zone).length - 1)) {
        allZone = true;
        controls[controlName].value = this.state.zone;
      }
    }

    this.setState({ allZone, controls });

    if (find) {
      this.getRegion(false, controls[controlName].value);
    }
  }

  allRegion = (find, controlName, controlValue) => {

    let { controls, allRegion } = this.state;

    if (controlValue.length > 0) {
      let index_all = controls[controlName].value.findIndex(x => x.label === "All");

      if (index_all === 0) {
        if (controlValue.length === 1) {
          allRegion = true;
          controls[controlName].value = this.state.region;
        } else if (controlValue.length === (this.state.region).length - 1) {
          allRegion = false;
          controls[controlName].value.splice(index_all, 1);
        }
      } else if (index_all > 0) {
        allRegion = true;
        controls[controlName].value = this.state.region;
      } else if (allRegion && (controlValue.length === (this.state.region).length - 1)) {
        allRegion = false;
        controls[controlName].value = [];
      } else if (!allRegion && (controlValue.length === (this.state.region).length - 1)) {
        allRegion = true;
        controls[controlName].value = this.state.region;
      }
    }

    this.setState({ allRegion, controls });
    if (find) {
      this.getArea(false, controls[controlName].value);
    }
  }

  allArea = (find, controlName, controlValue) => {

    let { controls, allArea } = this.state;

    if (controlValue.length > 0) {
      let index_all = controls[controlName].value.findIndex(x => x.label === "All");

      if (index_all === 0) {
        if (controlValue.length === 1) {
          allArea = true;
          controls[controlName].value = this.state.area;
        } else if (controlValue.length === (this.state.area).length - 1) {
          allArea = false;
          controls[controlName].value.splice(index_all, 1);
        }
      } else if (index_all > 0) {
        allArea = true;
        controls[controlName].value = this.state.area;
      } else if (allArea && (controlValue.length === (this.state.area).length - 1)) {
        allArea = false;
        controls[controlName].value = [];
      } else if (!allArea && (controlValue.length === (this.state.area).length - 1)) {
        allArea = true;
        controls[controlName].value = this.state.area;
      }
    }

    this.setState({ allArea, controls });
  }

  getRegion = (isProp, val) => {
    val = Object.keys(val).map(function (key) {
      return val[key].value;
    });

    if (val.length > 0) {
      this.setState({ isLoading: true });
      MarketingCouponService.getTypeAndData({ "zone": val })
        .then(res => {

          let { region, controls } = this.state;

          if (res.data.data) {
            region = res.data.data.region;
            region.unshift({ label: "All", value: null });
          } else {
            region = [];
          }

          if (!isProp) {
            controls.region.value = [];
            controls.area.value = [];
            this.state.area = [];
          }

          this.setState({
            region,
            isLoading: false
          })

          this.allRegion(false, 'region', this.state.controls.region.value);

        })
        .catch(e => {
          this.setState({ isLoading: false });
          const err =
            e.response && e.response.data && e.response.data.error
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
        });
    } else {
      this.state.controls.region.value = [];
      this.state.region = [];
      this.state.controls.area.value = [];
      this.state.area = [];
      this.setState();
    }

  }

  getArea = (isProp, val) => {
    val = Object.keys(val).map(function (key) {
      return val[key].value;
    });
    if (val.length > 0) {
      this.setState({ isLoading: true });
      MarketingCouponService.getTypeAndData({ "region": val })
        .then(res => {

          let { area, controls } = this.state;

          if (res.data.data) {
            area = res.data.data.area;
            area.unshift({ label: "All", value: null });
          } else {
            area = [];
          }

          if (!isProp) {
            controls.area.value = [];
          }
          this.setState({
            area,
            isLoading: false
          })

          this.allArea(false, 'area', this.state.controls.area.value);

        })
        .catch(e => {
          this.setState({ isLoading: false });
          const err =
            e.response && e.response.data && e.response.data.error
              ? e.response.data.error[0]
              : null;
          const message = err && err.message ? err.message : ERROR_MESSAGE;
          ModalService.openAlert("", message, "error");
        });
    } else {
      this.state.controls.area.value = [];
      this.state.area = [];
      this.setState();
    }

  }

  onChangeFilter = (e) => {
    let controlName = e.target.name;
    this.setState({
      [controlName]: e.target.value
    })
  };

  onChangeDate = (e, controlName) => {
    const { controls } = this.state;
    controls[controlName].value = e;
    controls[controlName].touched = true;
    if (controls[controlName].touched === true && (controls[controlName].value === '' || controls[controlName].value === null)) {
      controls[controlName].showErrorMsg = true;
    } else {
      controls[controlName].showErrorMsg = false;
    }
    this.setState({ controls });
  };

  handleOnCancel = () => {
    this.props.onCloseSidebar(false);
  };

  fileUploadCheck = (data) => {
    // disabling button cause it can not be clicked multiple type
    this.setState({ disabled_button: true });

    // check validation first
    if (!this.handleValidation(true)) {
      this.setState({ disabled_button: false });
      return;
    }

    if ((this.state.click_on_url === true || this.state.click_on_url === 'true') && this.state.controls.content_url.value === null) {

      swal({
        title: "",
        text: `${this.state.image_url ? 'You have not added Image. Do you want to add ?' : 'You have not added offer URL. Do you want to add ?'}`,
        icon: "warning",
        buttons: ["No", "Yes"]
      }).then(isConfirm => {

        if (isConfirm) {
          this.setState({ disabled_button: false });
          return;
        } else {
          // if all feilds are validated than check if file uploaded and all zone and classification selected and give this warning mssg 
          if (((this.state.controls.classification).length === 0 && (this.state.controls.zone.value).length === 5) && (this.state.controls.selectedFile.value) !== '') {
            swal({
              title: "",
              text: "It seems you have selected all zones and not applied any other filter. This qualifies all dealers so the file uploaded will have no impact. Do you want to continue?",
              icon: "warning",
              buttons: ["No", "Yes"]
            }).then(isConfirm => {
              if (isConfirm) {
                this.addEditCoupon(data);
              } else {
                this.setState({ disabled_button: false });
              }
            });
          } else {
            this.addEditCoupon(data);
          }
        }
      });

    } else {

      // if all feilds are validated than check if file uploaded and all zone and classification selected and give this warning mssg 
      if (((this.state.controls.classification).length === 0 && (this.state.controls.zone.value).length === 5) && (this.state.controls.selectedFile.value) !== '') {
        swal({
          title: "",
          text: "It seems you have selected all zones and not applied any other filter. This qualifies all dealers so the file uploaded will have no impact. Do you want to continue?",
          icon: "warning",
          buttons: ["No", "Yes"]
        }).then(isConfirm => {
          if (isConfirm) {
            this.addEditCoupon(data);
          } else {
            this.setState({ disabled_button: false });
          }
        });
      } else {
        this.addEditCoupon(data);
      }

    }

  }

  addEditCoupon = (data) => {
    this.setState({ isLoading: true });

    let zone, region, area;
    let { controls } = this.state;
    const formData = new FormData();

    if (data.edit) {
      formData.append("uuid", controls.uuid);
    }

    if (controls.selectedFile.value) {
      formData.append("file", controls.selectedFile.value);
    }
    if (controls.program_name.value) {
      formData.append("program_name", controls.program_name.value);
    }
    if (controls.offer_detail.value) {
      formData.append("offer_details", controls.offer_detail.value);
    }
    if (controls.type.value) {
      formData.append("type", controls.type.value);
    }
    if (controls.purpose.value) {
      formData.append("purpose", controls.purpose.value);
    }
    if (controls.coupon_quantity.value === 0 || controls.coupon_quantity.value === '0' || controls.coupon_quantity.value) {
      formData.append("coupon_quantity", controls.coupon_quantity.value);
    }
    if (controls.discount.value === 0 || controls.discount.value === '0' || controls.discount.value) {
      formData.append("discount_per_coupon", controls.discount.value);
    }

    formData.append("repeat_count", controls.multiReddemLimit.value ? controls.multiReddemLimit.value : 0)

    if (controls.tyre_per_coupon.value === 0 || controls.tyre_per_coupon.value === '0' || controls.tyre_per_coupon.value) {
      formData.append("maximum_tyres_per_coupon", controls.tyre_per_coupon.value);
    }
    if (controls.start_date.value) {
      let date = new Date(controls.start_date.value).setHours(0, 0, 0, 0);
      formData.append("coupon_validity_start_date", new Date(date).toISOString());
    }
    if (controls.end_date.value) {
      let date = new Date(controls.end_date.value).setHours(0, 0, 0, 0);
      formData.append("coupon_validity_end_date", new Date(date).toISOString());
    }

    formData.append("filter", controls.filter.value);

    formData.append("paid_by_jk", controls.paid_by_jk.value);

    formData.append("redeemable", controls.redeemable.value);

    formData.append("is_multiple_repeat", controls.redeemable.value ? controls.redeemableMulti.value : 'false');

    formData.append("one_tyre_price", controls.one_tyre_price.value);

    if (controls.content_url.value) {
      formData.append("content_url", controls.content_url.value);
    }

    zone = Object.keys(controls.zone.value).map(function (key) {
      return controls.zone.value[key].value;
    });
    formData.append("zone", zone);

    region = Object.keys(controls.region.value).map(function (key) {
      return controls.region.value[key].value;
    });
    formData.append("region", region);

    area = Object.keys(controls.area.value).map(function (key) {
      return controls.area.value[key].value;
    });
    formData.append("area", area);

    formData.append("classification", controls.classification);
    MarketingCouponService.addCouon(formData)
      .then(res => {
        this.setState({
          isLoading: false
        });
        if (res.data && res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success"
          }).then(value => {
            this.setState({ disabled_button: false });
            this.handleOnCancel();
          });
        };

      })
      .catch(e => {
        this.setState({ disabled_button: false });
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
        this.handleOnCancel();
      });
  }

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid, classificationShowErrorMsg } = this.state;
    let { program_name, offer_detail, type, purpose, discount, multiReddemLimit, coupon_quantity, tyre_per_coupon, start_date, end_date, redeemableMulti } = controls;
    isFormValid = true;


    if ((program_name.touched === true || isSubmit) && program_name.value === '') {
      program_name.showErrorMsg = true;
      isFormValid = false;
    }
    if ((offer_detail.touched === true || isSubmit) && offer_detail.value === '') {
      offer_detail.showErrorMsg = true;
      isFormValid = false;
    }

    if ((type.touched === true || isSubmit) && type.value === '') {
      type.showErrorMsg = true;
      isFormValid = false;
    }

    if ((purpose.touched === true || isSubmit) && purpose.value === '') {
      purpose.showErrorMsg = true;
      isFormValid = false;
    }

    if ((coupon_quantity.touched === true || isSubmit) && (coupon_quantity.value !== 0 || coupon_quantity.value !== '0') && (coupon_quantity.value === '' || coupon_quantity.value === null)) {
      coupon_quantity.showErrorMsg = true;
      isFormValid = false;
    }

    if ((discount.touched === true || isSubmit) && (discount.value !== 0 || discount.value !== '0') && (discount.value === '' || discount.value === null)) {
      discount.showErrorMsg = true;
      isFormValid = false;
    }
    if ((multiReddemLimit.touched === true || isSubmit) && (multiReddemLimit.value !== 0 || multiReddemLimit.value !== '0') && (multiReddemLimit.value === '' || multiReddemLimit.value === null) && this.state.multiredeem && redeemableMulti.value) {
      multiReddemLimit.showErrorMsg = true;
      isFormValid = false;
    }

    if ((tyre_per_coupon.touched === true || isSubmit) && (tyre_per_coupon.value !== 0 || tyre_per_coupon.value !== '0') && (tyre_per_coupon.value === '' || tyre_per_coupon.value === null)) {
      tyre_per_coupon.showErrorMsg = true;
      isFormValid = false;
    }

    if ((start_date.touched === true || isSubmit) && start_date.value === '') {
      start_date.showErrorMsg = true;
      isFormValid = false;
    }

    if ((end_date.touched === true || isSubmit) && end_date.value === '') {
      end_date.showErrorMsg = true;
      isFormValid = false;
    }

    this.setState({ controls, isFormValid, classificationShowErrorMsg });
    return isFormValid;
  };

  handleClickReport = (data) => {
    this.setState({
      isLoading: true
    });
    MarketingCouponService.getSubReports(data)
      .then(res => {
        downlodFile(res.data.data.file);
        this.setState({
          isLoading: false
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        const err =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;
        ModalService.openAlert("", message, "error");
      });
  };


  ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.zone.length === this.state.controls.zone.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen &&
            <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueZone" value={this.state.serachValueZone} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  ValueContainerRegion = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.region.length === this.state.controls.region.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen &&
            <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueZone" value={this.state.serachValueRegion} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  ValueContainerArea = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <>
        <components.ValueContainer {...props} className="d-flex">
          <span>{`${this.state.area.length === this.state.controls.area.value.length ? nbValues - 1 : nbValues} selected`}</span>
          {props.selectProps.menuIsOpen &&
            <input type="text" style={{ border: 'none', outline: 'none', backgroundColor: '#fff', width: '100%' }} name="serachValueArea" value={this.state.serachValueArea} onChange={(e) => this.onChangeFilter(e)} placeholder='Serach...' />}
        </components.ValueContainer>
      </>
    );
  };

  customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleClickOutside = (event) => {
    if (this.refZone) {
      const domNode = ReactDOM.findDOMNode(this.refZone);
      const domNodeR = ReactDOM.findDOMNode(this.refRegion);
      const domNodeA = ReactDOM.findDOMNode(this.refArea);

      if (!domNode || !domNode.contains(event.target)) {
        if (this.refZone.state.menuIsOpen) {
          this.refZone.onMenuClose();
        }
      }

      if (!domNodeR || !domNodeR.contains(event.target)) {
        if (this.refRegion.state.menuIsOpen) {
          this.refRegion.onMenuClose();
        }
      }

      if (!domNodeA || !domNodeA.contains(event.target)) {
        if (this.refArea.state.menuIsOpen) {
          this.refArea.onMenuClose();
        }
      }
    }
  }

  handleInputChangeCheckClassificaton = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    var { classification } = controls;

    const controlValue = !controls[controlName];
    controls[controlName] = controlValue;

    controls.checkSW = false;
    controls.checkXW = false;
    controls.checkTW = false;
    controls.checkPTP = false;
    controls.checkMBO = false;
    while (classification.length > 0) {
      classification.pop();
    };
    controls[classification] = classification;
    this.setState({ classificationShowErrorMsg: false, controls });
  }

  handleInputChangeCheck = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    var { classification } = controls;

    const controlValue = !controls[controlName];
    if (controlValue) {
      if (controlName === 'checkAll') {
        controls.checkSW = false;
        controls.checkXW = false;
        controls.checkTW = false;
        controls.checkPTP = false;
        controls.checkMBO = false;
        while (classification.length > 0) {
          classification.pop();
        };
      }
      if (controlName === 'checkSW') {
        controls.checkAll = false;
        classification.push('sw')
      }
      if (controlName === 'checkXW') {
        controls.checkAll = false;
        classification.push('xw')
      }
      if (controlName === 'checkTW') {
        controls.checkAll = false;
        classification.push('tw')
      }
      if (controlName === 'checkPTP') {
        controls.checkAll = false;
        classification.push('ptp')
      }
      if (controlName === 'checkMBO') {
        controls.checkAll = false;
        classification.push('mbo')
      }
      if (controlName === 'checkHY') {
        controls.checkAll = false;
        classification.push('hy')
      }
    } else {
      let index;
      if (controlName === 'checkSW') {
        index = classification.indexOf('sw')
        classification.splice(index, 1);
      }
      if (controlName === 'checkXW') {
        index = classification.indexOf('xw')
        classification.splice(index, 1);
      }
      if (controlName === 'checkTW') {
        index = classification.indexOf('tw')
        classification.splice(index, 1);
      }
      if (controlName === 'checkPTP') {
        index = classification.indexOf('ptp')
        classification.splice(index, 1);
      }
      if (controlName === 'checkMBO') {
        index = classification.indexOf('mbo')
        classification.splice(index, 1);
      }
      if (controlName === 'checkHY') {
        index = classification.indexOf('hy')
        classification.splice(index, 1);
      }
      if (classification.length === 0) {
        this.state.controls.checkAll = true;
      }
    }
    controls[controlName] = controlValue;
    controls[classification] = classification;
    this.setState({ controls }, () => {
      if (controls.checkClassification && classification.length === 0) {
        this.setState({ classificationShowErrorMsg: true });
      } else {
        this.setState({ classificationShowErrorMsg: false });
      }

    });

  }

  render() {
    const {
      programData,
      type,
      purpose,
      zone,
      region,
      area,
      controls,
      fileName,
      action,
      discount_disable,
      multiRedeem_disable,
      disabled_button,
      dealer,
      previousLimitError
    } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: 70,
        overflow: 'auto',
        theme: 'primary',
        borderColor: '#fee19d',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
      }),
      input: styles => ('text'),
    };

    const CustomOption = props => {
      const { innerRef, innerProps } = props;
      return (
        <>
          <div className="d-flex" style={{ padding: '5px' }} ref={innerRef} {...innerProps}>
            <label className="custom-checkboax1--container"
              ref={innerRef} {...innerProps}>
              <input
                type="checkbox"
                name="service"
                checked={props.isSelected === true || props.isSelected === 'true'}
              />
              <span className="checkmark1" />
            </label>

            <span className="ml-2" ref={innerRef} {...innerProps} style={{ marginTop: '-2px' }}>{props.data.label}</span>
          </div>
        </>
      );
    };

    let file = null;
    file = fileName
      ? (<span>{fileName}</span>)
      : (<span className="hover-download">Upload Excel</span>);

    let title = 'GENERATE COUPON';
    title = action === 'edit'
      ? (<span>Edit Coupon Details</span>)
      : action === 'duplicate' ? (<span>Duplicate Coupon</span>) : title;
    return (
      <>
        {this.state.isLoading && <CustomSpinner />}
        <>
          <div className="drawer-header">
            <span className="drawer-title">
              {title}
            </span>
            <Ionicon
              className="cursor-pointer"
              icon="md-close"
              fontSize="24px"
              color="#fdbb1f"
              onClick={() => this.handleOnCancel()}
            />
          </div>
          <hr style={{ border: "solid 1px #ced4da" }} />
          <div className="custom-drawer-content-wrapperr Fix-height" onClick={this.handleClickOutside}>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <FormGroup>

                  <Label for="common">Select Program &nbsp;<span className="required-field">*</span></Label>
                  <div className="d-flex-row search">
                    <Typeahead
                      disabled={controls.program_name.disabled ? true : null}
                      id="program_name"
                      filterBy={['program_name']}
                      options={programData}
                      selected={controls.program_name.value ? [controls.program_name.value] : null}
                      allowNew
                      onBlur={(e) => { this.handleInputChange({ target: { name: 'program_name', value: e && e.target && e.target.value } }); }}
                      newSelectionPrefix="Create New Program: "
                      labelKey="program_name"
                      onChange={(e) => { this.handleInputChange({ target: { name: 'program_name', value: e && e[0] && e[0].program_name } }); }}
                      name="program_name"
                      placeholder="Select"
                      renderMenuItemChildren={(selectedItem) => {
                        return <>{selectedItem.program_name}</>;
                      }}
                    // selected={controls.program_name ? [controls.program_name] : []}
                    />
                    <InputGroupAddon addonType="append" className="serach-icon" style={{ marginTop: '0px' }}>
                      <div className="input-group-text-serch">
                        <FontAwesomeIcon htmlFor="datepicker" icon={faSearch} />
                      </div>
                    </InputGroupAddon>

                  </div>
                  {controls.program_name.showErrorMsg && (
                    <div className="error">
                      * Please add program name.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12">
                <Label for="common">Offer Detail &nbsp;<span className="required-field">*</span></Label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="Download-Full-Report"
                  onClick={this.handleChangeUrl}
                  for="file">Add Picture &nbsp;</label>
                <input style={{ "display": "none" }}
                  id="file"
                  type="file"
                  accept="image/*"
                  onChange={e =>
                    this.handleInputChangeFile({
                      target: {
                        name: "selectedFile",
                        value: e.target.files[0]
                      }
                    })
                  }
                  className="width-100"
                  ref={ref => (this.fileInput = ref)}
                />
                <Label for="common"><span className=""> Or &nbsp;</span></Label>
                <Label for="common"><span
                  onClick={this.handleInputChangeUrl}
                  className="Download-Full-Report">Add URL</span></Label>
                <FormGroup>
                  <Input
                    type="text"
                    autoComplete="nofill"
                    name="offer_detail"
                    id="offer_detail"
                    value={controls.offer_detail.value}
                    onChange={this.handleInputChange}
                    placeholder="Type here"
                  />
                  {controls.offer_detail.showErrorMsg && (
                    <div className="error">
                      * Please add offer detail.
                    </div>
                  )}
                </FormGroup>
                {(this.state.image_url === true || this.state.image_url === 'true') &&
                  <div>
                    <div style={{ float: "left" }}>
                      <img src={controls.content_url.value} alt='' height='80' />&nbsp;&nbsp;
                    </div>
                    <div style={{ paddingTop: "50px", float: "left" }}>
                      <img
                        className="cursor-pointer"
                        style={{ height: '13px' }}
                        onClick={() => this.openViewModal()}
                        src='images/eye.png'
                        alt=''
                      /> &nbsp;&nbsp;
                      <label for="file">
                        <img
                          className="cursor-pointer"
                          style={{ height: "13px" }}
                          src="images/edit.svg"
                          alt=''
                        />
                      </label>
                      <input style={{ "display": "none" }}
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={e =>
                          this.handleInputChangeFile({
                            target: {
                              name: "selectedFile",
                              value: e.target.files[0]
                            }
                          })
                        }
                        className="width-100"
                        ref={ref => (this.fileInput = ref)}
                      />&nbsp;&nbsp;
                      <img
                        className="cursor-pointer ml-1"
                        style={{ height: "13px" }}
                        onClick={() => this.deleteImage()}
                        src="images/delete.svg"
                        alt=''
                      />
                    </div>
                  </div>}
                {(this.state.image_url === false || this.state.image_url === 'false') &&
                  <>
                    <Label for="common">Add URL</Label>
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="nofill"
                        name="content_url"
                        id="content_url"
                        value={controls.content_url.value}
                        onChange={this.handleInputChange}
                        placeholder="Type here"
                      />
                    </FormGroup>
                  </>
                }
              </Col>
            </Row>



            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Type{controls.type.disabled} &nbsp;<span className="required-field">*</span></Label>

                <FormGroup>
                  <select
                    style={controls.type.disabled ? { backgroundColor: "#e9ecef" } : {}}
                    className="form-control form-group-select select"
                    disabled={controls.type.disabled ? true : null}
                    name="type"
                    value={controls.type.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      type.map((item) => (
                        <option className="label-text" value={item.vehicle_type_id}>{item.name}</option>
                      ))
                    }
                  </select>
                  {controls.type.showErrorMsg && (
                    <div className="error">
                      * Please select type.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Purpose &nbsp;<span className="required-field">*</span></Label>
                <FormGroup>
                  <select
                    style={controls.type.disabled ? { backgroundColor: "#e9ecef" } : {}}
                    className="form-control form-group-select select"
                    disabled={controls.purpose.disabled ? true : null}
                    name="purpose"
                    value={controls.purpose.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option className="label-text" value="">Select</option>
                    {
                      purpose.map((item) => (
                        <option className="label-text" value={item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                  {controls.purpose.showErrorMsg && (
                    <div className="error">
                      * Please select purpose.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0", paddingRight: "0" }}>
                <hr style={{ border: "solid 1px #e9ecef" }} />
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0", marginBottom: "5px" }}>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Coupon Redeemable&nbsp;<span className="required-field">*</span></Label>
              </Col>
              {/* style={{ marginBottom: "5" }} */}
              <Col xl="6" md="12" xs="12" style={action === 'edit' ? { opacity: "0.6" } : {}}>
                <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0px" }}>
                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="redeemable"
                      value={true}
                      onChange={() => {
                        this.setState({ multiredeem: true })
                        this.handleRadioChangeReedeemable(true);
                      }}
                      checked={controls.redeemable.value === true} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">Yes</span>

                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="redeemable"
                      value={false}
                      onChange={() => {
                        this.setState({ multiredeem: false })
                        this.handleRadioChangeReedeemable(false);
                      }}
                      checked={controls.redeemable.value === false} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">No</span>
                </Col>
              </Col>
            </Row>

            {this.state.multiredeem && <><Row style={{ marginLeft: "0", marginRight: "0", marginBottom: "5px" }}>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Coupon Multi Redeem&nbsp;<span className="required-field">*</span></Label>
              </Col>
              {/* style={{ marginBottom: "5" }} */}
              <Col xl="6" md="12" xs="12" style={action === 'edit' ? { opacity: "0.6" } : {}}>
                <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0px" }}>
                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="redeemableMulti"
                      value={true}
                      onChange={() => {
                        this.handleRadioChangeMultiReedeemable(true);
                      }}
                      checked={controls.redeemableMulti.value === true} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">Yes</span>

                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="redeemableMulti"
                      value={false}
                      onChange={() => {
                        this.handleRadioChangeMultiReedeemable(false);
                      }}
                      checked={controls.redeemableMulti.value === false} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">No</span>
                </Col>
              </Col>
            </Row>

              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col xl="6" md="12" xs="12">
                  <Label for="common">Coupon Multi Redeem Limit &nbsp;<span className="required-field">*</span></Label>
                  <FormGroup>
                    <Input
                      disabled={controls.multiReddemLimit.disabled || multiRedeem_disable ? true : null}
                      type="text"
                      // min="0"
                      name="multiReddemLimit"
                      id="multiReddemLimit"
                      placeholder="Type here"
                      value={controls.multiReddemLimit.value}
                      onChange={this.handleInputChange}
                    />
                    {controls.multiReddemLimit.showErrorMsg && action !== 'edit' && (
                      <div className="error">
                        * Please add multi redeem limit.
                      </div>
                    )}
                    {
                      previousLimitError && (
                        <div className="error">
                          * Coupon multi redeem limit should not be less then current limit.
                        </div>
                      )
                    }
                  </FormGroup>
                </Col>
              </Row> </>}

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Discount per Coupon(INR) &nbsp;<span className="required-field">*</span></Label>
                <FormGroup>
                  <Input
                    disabled={controls.discount.disabled || discount_disable ? true : null}
                    type="text"
                    // min="0"
                    name="discount"
                    id="discount"
                    placeholder="Amount in rupees"
                    value={controls.discount.value}
                    onChange={this.handleInputChange}
                  />
                  {controls.discount.showErrorMsg && (
                    <div className="error">
                      * Please add discount.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12" style={action === 'edit' ? { opacity: "0.6" } : {}}>
                <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0px" }}>
                  <Label for="common">Coupon Amount to be paid by &nbsp;<span className="required-field">*</span></Label>
                </Col>
                <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0px", marginTop: "5px" }}>
                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="paid_by_jk"
                      value={true}
                      onChange={() => {
                        this.handleRadioChange(true);
                      }}
                      checked={controls.paid_by_jk.value === true} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">JK Tyre</span>

                  <label className="custom-checkboax-radio--container">
                    <input type="radio"
                      disabled={action === 'edit' ? true : null}
                      name="paid_by_jk"
                      value={false}
                      onChange={() => {
                        this.handleRadioChange(false);
                      }}
                      checked={controls.paid_by_jk.value === false} />
                    <span className="checkmark-radio" />
                  </label>
                  <span className="label-text marg-pad-generate">Dealer</span>
                </Col>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Coupon Quantity &nbsp;<span className="required-field">*</span></Label>
                <FormGroup>
                  <Input
                    disabled={controls.coupon_quantity.disabled ? true : null}
                    type="text"
                    // min="0"
                    name="coupon_quantity"
                    id="coupon_quantity"
                    placeholder="Type here"
                    value={controls.coupon_quantity.value}
                    onChange={this.handleInputChange}
                  />
                  {controls.coupon_quantity.showErrorMsg && (
                    <div className="error">
                      * Please add quantity.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Maximum Tyres per Coupon &nbsp;<span className="required-field">*</span></Label>
                <FormGroup>
                  <Input
                    disabled={controls.tyre_per_coupon.disabled ? true : null}
                    type="text"
                    // min="0"
                    name="tyre_per_coupon"
                    id="tyre_per_coupon"
                    placeholder="Type here"
                    value={controls.tyre_per_coupon.value}
                    onChange={this.handleInputChange}
                  />
                  {controls.tyre_per_coupon.showErrorMsg && (
                    <div className="error">
                      * Please add tyre per coupon.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Validity Start Date&nbsp;<span className="required-field">*</span></Label>

                  <Label
                    onClick={e =>
                      this.calendar1.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker className="m_title form-control"
                        placeholderText="Select"
                        onChange={e => this.onChangeDate(e, "start_date")}
                        selected={controls.start_date.value}
                        name="start_date"
                        dateFormat="dd/MM/yyyy"
                        autoComplete="nofill"
                        showMonthDropdown
                        ref={r => (this.calendar1 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        // minDate={new Date()}
                        maxDate={controls.end_date.value ? controls.end_date.value : null}
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                          }
                        }}
                        dropdownMode="select"
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                  {controls.start_date.showErrorMsg && (
                    <div className="error">
                      * Please choose start date.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="3" md="12" xs="12">
                <FormGroup>
                  <Label for="common">Validity End Date&nbsp;<span className="required-field">*</span></Label>
                  <Label
                    className="add-customer-date"
                    onClick={e =>
                      this.calendar2.state.open && e.preventDefault()
                    }
                  >
                    <InputGroup>
                      <DatePicker
                        placeholderText="Select"
                        className={"form-control"}
                        onChange={e =>
                          this.onChangeDate(e, "end_date")
                        }
                        name="end_date"
                        // maxDate={new Date()}
                        selected={controls.end_date.value}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="nofill"
                        showMonthDropdown
                        ref={r => (this.calendar2 = r)}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        minDate={controls.start_date.value ? controls.start_date.value : null}
                        dropdownMode="select"
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                          }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <div className="input-group-text">
                          <FontAwesomeIcon
                            htmlFor="datepicker"
                            icon={faCalendarAlt}
                          />
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                  {controls.end_date.showErrorMsg && (
                    <div className="error">
                      * Please choose end date.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" md="12" xs="12">
                <Label for="common">Price of one Tyre</Label>
                <FormGroup>
                  <Input
                    disabled={controls.one_tyre_price.disabled ? true : null}
                    type="text"
                    // min="0"
                    name="one_tyre_price"
                    id="one_tyre_price"
                    placeholder="Type here"
                    value={controls.one_tyre_price.value}
                    onChange={this.handleInputChange}
                  />
                  {/* {controls.one_tyre_price.showErrorMsg && (
                                        <div className="error">
                                            * Please add tyre per coupon.
                                        </div>
                                    )} */}
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12" style={{ paddingLeft: "0", paddingRight: "0" }}>
                <hr style={{ border: "solid 1px #e9ecef" }} />
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="12" md="12" xs="12" style={{ "margin-bottom": "15px" }}>
                <span className="mail-label">Dealer Outlets, where coupons can be redeemed</span>
              </Col>
            </Row>

            <Row style={{ marginLeft: "0", marginRight: "0" }}>

              <Col xl="12" md="12" xs="12" style={{ "margin-bottom": "15px" }}>
                <label className="custom-checkboax-radio--container">
                  <input type="radio"
                    name="filter"
                    value='all'
                    onChange={() => {
                      this.handleRadioChangefilter('all');
                    }}
                    checked={controls.filter.value === 'all'} />
                  <span className="checkmark-radio" />
                </label>
                <span className="label-text marg-pad-generate">
                  All
                  (<span /* onClick={() => this.handleClickReport({ type : 'all_dealer' })} */ className="link-warm-blue" style={{ fontSize: "14px" }}>{dealer}</span>)</span>

                <label className="custom-checkboax-radio--container">
                  <input type="radio"
                    name="filter"
                    value='customize'
                    onChange={() => {
                      this.handleRadioChangefilter('customize');
                    }}
                    checked={controls.filter.value === 'customize'} />
                  <span className="checkmark-radio" />
                </label>
                <span className="label-text marg-pad-generate">Customize</span>
              </Col>
            </Row>

            {controls.filter.value === 'customize' && <> <Row style={{ marginLeft: "0", marginRight: "0" }}>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Zone
                  </Label>
                  <Select
                    {...this.props.input}
                    ref={r => this.refZone = r}
                    name="zone"
                    pageSize="1"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={zone}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.zone.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'zone', value: e } })}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueZone', value: e } })}
                    placeholder="Select"
                    styles={customStyles}
                    hideSelectedOptions={false}
                    inputValue={this.state.serachValueZone}
                    components={{ Option: CustomOption, ValueContainer: this.ValueContainer }}
                    backspaceRemoves={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Region
                  </Label>
                  <Select
                    {...this.props.input}
                    ref={r => this.refRegion = r}
                    name="region"
                    pageSize="1"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={region}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.region.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'region', value: e } })}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueRegion', value: e } })}
                    placeholder="Select"
                    styles={customStyles}
                    hideSelectedOptions={false}
                    inputValue={this.state.serachValueRegion}
                    components={{ Option: CustomOption, ValueContainer: this.ValueContainerRegion }}
                    backspaceRemoves={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="12" xs="12">
                <FormGroup>
                  <Label className="label-text">
                    Territory
                  </Label>
                  <Select
                    {...this.props.input}
                    ref={r => this.refArea = r}
                    name="area"
                    pageSize="1"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={area}
                    allowSelectAll
                    closeMenuOnSelect={false}
                    value={controls.area.value}
                    tabSelectsValue={false}
                    isSearchable={true}
                    filterOption={this.customFilter}
                    onChange={(e) => this.handleInputChange({ target: { name: 'area', value: e } })}
                    onInputChange={(e) => this.onChangeFilter({ target: { name: 'serachValueArea', value: e } })}
                    placeholder="Select"
                    styles={customStyles}
                    hideSelectedOptions={false}
                    inputValue={this.state.serachValueArea}
                    components={{ Option: CustomOption, ValueContainer: this.ValueContainerArea }}
                    backspaceRemoves={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>

              {/* <Row style={{ marginLeft: "0", marginRight: "0", marginTop: "10" }}>
                            <Col xl="12" md="12" xs="12">
                                <FormGroup>
                                    <label className="custom-checkboax1--container">
                                        <input type="checkbox" name="checkClassification" checked={controls.checkClassification}
                                            onChange={(e) => this.handleInputChangeCheckClassificaton(e)}
                                        />
                                        <span className="checkmark1" />
                                    </label>&nbsp;
                                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">Select Dealer Classification</span>
                                </FormGroup>
                            </Col>
                        </Row> */}

              <Row style={{ marginTop: "5px", marginLeft: "0", marginRight: "0" }}>

                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkSW" checked={controls.checkSW}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">SW</span>
                  </FormGroup>
                </Col>

                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkXW" checked={controls.checkXW}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">XW</span>
                  </FormGroup>
                </Col>

                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkTW" checked={controls.checkTW}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">TW</span>
                  </FormGroup>
                </Col>

                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkPTP" checked={controls.checkPTP}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">PTP</span>
                  </FormGroup>
                </Col>

                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkMBO" checked={controls.checkMBO}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">MBO {controls.classificationShowErrorMsg}</span>
                  </FormGroup>
                </Col>
                <Col xl="2" md="6" xs="12">
                  <FormGroup>
                    <label className="custom-checkboax1--container">
                      <input type="checkbox" name="checkHY" checked={controls.checkHY}
                        onChange={(e) => this.handleInputChangeCheck(e)}
                      />
                      <span className="checkmark1" />
                    </label>&nbsp;
                    <span className="dropdown-toggle-fleet-type-filter-sub-lbl">HY {controls.classificationShowErrorMsg}</span>
                  </FormGroup>
                </Col>
                {/* <Col xl="12" md="12" xs="12">
                                {controls.classificationShowErrorMsg && (
                                    <div className="error" style={{ "margin-bottom": "20px" }}>
                                        * Please choose atleast one Classification.
                                    </div>
                                )}
                            </Col> */}
              </Row>


              <Row style={{ marginLeft: "0", marginRight: "0" }}>
                <Col xl="12" md="12" xs="12">
                  <Label for="common">If you want specific dealers also to participate in this program please upload excel with their SAP ID</Label>
                </Col>
                <Col xl="3" md="12" xs="12" style={{ "padding-right": "0px" }}>
                  <label style={{ fontWeight: 'bold', color: "#5665d8", 'font-size': '12px' }}
                    for="file_xlsx">{file}</label>
                  <input style={{ "display": "none" }}
                    id="file_xlsx"
                    type="file"
                    accept=".xlsx"
                    onChange={e =>
                      this.handleInputChange({
                        target: {
                          name: "selectedFile",
                          value: e.target.files[0]
                        }
                      })
                    }
                    className="width-100"
                    ref={ref => (this.fileInput = ref)}
                  />
                </Col>
                <Col xl="5" md="12" xs="12" style={{ "padding-right": "0px" }}>
                  <span className="link-warm-blue cursor-pointer">
                    <a
                      href="https://images-staging.jkadvantage.co.in/fleet_register_doc/80491589276961692.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      style={{ color: "#5665d8", 'font-size': '12px' }}
                    >
                      <label className="">Download Sample</label>
                    </a>
                  </span>
                </Col>
              </Row> </>}
          </div>

          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
              height: "100%",
              maxHeight: "80px",
              position: "relative",
              bottom: "0",
              width: "100%"
            }}
          >
            <Col
              xl="12"
              xs="12"
              md="12"
              className="text-align-right footer-drawer"
            >
              <Button
                className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                color="dark"
                onClick={() => this.handleOnCancel()} style={{ width: '116px' }}>
                Cancel
              </Button>&nbsp;&nbsp;

              <Button
                disabled={disabled_button ? true : null}
                className="mb-1 mr-1 btn-pill action-button profile-save-button"
                color="warning"
                onClick={() => {
                  action === 'edit' ? this.fileUploadCheck({ 'edit': true }) : this.fileUploadCheck({ 'edit': false });
                }}
              >
                {action === 'new' ? 'Generate' : 'Save'}
              </Button>
            </Col>
          </Row>
          <PosterView
            title=""
            ref="openViewModal"
          />
        </>
      </>
    );
  }
}

export default GenerateCoupon;
