import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Storage from '../../services/Storage';
import UserService from "../../services/UserService";
import StorageService from "../../services/Storage";

import { setUserDetail, isProfileUpdate } from "../../actions/UserAction";
import { updateSideMenu } from "../../actions/ThemeAction";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./UserBox.css";
import ModalService from "../../services/ModalService";
import ChangePassword from "../../modal/ChangePassword/ChangePassword";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class UserBox extends Component {
  state = {
    userDetail: Storage.getUserDetail(),
    isModalVisible: false,
    ownerName: null,
    sapId: null,
    profileCompletePercentage: 0,
    redirectToProfile: false,
    profileImage: null,
    areaName: "",
    dropdownOpen: false,
    customerClass: this.props.customerClass,
    isChangePasswordModalOpen: false,
  };

  componentDidMount() {
    let userDetail = this.props.userDetail;
    if (!userDetail) {
      this.getUserInfo();
    } else {
      this.setValues(userDetail);
    }
  }

  openChangePasswordModal = () => {
    this.setState({ isChangePasswordModalOpen: true });
  };

  closeChangePasswordModal = (update, message) => {
    this.setState({ isChangePasswordModalOpen: false });
    if (update === true) {
      ModalService.openAlert("Update Password", message, "success");
    }
  };

  getUserInfo = () => {
    UserService.getUserInfo()
      .then((data) => {
        let userData = data.data.data;
        let userDetailFromLocalStorage = StorageService.getUserDetail();
        userDetailFromLocalStorage.permissions = userData.permissions;
        StorageService.setUserDetail(userDetailFromLocalStorage);
        this.props.setUserDetail(userData);
        this.props.isProfileUpdate(false);
        this.props.updateSideMenu(true);
        this.setValues(userData);
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  componentWillReceiveProps(props) {
    let isProfileUpdate = props.isProfileUpdateState;
    let userDetail = props.userDetail;
    if (isProfileUpdate) {
      this.getUserInfo();
    }
    if (userDetail) {
      this.setValues(userDetail);
    }
  }

  setValues = (uDetail) => {
    let {
      sap_id,
      owner_name,
      image_url,
      area_name,
      nick_name,
      type,
    } = uDetail;

    let { ownerName, sapId, profileImage } = this.state;
    ownerName =
      type === Roles.SUB_DEALER_ROLE || type === Roles.BRANCH_ROLE ? nick_name : owner_name;
    sapId = sap_id;

    let totalFields = 13;
    let filledFields = 0;
    let fields = [
      "email_1",
      "telephone_no_1",
      "gst_number",
      "pan_number",
      "alternate_contact_person",
      "alternate_contact_number",
      "designation",
      "whats_app_number",
      "birth_date",
      "anniversary_date",
      "facebook_url",
      "instagram_url",
      "website",
    ];

    if (type === Roles.DEALER_ROLE && this.state.userDetail.customer_class !== "na" && this.state.userDetail.customer_class !== "hy") {
      totalFields = 13;
      filledFields = 0;
      fields = [
        "email_1",
        "telephone_no_1",
        "gst_number",
        "pan_number",
        "alternate_contact_person",
        "alternate_contact_number",
        "designation",
        "whats_app_number",
        "birth_date",
        "anniversary_date",
        "facebook_url",
        "instagram_url",
        "website",
      ];
    } else if (type === Roles.SUB_DEALER_ROLE) {
      totalFields = 9;
      filledFields = 0;
      fields = [
        "business_name",
        "owner_name",
        "address_line_1",
        "state",
        "city",
        "pin_code",
        "gst_number",
        "pan_number",
        "whats_app_number_user",
      ];
    } else if (type === Roles.BRANCH_ROLE) {
      totalFields = 6;
      filledFields = 0;
      fields = [
        "alternate_contact_person",
        "whats_app_number_user",
        "state",
        "city",
        "pin_code",
        "address_line_1",
      ];
    } else if (this.state.userDetail.customer_class === "na" || this.state.userDetail.customer_class === "hy") {
      totalFields = 6;
      filledFields = 0;
      fields = [
        "alternate_contact_person",
        "whats_app_number_user",
        "state",
        "city",
        "pin_code",
        "address_line_1",
      ];
    }

    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (!(uDetail[field] === null || uDetail[field] === "")) {
        filledFields++;
      }
    }
    let percentage = (filledFields / totalFields) * 100;
    percentage = Math.floor(percentage);
    profileImage = image_url;
    let areaName = area_name;
    this.setState({
      ownerName,
      sapId,
      profileCompletePercentage: percentage,
      profileImage,
      areaName,
    });
  };

  redirectToProfile = () => {
    this.setState({ redirectToProfile: true });
  };

  openProfileModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  toggle = () => {
    let userRoleDetail = StorageService.getUserDetail();
    if ([Roles.DEALER_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE].includes(userRoleDetail.type)) {
      this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }
    // this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    let userRoleDetail = StorageService.getUserDetail();
    let userRole = userRoleDetail.type;
    return (
      <Fragment>
        {this.state.isChangePasswordModalOpen && (
          <ChangePassword
            show={this.state.isChangePasswordModalOpen}
            closeModal={this.closeChangePasswordModal}
          ></ChangePassword>
        )}
        <UncontrolledButtonDropdown id="user-box">
          <DropdownToggle>
            {(userRole === Roles.DEALER_ROLE ||
              userRole === Roles.SUB_DEALER_ROLE ||
              userRole === Roles.BRANCH_ROLE ||
              userRole === Roles.VENDOR_ROLE ||
              (userRoleDetail &&
                userRoleDetail.permissions.includes(Permissions.PROFILE))) && (
                <Fragment>
                  {userRole !== Roles.VENDOR_ROLE && (
                    <>
                      {this.state.redirectToProfile && (
                        <Redirect to="/profile"></Redirect>
                      )}
                      {this.state.profileImage && (
                        <img
                          className="header-menus"
                          src={this.state.profileImage}
                          alt=""
                        />
                      )}
                      {!this.state.profileImage && (
                        <img
                          className="header-menus"
                          src="images/Web_Avatar.svg"
                          alt=""
                        />
                      )}
                      <FontAwesomeIcon
                        className="ml-2 opacity-8 arrow-color cursor-pointer"
                        icon={faAngleDown}
                        onClick={this.openProfileModal}
                      />
                    </>
                  )}
                  <div
                    className="user-detail"
                    style={
                      userRole === Roles.VENDOR_ROLE
                        ? { paddingTop: "23px" }
                        : { paddingTop: "opx" }
                    }
                  >
                    <div className="user-name title-case">
                      {this.state.ownerName}
                    </div>
                    <div className="sap-id">{this.state.sapId}</div>
                  </div>
                  {/* <Row>
              <Col>
              <div className="user-detail">
                <div className="user-name title-case">{this.state.ownerName}</div>
                <div className="sap-id">{this.state.sapId}</div>
              </div>
              </Col> */}
                  {userRole === Roles.VENDOR_ROLE && (
                    <div style={{ textAlign: "right" }}>
                      <img
                        style={{
                          width: "11.56px",
                          height: "6px",
                          marginTop: "-33px",
                        }}
                        className="header-menus"
                        src="images/down-arrow.png"
                        alt=""
                      />
                    </div>
                  )}
                  {/* </Row> */}
                  {/* <Row style={{ marginLeft: '5px', display: 'inline-block' }}>
              <Col>
                <div className="user-name title-case">{this.state.ownerName}</div>
                <div className="sap-id">{this.state.sapId}</div>
              </Col>
            </Row> */}
                </Fragment>
              )}

            {[
              Roles.AREA_MANAGER_ROLE,
              Roles.SALES_OFFICER_ROLE,
              Roles.REGION_MANAGER_ROLE,
              Roles.ZONE_MANAGER_ROLE,
              Roles.FINANCE_ROLE
            ].includes(userRole) && (
                <Fragment>
                  <div className="user-box-area-manager title-case">
                    {userRole === Roles.FINANCE_ROLE && <span>Finance </span>}
                    {userRole === Roles.AREA_MANAGER_ROLE && <span>Territory Manager </span>}
                    {userRole === Roles.REGION_MANAGER_ROLE && (
                      <span>Region Manager </span>
                    )}
                    {userRole === Roles.ZONE_MANAGER_ROLE && <span>Zone Manager </span>}
                    {userRole === Roles.SALES_OFFICER_ROLE && (
                      <span>Sales Officer </span>
                    )} {userRole !== Roles.FINANCE_ROLE && '-'} {this.state.areaName}
                  </div>
                </Fragment>
              )}

            {[Roles.HEAD_OFFICE_ROLE].includes(userRole) && (
              <Fragment>
                <div className="user-box-area-manager title-case">
                  Head Office
                </div>
                <img
                  style={{
                    width: "14px",
                    height: "8px",
                    position: 'absolute',
                    top: '12px',
                    right: '-5px'
                  }}
                  className="header-menus"
                  src="images/down-arrow.png"
                  alt=""
                />
              </Fragment>
            )}
            {[Roles.PROJECT_MANAGER_ROLE].includes(userRole) && (
              <Fragment>
                <div className="user-box-area-manager title-case">
                  Project Manager
                </div>
              </Fragment>
            )}
            {[Roles.ZTM_ROLE].includes(userRole) && (
              <Fragment>
                <div className="user-box-area-manager title-case">
                  Zonal Trade Marketing
                </div>
                <img
                  style={{
                    width: "14px",
                    height: "8px",
                    position: 'absolute',
                    top: '12px',
                    right: '-5px'
                  }}
                  className="header-menus"
                  src="images/down-arrow.png"
                  alt=""
                />
              </Fragment>
            )}
          </DropdownToggle>
          {([Roles.DEALER_ROLE, Roles.VENDOR_ROLE, Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE, Roles.HEAD_OFFICE_ROLE, Roles.ZTM_ROLE].includes(
            userRoleDetail.type
          ) ||
            (userRoleDetail &&
              userRoleDetail.permissions.includes(Permissions.PROFILE))) && (
              <DropdownMenu>
                <DropdownItem>
                  <div
                  // className="user_box_arrow_box"
                  >
                    <Card className="card-width">
                      <CardBody>
                        {[Roles.VENDOR_ROLE, Roles.HEAD_OFFICE_ROLE, Roles.ZTM_ROLE].includes(userRoleDetail.type) ? (
                          <Row>
                            <div
                              onClick={this.openChangePasswordModal}
                              style={{
                                marginLeft: "35%",
                                fontFamily: "Helvetica",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#545CD8",
                              }}
                            >
                              Change Password
                            </div>
                          </Row>
                        ) : (
                          <>
                            <Row>
                              <Col className="title">
                                PROFILE COMPLETION PROGRESS:
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col>
                                <Progress
                                  className="mb-3 progress-bar-container"
                                  value={this.state.profileCompletePercentage}
                                >
                                  {this.state.profileCompletePercentage}%
                                </Progress>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                className="text-align-right button-text cursor-pointer"
                                onClick={this.redirectToProfile}
                              >
                                {this.state.profileCompletePercentage === 100
                                  ? "View profile"
                                  : "Complete your Profile now"}
                              </Col>
                            </Row>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            )}
        </UncontrolledButtonDropdown>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
  isProfileUpdate: (bool) => dispatch(isProfileUpdate(bool)),
  updateSideMenu: (bool) => dispatch(updateSideMenu(bool)),
});

const mapStateToProps = (state) => ({
  userDetail: state.User.user,
  isProfileUpdateState: state.User.isProfileUpdate,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
