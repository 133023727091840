import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// import cookie from 'react-cookies';

// component
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import ConfirmAlert from "../../modal/ConfirmAlert/ConfirmAlert";
// import swal from "sweetalert";

// services
import AuthService from "../../services/AuthService";
import Validation from "../../services/Validation";
import Storage from "../../services/Storage";
import UserService from "../../services/UserService";

// actions
import { setUserDetail } from "../../actions/UserAction";

// constant
import { messages } from "../../constants/constant.message";

// css
import "./Login.css";
import DashboardService from "../../services/DashboardService";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

const { EMPTY_PASSWORD, EMPTY_USER_NAME } = messages.LOGIN;
const { ERROR_MESSAGE } = messages.COMMON;

class Login extends Component {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    redirectToOnBoarding: false,
    redirectToDashBoard: false,
    redirectToNewDealerDashBoard: false,
    redirectToForgotPasswordPage: false,
    redirectToEmployeeDashBoard: false,
    redirectToSystemArchitect: false,
    redirectToNotification: false,
    errResponseMessage: "",
    isLoading: false,
    controls: {
      username: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
      },
      password: {
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
        invalidPassword: null,
      },
      keepMeLoggenIn: false,
    },
    isFormValid: false,
    isErrorModalOpen: false,
    errorMessage: ERROR_MESSAGE,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow);
    this.props.setUserDetail(null);
    // window.location.reload(false)
  }
  refreshPage() {
    window.location.reload(false);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  };

  setCheckBoxValue = () => {
    const { controls } = this.state;
    controls.keepMeLoggenIn = !controls.keepMeLoggenIn;
    this.setState({ controls });
  };

  login = async (e) => {
    e.preventDefault();
    const { controls } = this.state;
    const { username, password, keepMeLoggenIn } = controls;

    const isFormValid = this.handleValidation();

    if (isFormValid === false) {
      return;
    }

    const obj = {
      username: username.value,
      password: password.value,
    };
    this.setState({ errResponseMessage: "", isLoading: true });
    await AuthService.login(obj)
      .then(async (data) => {
        const response = data.data;
        const obj = {
          accessToken: response.accessToken,
          accessTokenExpiresAt: response.accessTokenExpiresAt,
          refreshToken: response.refreshToken,
          refreshTokenExpiresAt: response.refreshTokenExpiresAt,
        };
        const userDetail = response.user;

        // if (userDetail.type.toLowerCase() === Roles.SUB_DEALER_ROLE || userDetail.type.toLowerCase() === Roles.BRANCH_ROLE) {
        //   this.setState({
        //     // errResponseMessage: "You are not authorized to login from Web Console. Please try the Mobile app.",
        //     isLoading: false,
        //     // errorMessage: "You are not authorized to login from Web Console. Please try the Mobile app.", isErrorModalOpen: true
        //   });
        //   swal('Sorry !', 'You are not authorized to login from Web Console. Please try the Mobile app.', 'error');
        //   return;
        // }
        Storage.setTokenDetail(obj);
        Storage.setUserDetail(userDetail);
        Storage.setIsTermConditionModal(true);
        if (keepMeLoggenIn === true) {
          this.setKeepMeLoggedIn(true);
        } else {
          this.setKeepMeLoggedIn(false);
        }
        GoogleAnalticsService.reactGAInitialize();
        await this.navBarToHide();
        if (
          userDetail.type.toLowerCase() === Roles.EMPLOYEE_ROLE ||
          userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE ||
          userDetail.type.toLowerCase() === Roles.AGENCY_ROLE ||
          userDetail.type.toLowerCase() === Roles.AREA_MANAGER_ROLE ||
          userDetail.type.toLowerCase() === Roles.REGION_MANAGER_ROLE ||
          userDetail.type.toLowerCase() === Roles.ZONE_MANAGER_ROLE ||
          userDetail.type.toLowerCase() === Roles.ZTM_ROLE
        ) {
          if (
            userDetail.access_level === "architect" ||
            userDetail.access_level === "agency"
          ) {
            this.setState({
              redirectToSystemArchitect: true,
              isLoading: false,
            });
          } else {
            this.props.history.push("/dashboard");
            // this.setState({ redirectToEmployeeDashBoard: true, isLoading: false });
          }
        } else if (userDetail.type.toLowerCase() === Roles.SALES_OFFICER_ROLE) {
          this.props.history.push("/point-dashboard");
        } else if (
          userDetail.type.toLowerCase() === Roles.HEAD_OFFICE_ROLE ||
          userDetail.type.toLowerCase() === Roles.PROJECT_MANAGER_ROLE
        ) {
          this.setState({ redirectToHoDashboard: true, isLoading: false });
        } else if (userDetail.type.toLowerCase() === Roles.SUPER_ADMIN_ROLE) {
          this.setState({ redirectToNotification: true, isLoading: false });
        } else if (
          userDetail.type.toLowerCase() === Roles.SUB_DEALER_ROLE ||
          userDetail.type.toLowerCase() === Roles.BRANCH_ROLE ||
          (userDetail.customer_class &&
            (userDetail.customer_class.toLowerCase() === "na" ||
              userDetail.customer_class.toLowerCase() === "hy"))
        ) {
          if (userDetail.is_login_first_time === true) {
            this.props.history.push("/on-boarding");
          } else {
            this.props.history.push("/warranty-score-card");
          }
        } else if (userDetail.type.toLowerCase() === Roles.MARKETING_ROLE) {
          this.props.history.push("/set-notification");
        } else if (userDetail.type.toLowerCase() === Roles.SUPPORT_ROLE) {
          this.props.history.push("/merchandise-orders");
        } else if (userDetail.type.toLowerCase() === Roles.ACCOUNTANT_ROLE) {
          this.props.history.push("/order-history-ho");
        } else if (userDetail.type.toLowerCase() === Roles.FINANCE_ROLE) {
          this.props.history.push("/special-approvals");
        } else if (userDetail.type.toLowerCase() === Roles.VENDOR_ROLE) {
          this.props.history.push("/retail-marketing-system");
        } else if (userDetail.type.toLowerCase() === Roles.BRAND_STORE_ROLE) {
          this.props.history.push("/proposal-dashboard");
        } else if (userDetail.type.toLowerCase() === Roles.DEALER_ROLE) {
          this.props.history.push("/dealer-dashboard");
        }

        if (userDetail.is_login_first_time === true) {
          this.setState({ redirectToOnBoarding: true, isLoading: false });
        } else {
          if (
            userDetail.permissions &&
            userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
          ) {
            this.setState({
              redirectToNewDealerDashBoard: true,
              isLoading: false,
            });
          } else {
            this.setState({ redirectToDashBoard: true, isLoading: false });
          }
        }
        // this.getUserDetail();
      })
      .catch((e) => {
        const err =
          e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : ERROR_MESSAGE;

        // ModalService.openAlert('Login', message, 'error');
        this.setState({
          errResponseMessage: message,
          isLoading: false,
          //  errorMessage: message, isErrorModalOpen: true
        });
      });
    // this.refreshPage();
    localStorage.setItem("serearchQuery", "");
    localStorage.setItem("pageNumber", 1);
  };

  getUserDetail = () => {
    UserService.getUserInfo()
      .then((data) => {
        const userData = data.data.data;
        this.props.setUserDetail(userData);

        // uncomment this if block

        if (userData.is_login_first_time === true) {
          this.setState({ redirectToOnBoarding: true });
        } else {
          if (
            userData.customer_class.toLowerCase() === "sw" ||
            userData.customer_class.toLowerCase() === "xw"
          ) {
            this.setState({ redirectToNewDealerDashBoard: true });
          } else {
            this.setState({ redirectToDashBoard: true });
          }
        }
        // this.refreshPage();
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  // forgotPassword = () => {
  //   AuthService.forgotPassword()
  // }

  handleValidation = () => {
    let { controls, isFormValid } = this.state;
    let { username, password } = controls;

    username = Validation.notNullValidator(username);
    username.valid = !username.nullValue;

    password = Validation.notNullValidator(password);
    password.valid = !password.nullValue;

    if (username.valid === true && password.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });

    return isFormValid;
  };

  resizeWindow = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  openForgotPasswordPage = () => {
    this.setState({ redirectToForgotPasswordPage: true });
  };

  openErrorModal = () => {
    this.setState({ isErrorModalOpen: true });
  };

  closeErrorModal = () => {
    this.setState({ isErrorModalOpen: false });
  };

  setKeepMeLoggedIn = (value) => {
    Storage.setKeepMeLoggedInCookie(value);
  };

  navBarToHide = async () => {
    await DashboardService.navBarToHide()
      .then((data) => {
        this.setState({
          navBarToHide: data.data.data.menu,
        });
        Storage.setHideNavBar(data.data.data.menu);
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  render() {
    const {
      controls,
      redirectToOnBoarding,
      redirectToDashBoard,
      redirectToHoDashboard,
      redirectToNotification,
      redirectToEmployeeDashBoard,
      redirectToForgotPasswordPage,
      errResponseMessage,
      redirectToSystemArchitect,
      redirectToNewDealerDashBoard,
    } = this.state;
    const { username, password, keepMeLoggenIn } = controls;
    return (
      <div id="login">
        {this.state.isLoading && <CustomSpinner />}

        {/* <Container> */}
        {redirectToHoDashboard && <Redirect to="/dashboard" />}
        {redirectToOnBoarding && <Redirect to="/on-boarding" />}
        {/* HIDE COde */}
        {/* {redirectToNewDealerDashBoard && <Redirect to="/dealer-dashboard" />} */}
        {(redirectToDashBoard || redirectToNewDealerDashBoard) && (
          <Redirect to="/" />
        )}
        {redirectToEmployeeDashBoard && <Redirect to="/point-dashboard" />}
        {redirectToForgotPasswordPage && <Redirect to="/forgot-password" />}
        {redirectToForgotPasswordPage && <Redirect to="/forgot-password" />}
        {redirectToSystemArchitect && <Redirect to="/proposal-dashboard" />}
        {redirectToNotification && <Redirect to="/set-notification" />}
        <ConfirmAlert
          closeModal={this.closeErrorModal}
          // open={this.state.isErrorModalOpen}
          title="Login"
          error
          msg={this.state.errorMessage}
          show={this.state.isErrorModalOpen}
        />
        {/* <ErrorModal
          closeModal={this.closeErrorModal}
          // open={this.state.isErrorModalOpen}
          title="Login"
          msg={this.state.errorMessage}
          open={this.state.isErrorModalOpen}
        /> */}
        <Row>
          <Col
            xl="3"
            xs="1"
            md="1"
            className={this.state.width < 1200 ? "display-none" : ""}
          >
            <img className="login-left-img" src="images/login-img.jpg" alt="" />
          </Col>
          <Col xl="1" xs="1" md="1" />
          <Col xl="7" xs="10" md="10" className="login-container">
            <Form className="width-100">
              <Row
                className={this.state.width < 1200 ? "margin-top-20" : null}
              />
              <Row className="padding-left-13" style={{ marginBottom: "20px" }}>
                <img
                  src="images/logo.png"
                  alt="logo"
                  width="233px"
                  height="76px"
                  style={{ objectFit: "contain" }}
                />
              </Row>
              <Row className="welcome-text">Welcome</Row>
              <Row className="sign-in-subtitle-text margin-top-5">
                <div>Please sign in to your account</div>
              </Row>
              <hr />
              <div>
                {/* <div><Spinner /></div> */}
                <Row>
                  <Col xl="6" xs="12" md="12">
                    {/* <Row> */}
                    <FormGroup>
                      <Label for="username">User ID</Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        className="login-input"
                        value={username.value}
                        onChange={this.handleInputChange}
                      />
                      <div className="error-container">
                        {username.nullValue && (
                          <div className="error">* {EMPTY_USER_NAME}</div>
                        )}
                        {/* {!(errResponseMessage === null || errResponseMessage === "") && <div className="error">{errResponseMessage}</div>} */}
                      </div>
                    </FormGroup>
                    {/* </Row> */}
                  </Col>
                  <Col xl="6" xs="12" md="12">
                    {/* <Row> */}
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        className="login-input"
                        value={password.value}
                        onChange={this.handleInputChange}
                      />
                      <div className="error-container">
                        {password.nullValue && (
                          <div className="error">* {EMPTY_PASSWORD}</div>
                        )}
                      </div>
                    </FormGroup>
                    {/* </Row> */}
                  </Col>
                </Row>
                {!(
                  errResponseMessage === null || errResponseMessage === ""
                ) && (
                    <div
                      className="error margin-bottom-20"
                      style={{ marginTop: "-26px" }}
                    >
                      {errResponseMessage}
                    </div>
                  )}
                <Row className="padding-left-13">
                  <label className="container-cb">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      checked={keepMeLoggenIn}
                      onChange={this.setCheckBoxValue}
                    />{" "}
                    Keep me logged in
                    <span className="checkmark" />
                  </label>
                  {/* <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        checked={keepMeLoggenIn}
                        onChange={this.setCheckBoxValue}
                      />{' '}
                      Keep me logged in
                    </Label>
                  </FormGroup> */}
                </Row>
                <hr />
                <Row>
                  <Col
                    xl="4"
                    className={this.state.width < 1200 ? "display-none" : ""}
                  />
                  <Col xl="8" xs="12" md="12" className="text-align-right">
                    <Button
                      className="mb-1 mr-1 btn-pill action-button forgot-password"
                      color="dark"
                      onClick={this.openForgotPasswordPage}
                    >
                      Recover Password
                    </Button>
                    {"  "}
                    <Button
                      className="mb-1 mr-1 btn-pill action-button login-button"
                      color="warning"
                      onClick={this.login}
                      type="submit"
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>
          <Col xl="1" xs="1" sm="1" />
        </Row>
        {/* </Container> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
});

export default connect(null, mapDispatchToProps)(Login);
