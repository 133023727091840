import React, { Component, Fragment } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import Sidebar from "react-sidebar";
import Ionicon from "react-ionicons";
import { connect } from "react-redux";

// component
// import HODashboardCategoryWiseCard from "../../components/HODashboardCategoryWiseCard/HODashboardCategoryWiseCard";
// import HODashboardClassWiseCard from "../../components/HODashboardClassWiseCard/HODashboardClassWiseCard";
import HODashboardProductWiseCard from "../../components/HODashboardProductWiseCard/HODashboardProductWiseCard";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import AutoSuggestionDealer from "../../components/AutoSuggestionDealer/AutoSuggestionDealer";

// service
import HeadOfficeService from "../../services/HeadOfficeService";
import StorageService from "../../services/Storage";
import SpeacialEngagementService from "../../services/SpecialEngagementService";

// modal
import HoDashboardFilter from "../../modal/HoDashboardFilter/HoDashboardFilter";
import SpeacialEngagement from "../../modal/SpeacialEngagement/SpeacialEngagement";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";

// utils
import {
  findFirstQuarterToCurrentMonths,
  findCurrentQuarterMonths,
  getCurrentMonth,
  getYearString,
  getYearRangeString,
} from "../../utils/utils";

// css
import "./HeadOfficeDashboard.css";
import { askForPermissioToReceiveNotifications } from "../../push-notification";
import { Roles } from "../../constants/constant.role";

const date = new Date();
const currentYear = date.getFullYear();
const currentMonthNumber = date.getMonth() + 1;
let currentYearValue;
let nextYearValue;
let currentYearString = getYearString(currentYear.toString());
let nextYearString = getYearString((currentYear + 1).toString());
if (currentMonthNumber >= 1 && currentMonthNumber <= 3) {
  currentYearString = getYearString((currentYear - 1).toString());
  nextYearString = getYearString(currentYear.toString());
  currentYearValue = currentYear - 1;
  nextYearValue = currentYear + 1;
} else {
  // currentYearValue = currentYear - 1;
  currentYearValue = currentYear;
}

// const monthToFullMonthName = {
//   1: `January${nextYearString}`,
//   2: `February${nextYearString}`,
//   3: `March${nextYearString}`,
//   4: `April${currentYearString}`,
//   5: `May${currentYearString}`,
//   6: `June${currentYearString}`,
//   7: `July${currentYearString}`,
//   8: `August${currentYearString}`,
//   9: `September${currentYearString}`,
//   10: `October${currentYearString}`,
//   11: `November${currentYearString}`,
//   12: `December${currentYearString}`,
// }

const monthToFullMonthName = {
  1: `January`,
  2: `February`,
  3: `March`,
  4: `April`,
  5: `May`,
  6: `June`,
  7: `July`,
  8: `August`,
  9: `September`,
  10: `October`,
  11: `November`,
  12: `December`,
};

class HeadOfficeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offtakeAndCustomerCountByCategory: [],
      totalOfftakeByCustomeClass: [],
      totalOfftakeCategoryWise: [],
      totalOfftake: [],
      sidebarOpen: false,
      filterControls: {},
      navigation: [],
      selectedMonth: "all",
      selectedYear: currentYearValue,
      isCategoryWiseCardLoading: false,
      isClassWiseCardLoading: false,
      dealerRole: null,
      userDetailRedux: null,
      label: {},
      isSpecialEngagementModalOpen: false,
      isFilterApply: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.childHoDashboardFilter = React.createRef();
  }

  componentDidMount() {
    GoogleAnalticsService.event("Offtake Dashboard", "Offtake Dashboard View");
    askForPermissioToReceiveNotifications();
    let userDetailRedux = this.props.userDetail;
    this.setState({ userDetailRedux });
    this.getPopupLink();
    this.getHeadOfficeReport({});
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.setState({ dealerRole: type });
  }

  componentWillReceiveProps(props) {
    let userDetailRedux = props.userDetail;
    this.setState({ userDetailRedux });
  }

  getHeadOfficeReport = (obj, fromFilter = false) => {
    const { type = null, refName = null } = obj;
    this.setState({
      // isLoading: true,
      isCategoryWiseCardLoading: true,
      isClassWiseCardLoading: true,
    });
    HeadOfficeService.getHeadOfficeReport(
      type,
      refName,
      this.state.selectedMonth,
      this.state.selectedYear
    )
      .then((data) => {
        const {
          offtakeAndCustomerCountByCategory,
          totalOfftakeByCustomeClass,
          totalOfftakeCategoryWise,
          totalOfftake,
          totalDealers,
          label,
        } = data.data.data;
        if (type !== null && !fromFilter) {
          this.updateBreadCrums(obj);
        }
        this.setState({
          offtakeAndCustomerCountByCategory,
          totalOfftakeByCustomeClass,
          totalOfftakeCategoryWise,
          totalDealers,
          totalOfftake,
          label,
          // isLoading: false,
          isCategoryWiseCardLoading: false,
          isClassWiseCardLoading: false,
        });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({
          // isLoading: false,
          isCategoryWiseCardLoading: false,
          isClassWiseCardLoading: false,
        });
      });
  };

  applyFilter = (controls) => {
    let isFilterApply = null;
    if (controls && controls.isFilterReset === true) {
      isFilterApply = false;
    } else {
      isFilterApply = true;
    }
    const mapZoneToZoneName = {
      nz: "North Zone",
      sz1: "South Zone 1",
      sz2: "South Zone 2",
      wz: "West Zone",
      ez: "East Zone",
      cz: "Central Zone",
    };
    let obj = {
      type: null,
      refName: null,
    };
    let type = null;
    let refName = null;
    let selectedMonth = this.state.selectedMonth;
    let selectedYear = this.state.selectedYear;
    this.onCloseSidebar();
    if (controls.area.value) {
      type = "area";
      refName = controls.area.value;
      // this.getHeadOfficeReport({ type: 'area', refName: controls.area.value }, true);
    } else if (controls.region.value) {
      type = "region";
      refName = controls.region.value;
      // this.getHeadOfficeReport({ type: 'region', refName: controls.region.value }, true);
    } else if (controls.zone.value) {
      type = "zone";
      refName = controls.zone.value;
      // this.getHeadOfficeReport({ type: 'zone', refName: controls.zone.value }, true);
    } else {
      // this.getHeadOfficeReport({});
    }

    if (type && refName) {
      obj.type = type;
      obj.refName = refName;
    } else {
      obj = {};
    }

    if (controls.month.value) {
      selectedMonth = controls.month.value;
      // this.setState({ selectedMonth: controls.month.value }, () => {
      //   this.getHeadOfficeReport({ type: 'area', refName: controls.area.value }, true);
      // });
    }

    if (controls.year.value) {
      selectedYear = controls.year.value;
      // this.setState({ selectedMonth: controls.month.value }, () => {
      //   this.getHeadOfficeReport({ type: 'area', refName: controls.area.value }, true);
      // });
    }
    const navigation = [];
    if (controls.zone.value) {
      const navObj = {
        name: mapZoneToZoneName[controls.zone.value],
        type: "zone",
        ref: controls.zone.value,
      };
      navigation.push(navObj);
    }

    if (controls.region.value) {
      const navObj = {
        name: controls.region.value,
        type: "region",
        ref: controls.region.value,
      };
      navigation.push(navObj);
    }

    if (controls.area.value) {
      const navObj = {
        name: controls.area.value,
        type: "area",
        ref: controls.area.value,
      };
      navigation.push(navObj);
    }
    this.setState(
      {
        filterControls: controls,
        navigation,
        selectedMonth,
        selectedYear,
        isFilterApply,
      },
      () => {
        this.getHeadOfficeReport(obj, true);
      }
    );
  };

  updateBreadCrums = (obj) => {
    const navigation = this.state.navigation;
    const navObj = {
      name: obj.dealerName,
      ref: obj.refName,
      type: obj.type,
    };
    navigation.push(navObj);
    this.setState({ navigation });
  };

  handleOnClickBreadCrum = (index) => {
    let navigation = this.state.navigation;
    const currentData = navigation[index];
    const obj = {
      refName: currentData.ref,
      type: currentData.type,
    };
    this.getHeadOfficeReport(obj, true);
    navigation = navigation.splice(0, index + 1);
    this.setState({ navigation });
  };

  setCheckBoxValue = () => {
    // const { controls } = this.state;
    // controls.keepMeLoggenIn = !controls.keepMeLoggenIn;
    // this.setState({ controls });
  };

  onCloseSidebar = () => {
    document.body.style.overflow = "auto";
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: false };
    });
  };

  onSetSidebarOpen(open) {
    document.body.style.overflow = "hidden";
    if (this.childHoDashboardFilter.current) {
      this.childHoDashboardFilter.current.setFilter(this.state.filterControls);
    }
    this.setState((prevState) => {
      return { ...prevState, sidebarOpen: open };
    });
    // setTimeout(() => {
    //   if (Object.keys(this.refs).length > 0 && this.refs && this.refs.childAddEditSubsidiaries) {
    //     this.refs.childAddEditSubsidiaries.setValue();
    //   }
    // }, 1);
  }

  openAllIndiaReport = () => {
    const controls = this.state.filterControls;
    let controlsToSet = {};
    if (controls) {
      if (controls.hasOwnProperty("month")) {
        controlsToSet["month"] = controls["month"];
      }
      if (controls.hasOwnProperty("year")) {
        controlsToSet["year"] = controls["year"];
      }
    }
    this.setState({ navigation: [], filterControls: controlsToSet });
    this.getHeadOfficeReport({}, true);
    if (this.childHoDashboardFilter.current) {
      this.childHoDashboardFilter.current.setFilter(controlsToSet);
    }
  };

  getPageTitle = () => {
    const { dealerRole, userDetailRedux } = this.state;

    if (userDetailRedux) {
      if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
        return "ALL INDIA";
      } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
        return userDetailRedux.area_name;
      } else if (dealerRole === Roles.ZTM_ROLE) {
        return userDetailRedux.area_name;
      } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
        return userDetailRedux.area_name;
      } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
        return "Sales Officer";
      } else {
        // return 'Area Manager'
        return userDetailRedux.area_name;
      }
    }
  };

  closeSpecialEngagementModal = () => {
    this.setState({ popupData: null, isSpecialEngagementModalOpen: false });
  };

  getPopupLink = () => {
    SpeacialEngagementService.getPopupLink()
      .then((data) => {
        if (data.data && data.data.data.link) {
          this.setState({ isSpecialEngagementModalOpen: true });
        }
      })
      .catch((e) => { });
  };

  render() {
    const {
      offtakeAndCustomerCountByCategory,
      // totalOfftakeByCustomeClass,
      isSpecialEngagementModalOpen,
      // totalOfftakeCategoryWise,
      // totalOfftake,
      totalDealers,
      label,
      selectedYear,
      navigation: navigationObj,
      selectedMonth,
      isCategoryWiseCardLoading,
      isClassWiseCardLoading,
    } = this.state;
    let date = new Date();
    const pageTitle = this.getPageTitle();
    const yearString = getYearRangeString(selectedYear);
    const currentDateMonth = date.getMonth() + 1;
    if (
      !(this.state.selectedMonth === "all" || this.state.selectedMonth === null)
    ) {
      date.setMonth(this.state.selectedMonth - 1);
      if (
        currentDateMonth >= 1 &&
        currentDateMonth <= 3 &&
        selectedMonth <= 12 &&
        selectedMonth >= 4
      ) {
        const year = date.getFullYear() - 1;
        date.setFullYear(year);
      } else if (
        selectedMonth >= 1 &&
        selectedMonth <= 3 &&
        currentDateMonth >= 4 &&
        currentDateMonth <= 12
      ) {
        const year = date.getFullYear() + 1;
        date.setFullYear(year);
      }
    }

    const findFirstQuarterMonthsToCurrentMonths = findFirstQuarterToCurrentMonths(
      date
    );
    const currentQuarterMonths = findCurrentQuarterMonths(date);
    const currentMonth = getCurrentMonth(date);
    let currentNav = null;
    let isArea = false;
    if (navigationObj.length > 0) {
      let length = navigationObj.length;
      let nav = navigationObj[length - 1];
      if (nav.type === "area") {
        isArea = true;
      }
      currentNav = navigationObj[length - 1];
    }
    const length = navigationObj.length;
    const navigation = navigationObj.map((m, index) => (
      <Fragment>
        <span>
          <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
        </span>
        <span
          className={
            length - 1 !== index
              ? "prev-page-link cursor-pointer title-case"
              : "title-case"
          }
          onClick={
            length - 1 !== index
              ? this.handleOnClickBreadCrum.bind(this, index)
              : null
          }
        >
          {m.name}
        </span>
      </Fragment>
    ));

    return (
      <Fragment>
        {isSpecialEngagementModalOpen && (
          <SpeacialEngagement
            show={isSpecialEngagementModalOpen}
            // popupDetail={popupDetail}
            closeModal={this.closeSpecialEngagementModal}
          ></SpeacialEngagement>
        )}
        {this.state.isCategoryWiseCardLoading && (
          <CustomSpinner></CustomSpinner>
        )}
        <div id="ho-dashboard">
          {true && (
            <Sidebar
              sidebar={
                <>
                  <div className="drawer-header">
                    <div>
                      <span className="drawer-title">Filters</span>&nbsp;&nbsp;
                      <img src="/images/filter.svg" alt="" />
                    </div>
                    <img
                      className="cursor-pointer"
                      src="images/close.svg"
                      alt=""
                      onClick={() => {
                        this.onCloseSidebar();
                      }}
                    />
                  </div>
                  <hr style={{ border: "solid 1px #ced4da" }} />
                  <HoDashboardFilter
                    applyFilter={this.applyFilter}
                    navigation={this.state.navigation}
                    ref={this.childHoDashboardFilter}
                  />
                </>
              }
              open={this.state.sidebarOpen}
              sidebarClassName="ho-filter-sidebar"
              pullRight
              styles={{ sidebar: { background: "white", height: "100%" } }}
            />
          )}
          {/* <HoDashboardFilter show={true} closeModal={null}></HoDashboardFilter> */}
          <Row>
            <Col>
              <div className="page-title">Dashboard</div>
            </Col>
            <br />
            <br />
            <br />
            <Col className="text-align-right">
              <Row>
                <Col style={{ marginTop: "8px" }}>
                  <span
                    className="filter-title cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.onSetSidebarOpen(true);
                    }}
                  >
                    Filter
                  </span>
                  &nbsp;
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      this.onSetSidebarOpen(true);
                    }}
                    src={
                      this.state.isFilterApply
                        ? "/images/filter_applied.svg"
                        : "/images/filter.svg"
                    }
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                  &nbsp;&nbsp;
                </Col>
                <Col md="7" xs="12" style={{ paddingLeft: "0" }}>
                  <div>
                    <AutoSuggestionDealer
                      {...this.props}
                      from="ho-dashboard"
                    ></AutoSuggestionDealer>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Card>
            <CardBody>
              <Row className="margin-bottom-20" style={{ marginLeft: "-25px" }}>
                <Col className="title title-case">
                  <span
                    className={
                      navigationObj.length > 0
                        ? "prev-page-link cursor-pointer title-case"
                        : "title-case"
                    }
                    onClick={
                      navigationObj.length > 0 ? this.openAllIndiaReport : null
                    }
                  >
                    {pageTitle}
                  </span>
                  {navigation}
                </Col>
                <Col className="title text-align-right">
                  {!(
                    this.state.selectedMonth === "all" ||
                    (currentMonthNumber.toString() ===
                      this.state.selectedMonth &&
                      currentYear.toString() === selectedYear)
                  ) && (
                    <div>
                      Till {monthToFullMonthName[this.state.selectedMonth]} (FY{" "}
                      {yearString})
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ padding: "4px" }}>
                  <HODashboardCategoryWiseCard
                    offtake={totalOfftakeCategoryWise}
                    totalOfftake={totalOfftake}
                    totalDealers={totalDealers}
                    findFirstQuarterMonthsToCurrentMonths={
                      findFirstQuarterMonthsToCurrentMonths
                    }
                    currentQuarterMonths={currentQuarterMonths}
                    currentMonth={currentMonth}
                    isLoading={isCategoryWiseCardLoading}
                    label={label}
                  ></HODashboardCategoryWiseCard>
                </Col>
                <Col sm="6" style={{ padding: "4px" }}>
                  <HODashboardClassWiseCard
                    offtake={totalOfftakeByCustomeClass}
                    totalOfftake={totalOfftake}
                    totalDealers={totalDealers}
                    findFirstQuarterMonthsToCurrentMonths={
                      findFirstQuarterMonthsToCurrentMonths
                    }
                    currentQuarterMonths={currentQuarterMonths}
                    currentMonth={currentMonth}
                    currentNav={currentNav}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    label={label}
                    isLoading={isClassWiseCardLoading}
                  ></HODashboardClassWiseCard>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          {/* <Row> */}
          <Card className="mt-3">
            <CardBody>
              <Row className="margin-bottom-20" style={{ marginLeft: "-25px" }}>
                <Col className="title title-case">
                  <span
                    className={
                      navigationObj.length > 0
                        ? "prev-page-link cursor-pointer title-case"
                        : "title-case"
                    }
                    onClick={
                      navigationObj.length > 0 ? this.openAllIndiaReport : null
                    }
                  >
                    {pageTitle}
                  </span>
                  {navigation}
                </Col>
                <Col className="title text-align-right">
                  {!(
                    this.state.selectedMonth === "all" ||
                    (currentMonthNumber.toString() ===
                      this.state.selectedMonth &&
                      currentYear.toString() === selectedYear)
                  ) && (
                      <div>
                        Till {monthToFullMonthName[this.state.selectedMonth]} (FY{" "}
                        {yearString})
                      </div>
                    )}
                </Col>
              </Row>
              <HODashboardProductWiseCard
                offtakeAndCustomerCountByCategory={
                  offtakeAndCustomerCountByCategory
                }
                getHeadOfficeReport={this.getHeadOfficeReport}
                totalDealers={totalDealers}
                findFirstQuarterMonthsToCurrentMonths={
                  findFirstQuarterMonthsToCurrentMonths
                }
                currentQuarterMonths={currentQuarterMonths}
                currentMonth={currentMonth}
                isArea={isArea}
                currentNav={currentNav}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                isClassWiseCardLoading={isClassWiseCardLoading}
                isCategoryWiseCardLoading={isCategoryWiseCardLoading}
                label={label}
              ></HODashboardProductWiseCard>
            </CardBody>
          </Card>
        </div>
        {/* </Row> */}
      </Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  userDetail: state.User.user,
});

export default connect(mapStateToProp, null)(HeadOfficeDashboard);
// export default HeadOfficeDashboard;
