import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
} from "reactstrap";
import Pagination from "../../components/Pagination/Pagination";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { downlodFile } from "../../utils/utils";
import lodash from "lodash"; // library added for debounce functionality
import Sidebar from "react-sidebar";
import CampaignService from "../../services/CampaignService";
import OrderHistoryFilterHO from "../../components/OrderHistoryFilter/OrderHistoryFilterHO";
import './OrderHistoryHo.css';
import OrderDetail from "../../components/OrderHistoryFilter/OrderDetail";
import Storage from "../../services/Storage";
import ModalService from "../../services/ModalService";
import { Roles } from "../../constants/constant.role";
const pageSize = 10;

class OrderHistoryHo extends Component {
  state = {
    page: 1,
    totalPages: 0,
    orderData: [],
    totalOrderCounts: 0,
    filterApplied: false,
    openCustomerManagementFilter: false,
    pageSize: 10,
    controls_filter: {},
    userDetail: Storage.getUserDetail()
  };

  constructor(props) {
    super(props);
    this.searchReport = lodash.debounce(this.searchReport, 500);
  }

  componentDidMount() {
    this.getOrderHistory(false);
  }
  searchReport = (search) => {
    this.setState({
      search: search
    }, () => {
      this.getOrderHistory();
    })
  };

  handlePageChange = (page) => {
    this.setState({ page: page }, () => {
      this.getOrderHistory(false);
    });
  };

  getFilterData = () => {
    let reqData = {};
    let { controls_filter } = this.state;
    if (controls_filter.product.value) {
      reqData.product = controls_filter.product.value;
    }
    if (controls_filter.productQty.value) {
      reqData.productQty = controls_filter.productQty.value;
    }
    if (controls_filter.orderStartDate.value) {
      let date = new Date(controls_filter.orderStartDate.value);
      date.setHours(0, 0, 0, 0);
      reqData.startDate = date.toISOString();
    }
    if (controls_filter.orderEndDate.value) {
      let date = new Date(controls_filter.orderEndDate.value);
      date.setHours(23, 59, 59, 59);
      reqData.endDate = date.toISOString();
    }
    if (controls_filter.pointStart.value) {
      reqData.pointStart = controls_filter.pointStart.value;
    }
    if (controls_filter.pointEnd.value) {
      reqData.pointEnd = controls_filter.pointEnd.value;
    }
    if (controls_filter.zone.value) {
      reqData.zone = controls_filter.zone.value;
    }
    if (controls_filter.region.value) {
      reqData.region = controls_filter.region.value;
    }
    if (controls_filter.area.value) {
      reqData.area = controls_filter.area.value;
    }
    if (controls_filter.paymentMode.value && controls_filter.paymentMode.value !== 'all') {
      reqData.paymentMode = controls_filter.paymentMode.value;
    }
    return reqData;
  }
  downloadOrderInvoice = (id) => {
    if (!id) {
      return
    }
    this.setState({ isLoading: true });
    CampaignService.downloadOrderInvoice({ orderId: id })
      .then((data) => {
        let { file } = data.data.data;
        if (file) {
          downlodFile(file);
        } else {
          ModalService.openAlert("", 'Not have invoice', "error");
        }
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }
  getOrderHistory(downloadReport = null) {
    this.setState({ isLoading: true });
    let reqData = {
      page: this.state.page,
      limit: this.state.pageSize,
      q: this.state.search
    }
    if (downloadReport) {
      reqData.export = true;
    }
    let { controls_filter } = this.state;

    if (controls_filter && Object.keys(controls_filter).length > 0) {
      reqData = { ...reqData, ...this.getFilterData() };
    }
    CampaignService.getOrderHistory(reqData)
      .then((data) => {
        this.setState({ isLoading: false });
        if (downloadReport === true) {
          let { file } = data.data.data;
          downlodFile(file);
          return;
        }
        const { orders, count } = data.data.data;
        const orderData = orders;
        const totalOrderCounts = count;
        const totalPages = Math.ceil(totalOrderCounts / pageSize);
        this.setState({ orderData, totalOrderCounts, totalPages });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  downloadReport = () => {
    this.getOrderHistory(true);
  };

  handleSearchChange = (e) => {
    let searchVal = e.target.value;
    this.setState({ search: searchVal, page: 1 });
    this.searchReport(searchVal);
  };

  applyDealerFilter = (controls = {}) => {
    let { controls_filter, filterApplied } = this.state;
    if (controls && Object.keys(controls).length > 0) {
      controls_filter = Object.assign({}, controls);
      filterApplied = true;
    } else {
      controls_filter = {};
      filterApplied = false;
    }
    this.setState({
      controls_filter,
      filterApplied,
      openCustomerManagementFilter: false,
      page: 1,
      search: ''
    }, () => {
      this.getOrderHistory()
    })
  }

  closeOrderHistoryFilterDealer = () => {
    this.setState({
      openCustomerManagementFilter: false
    })
  }

  openCustomerManagementFilter = () => {
    this.setState({
      openCustomerManagementFilter: true
    })
  }

  openOrderDetail = (item) => {
    this.refs.orderDetail.toggle(item);
  }
  render() {
    const {
      orderData,
      search,
      totalOrderCounts,
      totalPages,
      userDetail
    } = this.state;
    let prepareRow = '';
    if (userDetail && userDetail.type === Roles.HEAD_OFFICE_ROLE) {
      prepareRow = orderData.map((w, index) => (
        <tr>
          <td style={{ width: '108px' }}>{w.Date}</td>
          <td>{w['Order ID'] ? w['Order ID'] : '--'}</td>
          <td>{w['Product Name'] ? w['Product Name'] : '--'}</td>
          <td>{w['Product Qty'] ? w['Product Qty'] : '--'}</td>
          <td>{w['Points redemption'] ? w['Points redemption'] : '--'}</td>
          <td>{w['SAP ID'] ? w['SAP ID'] : '--'}</td>
          <td>{w['Dealer Name'] ? w['Dealer Name'] : '--'}</td>
          <td>{w['Owner Name'] ? w['Owner Name'] : '--'}</td>
          <td>{w['Mode Of Payment'] ? w['Mode Of Payment'] : '--'}</td>
          <td>{w['Area'] ? w['Area'] : '--'}</td>
          <td>{w['Region'] ? w['Region'] : '--'}</td>
          <td className="text-capitalize">{w['Zone'] ? w['Zone'] : '--'}</td>
        </tr>
      ));
    } else {
      prepareRow = orderData.map((w, index) => (
        <tr>
          <td style={{ width: '108px' }}>{w.Date ? w.Date : '--'}</td>
          <td className="link-blue cursor-pointer" onClick={() => this.openOrderDetail(w)}>{w['Order ID'] ? w['Order ID'] : '--'}</td>
          <td className="text-align-center">{w['Dealer SAP ID'] ? w['Dealer SAP ID'] : '--'}</td>
          <td>{w['Product Name'] ? w['Product Name'] : '--'}</td>
          <td className="text-align-center">{w['Product Qty'] ? w['Product Qty'] : '--'}</td>
          <td className="text-align-center">{w['Total amount'] ? w['Total amount'] : '--'}</td>
          <td className="text-align-center">{w['Points redemption'] ? w['Points redemption'] : '--'}</td>
          <td>{w['Mode Of Payment'] ? w['Mode Of Payment'] : '--'}</td>
          <td>--</td>
          <td style={{ width: '108px' }}>{w['Payment Received date'] ? w['Payment Received date'] : '--'}</td>
          <td>
            {
              w['Invoice No.'] ?
                <span className="link-blue cursor-pointer" onClick={() => this.downloadOrderInvoice(w['Order ID'] ? w['Order ID'] : null)}>
                  {w['Invoice No.']}
                </span>
                : '--'}</td>
        </tr>
      ));
    }
    return (
      <Fragment>
        <span className="header-title">Order History</span>
        {this.state.isLoading && <CustomSpinner></CustomSpinner>}
        {this.state.openCustomerManagementFilter && (
          <Sidebar
            sidebar={
              <>
                <OrderHistoryFilterHO
                  applyDealerFilter={this.applyDealerFilter}
                  controls_filter={this.state.controls_filter}
                  closeOrderHistoryFilterDealer={this.closeOrderHistoryFilterDealer} />
              </>
            }
            open={true}
            sidebarClassName="campaign-drawer"
            pullRight
            styles={{
              sidebar: {
                background: "white",
                height: "90%",
                top: "60px",
                width: "36%",
                borderRadius: "2px",
              },
            }}
          />
        )}
        <div id="order-history" style={{ marginTop: "20px" }}>
          <Card>
            <CardBody className="overflow-auto">
              <Row >
                <Col xs="12" className="mb-3">
                  <Row className="m-0 justify-content-between">
                    <div className="d-flex" style={{ width: '37%' }}>
                      <Input
                        placeholder="Search by Invoice Number, OrderId, Item Description"
                        className="report-search-input"
                        onChange={this.handleSearchChange}
                        style={{ paddingRight: '30px' }}
                        value={search}
                      ></Input>
                      <img src="images/search.svg"
                        alt=""
                        className="icon-width"
                        style={{ marginLeft: '-28px' }} />

                      <img
                        src={this.state.filterApplied === true ? "images/smaller-blue-dot-hi.png" : "images/filter.svg"}
                        alt=""
                        className={`cursor-pointer ${this.state.filterApplied === true
                          ? 'filter-icon-selected mt-auto mb-auto'
                          : 'filter-icon mt-auto mb-auto'}`
                        }
                        style={{ marginLeft: '30px' }}
                        onClick={() => this.openCustomerManagementFilter()}
                      />
                    </div>
                    {orderData.length > 0 &&
                      (
                        <span
                          className="download-report cursor-pointer mt-auto mb-auto"
                          onClick={this.downloadReport}
                        >
                          {" "}
                          Download Orders
                        </span>
                      )}
                  </Row>
                </Col>
                <Col>
                  <Table>
                    {
                      (userDetail && userDetail.type === Roles.HEAD_OFFICE_ROLE) &&
                      <thead>
                        <tr>
                          <th>Order Date</th>
                          <th>Order ID</th>
                          <th>Item Description</th>
                          <th>Qty</th>
                          <th>Point Redeemed</th>
                          <th>SAP ID</th>
                          <th>Dealer Name</th>
                          <th>Owner Name</th>
                          <th>Classification</th>
                          <th>Territory</th>
                          <th>Region</th>
                          <th>Zone</th>
                        </tr>
                      </thead>
                    }
                    {
                      (userDetail && userDetail.type !== Roles.HEAD_OFFICE_ROLE) &&
                      <thead>
                        <tr>
                          <th>Order Date</th>
                          <th>Order ID</th>
                          <th className="text-align-center">SAP ID</th>
                          <th>Item Description</th>
                          <th className="text-align-center">Qty</th>
                          <th className="text-align-center">Amount(INR)</th>
                          <th className="text-align-center">Point Redeemed</th>
                          <th>Payment Mode</th>
                          <th>Payment Status</th>
                          <th>Payment Date</th>
                          <th>Invioce Number</th>
                        </tr>
                      </thead>
                    }
                    <tbody>{prepareRow}</tbody>
                  </Table>
                  {orderData.length === 0 && (
                    <div className="text-align-center card-point-header">
                      {
                        this.state.filterApplied ? 'There is no order found with applied filter. Please change filter criteria.'
                          : 'Currently, there are not any order to display.'
                      }
                    </div>
                  )}
                  {totalPages > 1 && (
                    <Pagination
                      margin={2}
                      page={this.state.page}
                      count={totalPages}
                      pageSize={pageSize}
                      totalRecords={totalOrderCounts}
                      onPageChange={this.handlePageChange}
                    ></Pagination>
                  )}

                  {orderData.length === 0 && (
                    <div className="text-align-center card-point-header"></div>
                  )}

                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <OrderDetail
          ref="orderDetail" />
      </Fragment>
    )
  }
}
export default OrderHistoryHo;