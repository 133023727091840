export const colorsArr = [
  '#8884d8', '#82ca9d', '#FF5733', '#33FF57', '#5733FF', '#FF33E9', '#33FFD6', '#FFD633', '#E933FF', '#FF8C33', '#338CFF', '#8C33FF',
  '#FF3333', '#33FF33', '#3333FF', '#FFFF33', '#FF33FF', '#33FFFF',
  '#CC3333', '#33CC33', '#3333CC', '#CCCC33', '#CC33CC', '#33CCCC',
  '#993333', '#339933', '#333399', '#999933', '#993399', '#339999',
  '#663333', '#336633', '#333366', '#666633', '#663366', '#336666',
  '#FF6633', '#33FF66', '#6633FF', '#FFFF66', '#FF66FF', '#66FFFF',
  '#CC6633', '#33CC66', '#6633CC', '#CCCC66', '#CC66CC', '#66CCCC',
  '#996633', '#339966', '#663399', '#999966', '#996699', '#669999',
  '#666633', '#336666', '#663366', '#666666', '#666699', '#669966',
  '#FF9933', '#33FF99', '#9933FF', '#FFFF99', '#FF99FF', '#99FFFF',
  '#CC9933', '#33CC99', '#9933CC', '#CCCC99', '#CC99CC', '#99CCCC',
  '#999933', '#339999', '#993399', '#999999', '#9999CC', '#99CC99',
  '#FFCC33', '#33FFCC', '#CC33FF', '#FFFFCC', '#FFCCFF', '#CCFFFF',
  '#CCCC33', '#33CCCC', '#CC33CC', '#CCCCCC', '#CCCCFF', '#CCFFCC',
  '#999966', '#669999', '#996699', '#CCCCCC', '#CCCCFF', '#CCFFCC',
  '#FFCC66', '#66FFCC', '#CC66FF', '#FFFFCC', '#FFCCFF', '#CCFFFF',
  '#CCCC66', '#66CCCC', '#CC66CC', '#CCCCCC', '#CCCCFF', '#CCFFCC',
  '#996666', '#669966', '#666699', '#CCCCCC', '#CCCCFF', '#CCFFCC',
  '#FF9966', '#66FF99', '#9966FF', '#FFFF99', '#FF99FF', '#99FFFF',
  '#CC9966', '#66CC99', '#9966CC', '#CCCC99', '#CC99CC', '#99CCCC'
];

// const colorsArr = [
//   '#8884d8',
//   '#82ca9d',
//   '#FF5733', // Red
//   '#33FF57', // Green
//   '#5733FF', // Blue
//   '#FF33E9', // Pink
//   '#33FFD6', // Cyan
//   '#FFD633', // Yellow
//   '#E933FF', // Purple
//   '#FF8C33', // Orange
//   '#338CFF', // Light Blue
//   '#8C33FF'  // Lavender
// ];