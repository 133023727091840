import React, { useCallback, useEffect, useState } from "react";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import ReactTooltip from "react-tooltip";
import { Input, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import RetailMarketingSystemService from "../../../services/RetailMarketingSystemService";
import Pagination from "../../Pagination/Pagination";
import "./RetailMarketingHOList.css";
import { Redirect } from "react-router-dom";
import lodash from "lodash";
import {
  getFilterAppliedStatus,
  getFilterData,
  getRecceStatus,
  getRetailMarketingStoreInformationState,
} from "../selector";
import {
  resetStoreDetailControls,
  setFilterAppliedStatus,
  setRecceStatus,
  setRecceStatusCount,
  setRecceStatusCountFilter,
  setVendorScreen,
  resetfilterData,
} from "../../../actions/RetailManagementSystemAction";
import Sidebar from "react-sidebar";
import RetailMarketingSignageInformation from "../RetailMarketingSignageInformation/RetailMarketingSignageInformation";

function RetailMarketingHOList({
  resetStoreDetailControlsAction,
  resetfilterDataAction,
  setFilterAppliedStatusAction,
  storeDetailControls,
  role,
  openRecceFilter,
  status_data = "all",
  recceStatus,
  setRecceStatusAction,
  setRecceStatusCountAction,
  setRecceStatusCountFilterAction,
  setVendorScreenAction,
  filterData,
  filterAppliedStatus,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [retailListData, setRetailListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRetailCounts, setTotalRetailCounts] = useState(0);
  const [redirectToAddEditRecce, setRedirectToAddEditRecce] = useState(false);
  const [redirectToViewRecce, setRedirectToViewRecce] = useState(false);
  const [
    redirectToCompleteInstallation,
    setRedirectToCompleteInstallation,
  ] = useState(false);
  const [retailDetail, setRetailDetail] = useState({});
  const [hoInstallation, setHoInstallation] = useState(false);
  const [
    vendorInstallationModification,
    setVendorInstallationModification,
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const delayedQuery = useCallback(
    lodash.debounce((q) => searchData(q), 500),
    []
  );
  const [
    isFirsTimeLoadPageDataNotFound,
    setIsFirsTimeLoadPageDataNotFound,
  ] = useState(true);
  const [vendorViewOnly, setVendorViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isInstallationApproved, setIsInstallationApproved] = useState(false);
  const [ho, setHo] = useState(false);
  const [vendor, setVendor] = useState(false);
  // const [vendorRemark, setVendorRemark] = useState("");
  const [openSignageSidebar, setOpenSignageSidebar] = useState(false);

  const serearchQuery = localStorage.getItem("serearchQuery");
  const pageNumber = localStorage.getItem("pageNumber");

  // const [title,setTitle]  = useState();
  // const toggle = (row = "") => {
  //   setVendorRemark(row.vendor_comment);
  // };
  // Handle Search Query

  const handleSearchChange = (e) => {
    setPage(1);
    localStorage.setItem("serearchQuery", e.target.value);
    localStorage.setItem("pageNumber", 1);
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  useEffect(() => {
    resetStoreDetailControlsAction(storeDetailControls);
    resetfilterDataAction(filterData);
    setFilterAppliedStatusAction(false);
  }, []);
  useEffect(() => {
    getAllMiniRetail();
    setPage(Number(pageNumber) ? Number(pageNumber) : 1);
  }, [page * pageSize, pageSize, recceStatus, filterData]);

  useEffect(() => {
    getAllMiniRetail();
    setPageSize(10);
    setPage(Number(pageNumber) ? Number(pageNumber) : 1);
  }, [searchQuery, recceStatus, serearchQuery]);

  const searchData = (search) => {
    setSearchQuery(search);
  };

  const onCloseSidebar = () => {
    document.body.style.overflow = "auto";
    setOpenSignageSidebar(false);
    resetStoreDetailControlsAction(storeDetailControls);
    localStorage.setItem("serearchQuery", "");
  };

  // Downlad Report
  const downloadReport = () => {
    setIsLoading(true);
    let reqData = {
      page: pageNumber ? pageNumber : page,
      pageSize,
      q: serearchQuery ? serearchQuery : searchQuery,
      vendor_id: filterData.vendor_id.value,
      zone_id: filterData.zone_id.value.toString(),
      region_id: filterData.region_id.value.toString(),
      area_id: filterData.area_id.value.toString(),
      state_id: filterData.state_id.value.toString(),
      is_download: true,
    };
    RetailMarketingSystemService.getMiniRetailHODetail(reqData)
      .then((data) => {
        if (data.data.data.file) {
          window.open(data.data.data.file, "_blank");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  // Get All retail Detail API.

  const getAllMiniRetail = () => {
    setIsLoading(true);
    let reqData = {
      page: pageNumber ? pageNumber : page,
      pageSize,
      q: serearchQuery ? serearchQuery : searchQuery,
      vendor_id: filterData.vendor_id.value.toString(),
      zone_id: filterData.zone_id.value.toString(),
      region_id: filterData.region_id.value.toString(),
      area_id: filterData.area_id.value.toString(),
      state_id: filterData.state_id.value.toString(),
      city_id: filterData.city_id.value.toString(),
      is_download: false,
    };

    RetailMarketingSystemService.getMiniRetailHODetail(reqData)
      .then((data) => {
        let { data: list } = data.data;
        // setRecceStatusCountFilterAction(list.status);
        if (list.total_count === null) {
          setRecceStatusCountAction(0);
        } else {
          setRecceStatusCountAction(list.total_count);
        }

        if (
          list.elements &&
          Array.isArray(list.elements) &&
          list.elements.length > 0
        ) {
          setRetailListData(list.elements);
          if (isFirsTimeLoadPageDataNotFound) {
            setIsFirsTimeLoadPageDataNotFound(false);
          }
        } else {
          setRetailListData([]);
        }
        const totalCountsTemp = data.data.data.total_count;
        const totalPagesTemp = Math.ceil(totalCountsTemp / pageSize);
        setTotalRetailCounts(totalCountsTemp);
        setTotalPages(totalPagesTemp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  // Set Page Number in Pagination
  const handlePageChange = (page) => {
    localStorage.setItem("pageNumber", Number(page));
    setPage(page);
  };
  // Set Page Size in Pagination
  const handleInputChange = (e) => {
    setPageSize(Number(e.target.value));
    localStorage.setItem("pageNumber", 1);
    setPage(1);
  };

  // Styling for status Text in List.
  const getStatus = (status) => {
    let obj = {
      fontStyle: "italic",
      fontWeight: "normal",
      status: "Recce Approval Pending",
    };
    if (status === "recce_pending") {
      return obj;
    } else if (status === "recce_modified") {
      obj.fontStyle = "bold";
      obj.fontWeight = "bold";
      obj.status = "Recce Modification Required";
    } else if (status === "installation_pending") {
      obj.fontStyle = "bold";
      obj.fontWeight = "bold";
      obj.status = "Installation Pending";
    } else if (status === "recce_rejected") {
      obj.fontStyle = "bold";
      obj.fontWeight = "bold";
      obj.status = "Recce Rejected";
    } else if (status === "installation_completed") {
      obj.fontStyle = "italic";
      obj.fontWeight = "normal";
      obj.status = "Installation Completed";
    } else if (status === "installation_modified") {
      obj.fontStyle = "italic";
      obj.fontWeight = "normal";
      obj.status = "Installation Modification Required";
    } else if (status === "instllation_approved") {
      obj.fontStyle = "bold";
      obj.fontWeight = "bold";
      obj.status = "Installation Approved";
    }
    return obj;
  };
  // Edit Recce
  const editRecce = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Edit Recce Form");
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
    }
    setRetailDetail(row);
    setRedirectToAddEditRecce(true);
  };

  // Head Office Screen handle status of Recce and redirect to respective state.
  const handleRecceStatus = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Recce Approval");
      localStorage.setItem("screenStatus", "recce_pending");
      setHoInstallation(false);
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
      localStorage.setItem("screenStatus", "recce_modified");
    }
    if (row.status === "recce_rejected") {
      localStorage.setItem("screenTitle", "Recce Rejected");
      localStorage.setItem("screenStatus", "recce_rejected");
    }
    if (row.status === "installation_pending") {
      localStorage.setItem("screenTitle", "Installation Pending");
      localStorage.setItem("screenStatus", "installation_pending");
      setHoInstallation(false);
      setVendorInstallationModification(true);
    }
    if (row.status === "installation_completed") {
      localStorage.setItem("screenTitle", "Installation Approval");
      localStorage.setItem("screenStatus", "installation_completed");
      setHoInstallation(true);
    }
    if (row.status === "installation_modified") {
      localStorage.setItem("screenTitle", "Installation Modification Required");
      localStorage.setItem("screenStatus", "installation_modified");
      setHoInstallation(true);
      setVendorInstallationModification(true);
    }
    if (row.status === "instllation_approved") {
      localStorage.setItem("screenTitle", "Installation Approved");
      localStorage.setItem("screenStatus", "instllation_approved");
      setVendorInstallationModification(true);
      setHoInstallation(true);
    }
    setRetailDetail(row);
    setRedirectToViewRecce(true);
    setVendorViewOnly(false);
    setHo(true);
    setVendorScreenAction(true);
    localStorage.setItem("screenState", true);
  };
  const editRecceInstallation = (row) => {
    localStorage.setItem("screenTitle", "Edit Installation Form");
    localStorage.setItem("screenStatus", "installation_modified");
    if (row.status === "installation_modified") {
      localStorage.setItem(
        "screenTitle",
        "Edit Installation Form (Modification Required)"
      );
    }
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(true);
    setIsEdit(true);
    setHoInstallation(false);
    setVendor(true);
    localStorage.setItem("screenState", false);
  };
  const viewRecceInstallation = (row) => {
    localStorage.setItem("screenTitle", "Installation Approved");
    localStorage.setItem("screenStatus", "installation_completed");
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(true);
    setIsEdit(false);
    setIsInstallationApproved(true);
    setHoInstallation(true);
    setVendor(true);
    setVendorScreenAction(true);
    localStorage.setItem("screenState", false);
  };
  const completeRecceInstallation = (row) => {
    if (row.status === "installation_pending") {
      localStorage.setItem("screenTitle", "Installation Pending");
      localStorage.setItem("screenStatus", "installation_pending");
    }
    setVendor(true);
    setRetailDetail(row);
    setRedirectToCompleteInstallation(true);
    setVendorViewOnly(false);
    localStorage.setItem("screenState", false);
  };
  const viewRecce = (row) => {
    if (row.status === "recce_pending") {
      localStorage.setItem("screenTitle", "Recce Approval");
    }
    if (row.status === "recce_modified") {
      localStorage.setItem("screenTitle", "Recce Modification");
    }
    if (row.status === "recce_rejected") {
      localStorage.setItem("screenTitle", "Recce Rejected");
    }
    if (row.status === "installation_pending") {
      localStorage.setItem("screenStatus", "installation_pending");
      localStorage.setItem("screenTitle", "Installation Pending");
    }
    setVendor(true);
    setRetailDetail(row);
    setRedirectToViewRecce(true);
    setVendorViewOnly(true);
    localStorage.setItem("screenState", false);
  };

  // Listing page set Icon according to Action.
  const getAction = (row) => {
    let prepareRow = (
      <div className="action">
        <img
          className="cursor-pointer"
          style={{ height: "13px" }}
          onClick={() => viewRecce(row)}
          src="images/eye.png"
          alt=''
        />
        &nbsp;&nbsp;
      </div>
    );
    if (row.status === "recce_pending") {
      //
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecce(row)}
            alt=''
          />
        </div>
      );
    }
    if (row.status === "installation_completed") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecceInstallation(row)}
            alt=''
          />
          &nbsp;&nbsp;
        </div>
      );
    }
    if (row.status === "recce_modified") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/edit.svg"
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            onClick={() => editRecce(row)}
            alt=''
          />
          &nbsp;&nbsp;
          <img
            src="images/group-29.png"
            alt=''
            className="Group-3 "
            style={{ cursor: "pointer" }}
            data-tip={row.modification_comments}
            data-for={"tooltip-tyre-replacement"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "installation_pending") {
      prepareRow = (
        <div className="action">
          <img
            className="cursor-pointer"
            style={{ height: "13px" }}
            onClick={() => viewRecce(row)}
            src="images/eye.png"
            alt=''
          />{" "}
          &nbsp;&nbsp;
          <img
            src="/images/installation_pending.png"
            alt=''
            onClick={() => completeRecceInstallation(row)}
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            data-tip="Click to finish the Installation"
            data-for={"tooltip-tyre-replacementtt"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacementtt"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
          &nbsp;&nbsp;
          {row.modification_comments && (
            <img
              src="images/group-29.png"
              alt=''
              className="Group-3 "
              style={{ cursor: "pointer" }}
              data-tip={row.modification_comments}
              data-for={"tooltip-tyre-replacement"}
            />
          )}
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "installation_modified") {
      prepareRow = (
        <div className="action">
          <img
            src="/images/installation_pending.png"
            alt=''
            onClick={() => editRecceInstallation(row)}
            className="cursor-pointer"
            style={{ height: "16px", width: "16px" }}
            data-tip="Click to finish the Installation"
            data-for={"tooltip-tyre-replacementt"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacementt"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
          &nbsp;&nbsp;
          <img
            src="images/group-29.png"
            alt=''
            className="Group-3 "
            style={{ cursor: "pointer" }}
            data-tip={row.modification_comments}
            data-for={"tooltip-tyre-replacement"}
          />
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    if (row.status === "instllation_approved") {
      prepareRow = (
        <div className="action">
          <img
            className="cursor-pointer"
            style={{ height: "13px" }}
            onClick={() => viewRecceInstallation(row)}
            src="images/eye.png"
            alt=''
          />{" "}
          &nbsp;&nbsp;
          {row.modification_comments && (
            <img
              src="images/group-29.png"
              alt=''
              className="Group-3 "
              style={{ cursor: "pointer" }}
              data-tip={row.modification_comments}
              data-for={"tooltip-tyre-replacement"}
            />
          )}
          <ReactTooltip
            id={"tooltip-tyre-replacement"}
            className="customer-list-tooltip"
            effect="solid"
          >
            {/* {row.vendor_comment} */}
          </ReactTooltip>
        </div>
      );
    }
    return prepareRow;
  };
  return (
    <div style={{ position: "relative" }}>
      {redirectToAddEditRecce && (
        <Redirect
          to={{
            pathname: "/retail-marketing-recce",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            ho,
            vendor,
          }}
        />
      )}
      {vendorInstallationModification && (
        <Redirect
          to={{
            pathname: "/retail-marketing-recce",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            vendorInstallationModification,
            ho,
            vendor,
          }}
        />
      )}
      {redirectToViewRecce && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.id}`,
            vendorViewOnly,
            ho,
            vendor,
            vendorInstallationModification,
            hoInstallation,
          }}
        />
      )}
      {redirectToCompleteInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            isInstallationApproved,
            ho,
            vendor,
          }}
        />
      )}
      {hoInstallation && (
        <Redirect
          to={{
            pathname: "/retail-recce-progress-status",
            search: `id=${retailDetail.retail_id}`,
            vendorViewOnly,
            isEdit,
            hoInstallation,
            ho,
            vendor,
          }}
        />
      )}
      {isLoading && <CustomSpinner />}

      <Card id="RetailMarketingHOStoreInformation">
        <CardBody>
          {!isFirsTimeLoadPageDataNotFound && (
            <div className="d-inline-block">
              <div
                style={{ width: "301px" }}
                className="d-flex-row search-input"
              >
                <Input
                  placeholder="Search by SAP ID or Business name"
                  className="login-input"
                  type="text"
                  name="search"
                  onChange={handleSearchChange}
                  value={serearchQuery ? serearchQuery : userQuery}
                ></Input>
                <img
                  src="images/search.svg"
                  alt=''
                  className="icon-width"
                  style={{ marginLeft: "-25px" }}
                />
                <img
                  src={
                    filterAppliedStatus === true
                      ? "images/smaller-blue-dot-hi.png"
                      : "images/filter.svg"
                  }
                  alt=''
                  className={`cursor-pointer filter-icon`}
                  // srcSet="images/sidebar-icons/group-3@2x.png 2x,
                  //   images/sidebar-icons/group-3@3x.png 3x"
                  // className="Group-33"
                  style={{ marginTop: "4px", marginLeft: "19px" }}
                  onClick={openRecceFilter}
                />
              </div>
            </div>
          )}

          <div style={{ display: "contents" }}>
            <span className="Download-icon">
              <img
                src="images/group-6.png"
                srcSet="images/group-6@2x.png 2x,
                      images/group-6@3x.png 3x"
                className="Group-6"
                alt=''
              />
              <span
                className="Download-Report"
                onClick={() => downloadReport()}
              >
                Download Report
              </span>
            </span>
          </div>

          {openSignageSidebar && (
            <Sidebar
              sidebar={
                <>
                  <RetailMarketingSignageInformation
                    onCloseSidebar={onCloseSidebar}
                    getAllRetail={getAllMiniRetail}
                  />
                </>
              }
              open={openSignageSidebar}
              sidebarClassName="customer-management-filter"
              pullRight
              styles={{
                sidebar: {
                  background: "white",
                  height: "94%",
                  width: "500px",
                  top: "60px",
                  overflowY: "auto !important",
                },
              }}
            />
          )}

          <table
            className="mb-0 table-responsive tbl-f6f6f6"
            bordered="true"
            style={{ marginTop: "24px" }}
          >
            <thead>
              <tr className="tr-f6f6f">
                {role === "HO" && <th>Vendor Code</th>}
                {role === "HO" && <th>Vendor Name</th>}
                <th style={{ width: "100px" }}>SAP ID</th>
                <th style={{ width: "100px" }}>Business Name</th>
                {role !== "HO" && (
                  <th style={{ width: "100px" }}>Installation Period</th>
                )}
                <th style={{ width: "155px" }}>Recce Photos</th>
                <th style={{ width: "155px" }}>Installation Photos</th>
                <th style={{ width: "155px" }}>PIN</th>
                <th style={{ width: "155px" }}>City</th>
                <th style={{ width: "155px" }}>State</th>
                <th style={{ width: "155px" }}>Zone</th>
                <th style={{ width: "155px" }}>Region</th>
                <th style={{ width: "155px" }}>Territory</th>

                {/* <th>Sap Code</th>
                <th>Dealer Name</th> */}
                {/* {(role !== "HO" || recceStatus === "all") && <th>Status</th>} */}
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {retailListData[0] !== null &&
                retailListData.map((row, i) => {
                  return (
                    <tr key={i.toString()}>
                      {role === "HO" && (
                        <td>
                          <span
                          // style={{
                          //   fontStyle: getStatus(row.status).fontStyle
                          // }}
                          >
                            {row.vendor_code}
                          </span>
                        </td>
                      )}
                      {role === "HO" && (
                        <td>
                          <span
                          // style={{
                          //   fontStyle: getStatus(row.status).fontStyle
                          // }}
                          >
                            {row.vendor_name || "-"}
                          </span>
                        </td>
                      )}
                      <td>{row.sap_id}</td>
                      <td>{row.business_name || "-"}</td>
                      {role !== "HO" && (
                        <td>
                          {row.installation_month && row.installation_year
                            ? row.installation_month +
                            "/" +
                            row.installation_year
                            : "-"}
                        </td>
                      )}
                      <td>
                        <div className="d-flex">
                          {row.recce_image_1.length ? (
                            <a href={row.recce_image_1} target="_blank" rel="noopener noreferrer">
                              <img
                                src={row.recce_image_1}
                                alt="img1"
                                className="thumnail-img"
                              />
                            </a>
                          ) : null}

                          {row.recce_image_2.length ? (
                            <a href={row.recce_image_2} target="_blank" rel="noopener noreferrer">
                              <img
                                src={row.recce_image_2}
                                alt="img1"
                                className="thumnail-img"
                              />
                            </a>
                          ) : null}
                          {row.recce_image_3.length ? (
                            <a href={row.recce_image_3} target="_blank" rel="noopener noreferrer">
                              <img
                                src={row.recce_image_3}
                                alt="img1"
                                className="thumnail-img"
                              />
                            </a>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="d-flex">
                            {row.recce_installation_image_1.length ? (
                              <a
                                href={row.recce_installation_image_1}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={row.recce_installation_image_1}
                                  alt="img1"
                                  className="thumnail-img"
                                />
                              </a>
                            ) : null}

                            {row.recce_installation_image_2.length ? (
                              <a
                                href={row.recce_installation_image_2}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={row.recce_installation_image_2}
                                  alt="img1"
                                  className="thumnail-img"
                                />
                              </a>
                            ) : null}

                            {row.recce_installation_image_3.length ? (
                              <a
                                href={row.recce_installation_image_3}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={row.recce_installation_image_3}
                                  alt="img1"
                                  className="thumnail-img"
                                />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div>{row.pin_code || "-"}</div>
                      </td>
                      <td>{row.city || "-"}</td>
                      <td>{row.state || "-"}</td>
                      <td>{row.zone || "-"}</td>
                      <td>{row.region || "-"}</td>
                      <td>{row.area || "-"}</td>

                      {/* <td>
                      {role === "HO" ? (
                        <Row>
                          <Col sm="2">
                            <img
                              src="images/group-28.png"
                               alt=''
                              className="Group-3 "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleRecceStatus(row);
                              }}
                            />
                          </Col>
                          <Col sm="2">
                            {row.vendor_comment &&
                              !row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={row.vendor_comment}
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            {!row.vendor_comment &&
                              row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={row.vendor_comment_installation}
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            {row.vendor_comment &&
                              row.vendor_comment_installation && (
                                <img
                                  src="images/group-29.png"
                                   alt=''
                                  className="Group-3 "
                                  style={{ cursor: "pointer" }}
                                  data-tip={
                                    (row.vendor_comment,
                                    row.vendor_comment_installation)
                                  }
                                  data-for={"tooltip-tyre-replacement"}
                                />
                              )}
                            <ReactTooltip
                              id={"tooltip-tyre-replacement"}
                              className="customer-list-tooltip"
                              effect="solid"
                            ></ReactTooltip>
                          </Col>
                        </Row>
                      ) : (
                        getAction(row)
                      )}
                    </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {retailListData.length === 0 && (
            <div className="text-align-center card-point-header mt-2">
              Currently, there are not any recce.
            </div>
          )}
          {totalPages > 0 && (
            <div
              className="d-flex-row"
              style={{ marginTop: "10px", justifyContent: "flex-end" }}
            >
              <span className="Rows-per-page">Rows per page:</span>
              <select
                className="form-control rows-per-page-select"
                name="pageSize"
                value={pageSize}
                onChange={(e) => handleInputChange(e)}
                style={{ width: "65px" }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              &nbsp;&nbsp;
              <div style={{ marginTop: "5px" }}>
                <Pagination
                  margin={2}
                  page={page}
                  count={totalPages}
                  pageSize={pageSize}
                  totalRecords={totalRetailCounts}
                  onPageChange={handlePageChange}
                ></Pagination>
              </div>
            </div>
          )}
          {retailListData.length === 0 && (
            <div className="text-align-center card-point-header"></div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

RetailMarketingHOList.propTypes = {
  resetStoreDetailControlsAction: PropTypes.func.isRequired,
  setRecceStatusAction: PropTypes.func.isRequired,
  storeDetailControls: PropTypes.instanceOf(Object).isRequired,
  role: PropTypes.string,
  openRecceFilter: PropTypes.func,
  status_data: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    storeDetailControls: getRetailMarketingStoreInformationState(state),
    recceStatus: getRecceStatus(state),
    filterData: getFilterData(state),
    filterAppliedStatus: getFilterAppliedStatus(state),
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStoreDetailControlsAction: resetStoreDetailControls,
      resetfilterDataAction: resetfilterData,
      setFilterAppliedStatusAction: setFilterAppliedStatus,
      setRecceStatusAction: setRecceStatus,
      setRecceStatusCountAction: setRecceStatusCount,
      setRecceStatusCountFilterAction: setRecceStatusCountFilter,
      setVendorScreenAction: setVendorScreen,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailMarketingHOList);
