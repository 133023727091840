/* eslint-disable import/prefer-default-export */
const specialEngagementPrefix = "/api/v1/special-engagement";
const crmPointPrefix = "/api/v1/crm-points";
// const crmPointV2Prefix = '/api/v2/crm-points';
const giftCatalogPrefix = "/api/v1/merchandise/gift";

export const routes = {
  USER: {
    GET_USER_INFO: "/api/v1/user/get-user-info",
    UPDATE_USER_INFO: "/api/v1/user/update-user",
    UPLOAD_PROFILE_IMAGE: "/api/v1/user/upload-profile-image",
    UPDATE_DETAIL_REQUEST: "/api/v1/user/send-mail",
    CHANGE_PASSWORD: "/api/v1/user/change-password",
    SEARCH_DEALER: "/api/v1/user/search-dealer",
    GET_IMMEDIATE_DEALER: "/api/v1/user/get-immediate-dealer",
    GET_AREA_REGION_ZONE: "/api/v1/user/get-area-region-zone",
    GET_USER_COUNT: "/api/v1/user/get-user-count",
    GET_STATIC_WHATSAPP_TEMPLATES: "/api/v2/whatsapp/templates",
    WHATSAPP_UTILITY: "/api/v1/user/whatsapp-utility",
    WHATSAPP_REPORT: "/api/v2/whatsapp/report",
    WHATSAPP_REPORT_DETAIL: "/api/v2/whatsapp/detail-report",
    GET_ALL_WHATSAPP_TEMPLATES: "/api/v2/whatsapp/all-templates",
  },
  AUTH: {
    FORGOT_PASSWORD: "/api/v1/user/forgot-password",
    LOGIN: "/api/v1/auth/login",
    LOGOUT: "/api/v1/user/logout-from-all",
  },
  USER_DOCUMENTS: {
    GET_DOCUMENT_TYPES: "/api/v1/user-documents/document-types",
  },
  DASHBOARD: {
    SECONDARY_COUNT: "/api/v1/warranty/secondary-points/dashboard-count",
    SECONDARY_COUNT_DASBOARD_COUNT_v2:
      "/api/v2/warranty/secondary-points/dashboard-count",
    PRIMARY_POINTS: "/api/v1/crm-points/dashboard/points",
    YEARLY_OFFTAKE: "/api/v1/crm-points/dashboard/offtake/yearly",
    PRIMARY_GRAPH: "/api/v1/crm-points/dashboard/plot-graph",
    YTD: "/api/v1/crm-points/dashboard/year-till-date",
    MTD: "/api/v1/crm-points/dashboard/month-till-date",
    QTD: "/api/v1/crm-points/dashboard/quarter-till-date",
    GET_REDEEM_DATA: "/api/v1/user/get-redeem-data",
    VIDEO_URL: "/api/v1/user/video-url",
    GET_EMPLOYEE_DASHBOARD_PRIMARY_POINTS:
      "/api/v1/crm-points/reports/advantage-summary-manager",
    DOWNLOAD_SCORE_CARD: "/api/v1/crm-points/dashboard/score-card?export=true",
    HEAD_OFFICE_REPORT: "/api/v1/crm-points/dashboard/head-office",
    DOWNLOAD_EXCEL_HO: "/api/v1/crm-points/dashboard/download-excel-ho",
    GET_DISPLAY_LABEL: "/api/v1/crm-points/get-display-label",
    NAVBAR_TOHIDE: "/api/v1/user/inactive-menu",
    FILTERAPI: "/api/v1/sales-report/filter-api",
    ENGAGE_CUSTOMER: "/api/v1/campaign-management/customer/engage-customer",
    GROW_BUSINESS: "/api/v1/campaign-management/customer/grow-business",
    DASHBOARD_COUNTS: "/api/v1/campaign-management/customer/dashboard-counts",
    GET_BANNER: "/api/v1/user/get-banner",
    GET_REDEEMPTION_DASHBOARD:
      "/api/v1/crm-points/order/get-redeemption-dashboard",
    NEW_DASHBOARD_TOTAL_OFFTAKE:
      "/api/v1/crm-points/dashboard/new-dashboard-total-offtake",
    BANNER_VIEW_CLICK: "/api/v1/user/banner-view-click",
    GET_POINTS: "/api/v1/crm-points/baadshah/reports/points",
    GET_HELP_MODULE_SCREEN: "/api/v1/user/get-help-module-screen",
    ADD_HELP_MODULE_SCREEN: "/api/v1/user/add-help-module-screen",
  },
  WARRANTY_REGISTRATION: {
    // GETVEHICLETYPE: "/api/v1/warranty/vehicle/get-vehicle-type",
    EVENT_REGISTRATION: "/api/v1/user/user-registration-event",
    GETVEHICLETYPE: "/api/v2/warranty/vehicle/get-vehicle-type",
    GETSTATE: "/api/v1/warranty/state-city/get-state",
    GETCITY: "/api/v1/warranty/state-city/get-state-city",
    GETSKUID: "/api/v1/warranty/vehicle/get-vehicle-sku-id",
    GET_TOP_SKU: "/api/v1/warranty/vehicle/get-vehicle-top-sku-id",
    GET_VEHICALE_MAKE: "/api/v1/warranty/vehicle/get-vehicle-type-brand",
    GET_VEHICALE_MODEL: "/api/v1/warranty/vehicle/get-vehicle-type-brand-model",
    GET_VEHICALE_VARIATION:
      "/api/v1/warranty/vehicle/get-vehicle-type-brand-model-variation",
    GET_CUSTOMER: "/api/v1/warranty/secondary-points/find-customer",
    POST_WARRANTY_REGISTRATION:
      "/api/v2/warranty/secondary-points/registration",
    POST_WARRANTY_REGISTRATION_V3:
      "/api/v3/warranty/secondary-points/registration",
    GENERATE_OTP: "/api/v2/warranty/secondary-points/send-otp",
    VERIFY_OTP: "/api/v2/warranty/secondary-points/verify-otp",
    WARRANTY_REPORT: "/api/v1/warranty/claim/warranty-report",
    WARRANTY_SUMMARY_REPORT:
      "/api/v1/warranty/secondary-points/warranty-summary-report",
    WARRANTY_REPORT_CHART:
      "/api/v1/warranty/secondary-points/get-warranty-chart",
  },
  MARKETING_COUPON: {
    GET_COUPON: "/api/v1/coupon/get-coupons",
    GET_PROGRAM: "/api/v1/coupon/get-program-name",
    GET_DATA_FOR_COUPON: "/api/v1/coupon/get-data-for-coupon",
    GET_SUB_REPORT: "/api/v1/coupon/sub-reports",
    DELETE_COUPON: "/api/v1/coupon/delete-coupon",
    CN_REPORT: "/api/v1/coupon/cn-coupons-report",
    ADD_COUPON: "/api/v1/coupon/add-update-coupon",
    GET_COUPON_BY_ID: "/api/v1/coupon/get-coupon-by-id",
    GET_TYPE: "/api/v1/campaign-management/customer/get-vehicle-type",
    VALIDATE_COUPON: "/api/v1/coupon/validate-coupon",
    REDEEM_COUPON: "/api/v1/coupon/redeem-coupon",
    GET_REDEEMED_COUPON: "/api/v1/coupon/dealer-coupon-reports",
    GET_TYPE_PROGRAMNAME: "/api/v1/coupon/get-vehicle-type",
    GET_LOCATION: "/api/v1/locations/get",
  },
  WARRANTY_REPLACEMENT: {
    GET_CUSTOMER: "/api/v1/warranty/secondary-points/find-customer",
    GET_VEHICLE_TYPE: "/api/v1/warranty/vehicle/get-vehicle-type",
    GET_VEHICLE_WARRANTY_LIST:
      "/api/v1/warranty/claim/get-vehicle-warranty-list",
    GET_VEHICLE_WARRANTY_LIST_V3:
      "/api/v3/warranty/claim/get-vehicle-warranty-list",
    GENERATE_OTP: "/api/v1/warranty/secondary-points/send-otp",
    VERIFY_OTP: "/api/v1/warranty/secondary-points/verify-otp",
    GETSKUID: "/api/v1/warranty/vehicle/get-vehicle-sku-id",
    CLAIM_REGISTRATION: "/api/v2/warranty/claim/claim-registration",
    CLAIM_REGISTRATION_v3: "/api/v3/warranty/claim/claim-registration",
  },
  WARRANTY_SCORE_CARD: {
    GET_DEALER_WARRANTY_DETAIL:
      "/api/v1/warranty-am-report/get-dealer-warranty-details",
    GET_TOTAL_DEALERS_AND_POINTS:
      "/api/v1/warranty-am-report/get-total-dealers-and-points",
    GET_TOTAL_DEALERS_AND_POINTS_V2:
      "/api/v2/warranty-am-report/get-total-dealers-and-points",
    GET_DEALER_WARRANTY_DETAIL_V2:
      "/api/v2/warranty-am-report/get-dealer-warranty-details",
    GET_MAX_DATE: "/api/v1/warranty/secondary-points/get-max-date",
  },
  PRIMARY_REPORT: {
    GET_DELEAR_REPORT_QUARTERLY: "/api/v2/crm-points/reports/all",
    OFFTAKE_SUMMARY: "/api/v1/crm-points/reports/offtake",
    GET_ALL_DEALER_REPORT:
      "/api/v1/crm-points/reports/advantage-detail-manager",
    GET_TOP_SKU_OFFTAKE_TREND: "/api/v1/crm-points/get-top-sku-offtake-trend",
    GET_TOP_SKU_AREA_TREND: "/api/v1/crm-points/get-top-sku-area-trend",
    GET_TOP_SKU_WARRANTY_TREND: "/api/v1/crm-points/get-top-sku-warranty-trend",
    GET_ALL_MONTH_OFFTAKE_TREND:
      "/api/v1/crm-points/get-all-month-offtake-trend",
    GET_DEALER_REPORT_TARGET_ACHIEVEMENT:
      "/api/v1/crm-points/get-dealer-report-target-achievement",
  },
  ABOUT_DETAIL: {
    GET_ABOUT_DETAIL: "/api/v1/crm-points/about",
  },
  PROPOSAL_DETAILS: {
    GET_REGION: "/api/v1/new-dealer/region",
    GET_AREA: "/api/v1/new-dealer/area?region=",
    GET_SO: "/api/v1/new-dealer/so",
    UPLOAD_DOCUMENT_NEW_DEALER: "/api/v1/new-dealer/upload-temp-file",
    FRESH_PROPOSAL: "/api/v1/new-dealer/fresh-proposal",
    GET_DASHBOARD_COUNTS: "/api/v1/new-dealer/dashboard-counts",
    GET_PROPOSAL_LIST: "/api/v1/new-dealer/proposal-list",
    GET_ARCHITECT: "/api/v1/new-dealer/architect-list?type=",
    GET_PROPOSAL_BY_ID: "/api/v1/new-dealer/proposal-by-id?id=",
    UPDATE_PROPOSAL_STAGE: "/api/v1/new-dealer/update-proposal-stage",
    UPDATE_PROPOSAL_STATUS: "/api/v1/new-dealer/update-proposal-status",
    VISIT_BY_DATA: "/api/v1/new-dealer/architect-team?uuid=",
    RECCI_VISIT: "/api/v1/new-dealer/recci-visit",
    GET_PRPOSAL_STAGE_DETAIL:
      "/api/v1/new-dealer/proposal-stage-detail-by-id?id=",
    UPLOAD_LAYOUT_DOCUMENT: "/api/v1/new-dealer/upload-layout-documents",
    DELETE_LAYOUT_DOCUMENT: "/api/v1/new-dealer/delete-layout-documents",
    SAVE_COMPLTETION_REPORT: "/api/v1/new-dealer/save-completion-report",
    SAVE_INTERIM_VISIT: "/api/v1/new-dealer/save-interim-visit",
    HAND_OVER: "/api/v1/new-dealer/hand-over",
    PRE_INAUGRATION_DATE: "/api/v1/new-dealer/pre-inauguration-date",
    PRE_INAUGRATION_DATE_UPDATE: "/api/v1/new-dealer/update-inauguration-date",
    APPROVED_ESTIMATE: "/api/v1/new-dealer/approved-estimate",
    UPLOAF_ESTIMATE: "/api/v1/new-dealer/upload-estimate-documents",
    UPLOAD_AGREEMENT: "/api/v1/new-dealer/upload-agreement-documents",
    AGENCY_LIST: "/api/v1/new-dealer/agency-list",
    ASSIGN_AGENCY: "/api/v1/new-dealer/assign-agency",
    AGENCY_BRANDING: "/api/v1/new-dealer/agency-branding",
    SEARCH_DEALER: "/api/v1/new-dealer/search-dealer",
    UPDATE_BRANDING_STATUS: "/api/v1/new-dealer/branding-completed",
  },
  UPLOAD: {
    UPLOAD_SALES_REPORT: "/api/v1/crm-points/upload-sales-data",
  },
  MERCHANDISE_WALLET: {
    GET_ITEMS: "/api/v1/merchandise/dashboard/get-items",
    GET_DASHOBARD_COUNTS: "/api/v1/merchandise/dashboard/get-dashboard-coins",
    GET_ADDRESS: "/api/v1/merchandise/order/get-dealer-placed-address",
    PLACE_ORDER: "/api/v1/merchandise/order/place-order",
    GET_ORDERS: "/api/v1/merchandise/order/get-dealer-placed-orders",
    SEND_OTP_REDEMPTION:
      "/api/v2/warranty/secondary-points/send-otp-redemption",
    VERIFY_OTP_REDEMPTION:
      "/api/v2/warranty/secondary-points/verify-otp-redemption",
  },
  OTHER: {
    SAVE_TOKEN: "/api/v1/message/save-token",
    GET_UNREAD_NOTIFICATION_COUNT:
      "/api/v1/message/get-unread-notification-count",
    GET_NOTIFICATION: "/api/v1/message/get-notification",
  },
  NOTIFICATION: {
    SEND_NOTIFICATION: "/api/v1/message/send-notification",
    GET_ROLES: "/api/v1/message/get-role-ids",
    GET_NOTIFICATION_ACTIONS: "/api/v1/message/get-notification-actions",
    UPLOAD_NOTIFICATION_IMAGE: "/api/v1/message/upload-notification-image",
  },
  SALES_DATA_REPORT: {
    SALES_DATA_UPLOAD: "/api/v1/crm-points/upload-sales-data",
    MIGRATE_DATA: "/api/v1/crm-points/migrate-monthly-data",
    MIGRATE_QUARTER_DATA: "/api/v1/crm-points/migrate-quarterly-data",
    MOBILITY_GRN_DATA_UPLOAD:
      "/api/v1/mobility-fleet-sales/upload-mobility-fleet-sales-data",
    MIGRATE_YEAR_DATA: "/api/v1/crm-points/migrate-yearly-data",
  },
  EXCEPTIONAL_POINT: {
    EXCEPTIONAL_POINT_UPLOAD:
      "/api/v1/exceptional-points/upload-exceptional-points",
    EXCEPTIONAL_POINT_UPLOAD_V2:
      "/api/v2/exceptional-points/upload-exceptional-points",
    GET_CLOSING_BALANCE_TRACK:
      "/api/v1/exceptional-points/get-closing-point-tracker",
  },
  SUB_DEALER: {
    INVITE_DEALER: "/api/v1/branch-sub-dealer/invite",
    INVITE_AGAIN: "/api/v1/branch-sub-dealer/invite-again",
    GET_ALL_INVITEE: "/api/v1/branch-sub-dealer/get-all-invitees",
    DELETE_INVITEE: "/api/v1/branch-sub-dealer/delete-invitee",
    EDIT_INVITEE: "/api/v1/branch-sub-dealer/edit-invitees",
  },
  MERCHANDISE_REPORT: {
    GEL_ALL_ORDER: "/api/v1/merchandise/order/all",
    NOTIFY_MERCHANDISE_DISPATCH:
      "/api/v1/merchandise/order/notify-merchandise-dispatch",
    UPDATE_ORDER: "/api/v1/merchandise/order",
    TRACK_ORDER: "/api/v1/merchandise/order/track-order",
  },
  SPECIAL_ENGAGEMENT: {
    ADD_POPUP_DATA: `${specialEngagementPrefix}/add-popup-data`,
    GET_POPUP_DETAIL: `${specialEngagementPrefix}/get-popup-detail`,
    SUBMIT_POPUP_DETAIL: `${specialEngagementPrefix}/submit-popup-detail`,
    GET_POPUP_LINK: `${specialEngagementPrefix}/get-popup-link`,
    GET_USER_POPUP_TITLES: `${specialEngagementPrefix}/get-user-popup-titles`,
    GET_USER_POPUP_DETAIL: `${specialEngagementPrefix}/get-user-popup-detail`,
  },
  CUSTOMER_MGT: {
    GET_CUSTOMERS: "/api/v1/campaign-management/customer/get-customer",
    GET_VEHICLE_TYPE: "/api/v1/campaign-management/customer/get-vehicle-type",
    ADD_VEHICLE: "/api/v1/campaign-management/customer/add-customer-vehicle",
    UPDATE_VEHICLE:
      "/api/v1/campaign-management/customer/update-customer-vehicle",
    ADD_CUSTOMER: "/api/v1/campaign-management/customer/add-customer",
    UPDATE_CUSTOMER: "/api/v1/campaign-management/customer/update-customer",
    GET_CUSTOMER_VEHICLE:
      "/api/v1/campaign-management/customer/get-customer-vehicle",
    GET_GROUP: "/api/v1/campaign-management/customer/get-group",
    BLOCK_CUSTOMER: "/api/v1/campaign-management/customer/block-customer",
    GET_BLOCK_CUSTOMERS:
      "/api/v1/campaign-management/customer/get-block-customer",
    UNBLOCK_CUSTOMER: "/api/v1/campaign-management/customer/unblock-customer",
    /* GET_BLOCK_CUSTOMERS:
      '/api/v1/campaign-management/customer/get-block-customer', */
    ADD_GROUP: "/api/v1/campaign-management/customer/add-group",
    GET_GROUP_CUSTOMER:
      "/api/v1/campaign-management/customer/get-group-customer",
    ADD_CUSTOMERS_TO_GROUP: "/api/v1/campaign-management/customer/add-to-group",
    EDIT_GROUP: "/api/v1/campaign-management/customer/edit-group",
    DELETE_GROUP_CUSTOMER:
      "/api/v1/campaign-management/customer/delete-group-customer",
    ADD_TO_GROUP: "/api/v1/campaign-management/customer/add-to-group",
    DELETE_CUSTOMER: "/api/v1/campaign-management/customer/delete-customer",
    DELETE_GROUP: "/api/v1/campaign-management/customer/delete-group",
    GET_NEW_CUSTOMER_FOR_GROUP:
      "/api/v1/campaign-management/customer/get-new-customer-for-group",
    GET_SOURCE_MEMBER:
      "/api/v1/campaign-management/customer/get-source-of-member",
    DELETE_CUSTOMER_VEHICLE:
      "/api/v1/campaign-management/customer/delete-customer-vehicle",
    UPLOAD_FILE: "/api/v1/campaign-management/customer/upload-contact",
    CHECK_CUSTOMER: "/api/v1/campaign-management/customer/check-customer",
    GET_ALL_SERVICES:
      "/api/v1/campaign-management/customer/get-customer-service",
    GET_VISIT_PURPOSE:
      "/api/v1/campaign-management/customer/get-customer-purpose",
    GET_CUSTOMER_SERVICE_TAKEN:
      "/api/v1/campaign-management/customer/get-customer-service-taken",
    SERVICE_WISE_VEHICLE_TYPE_ID:
      "/api/v1/campaign-management/customer/service-wise-vehicle-type-id",
    SAVE_DEALER_EMAIL_PREF:
      "/api/v1/campaign-management/customer/save-dealer-email-preference-setting",
    GET_OVERDUE_SERVICES:
      "/api/v1/campaign-management/customer/get-customer-over-due-service",
  },
  CAMPAIGN: {
    GET_PURPOSE: "/api/v1/campaign-management/campaign/get-purpose",
    GET_SERVICE: "/api/v1/campaign-management/campaign/get-service",
    GET_FESTIVAL: "/api/v1/campaign-management/campaign/get-festival",
    GET_CAMPAIGN_DATA: "/api/v1/campaign-management/campaign/get-campaign-data",
    GET_ALL_CUSTOMER: "/api/v1/campaign-management/campaign/get-all-customer",
    GET_CUSTOMISE_CUSTOMER: "/api/v1/campaign-management/customer/get-customer",
    GET_GROUPS:
      "/api/v1/campaign-management/customer/get-group?page=1&pageSize=1000",
    SOURCE_OF_CUSTOMER:
      "/api/v1/campaign-management/customer/get-source-of-member",
    GET_CUSTOM_CUSTOMER: "/api/v1/campaign-management/customer/get-customer",
    UPLOAD_CAMPAIGN_IMAGE:
      "/api/v1/campaign-management/campaign/upload-campaign-image",
    POST_CREATE_CAMPAIGN:
      "/api/v1/campaign-management/campaign/create-campaign",
    DELETE_CAMPAIGN: "/api/v1/campaign-management/campaign/delete-campaign",
    SMS_REPORT_DETAIL: "/api/v1/sms-process/sms-report-detail",
    GET_CAMPAIGN_COUNT:
      "/api/v1/campaign-management/campaign/get-campaign-type-count",
    /* POST_CREATE_CAMPAIGN:
      '/api/v1/campaign-management/campaign/create-campaign',
    DELETE_CAMPAIGN: '/api/v1/campaign-management/campaign/delete-campaign', */
    POST_EDIT_CAMPAIGN: "/api/v1/campaign-management/campaign/edit-campaign/",
    POST_RERUN: "/api/v1/campaign-management/campaign/re-run-campaign",
    POST_CAMPAIGN_RUN: "/api/v1/campaign-management/campaign/",
    GET_CAMPAIGN_DETAIL:
      "/api/v1/campaign-management/campaign/get-campaign-detail",
    // GET_SMS_DETAIL: "/api/v1/sms-process/sms-report-detail",
    GET_SMS_DETAIL: "/api/v1/sms-process/sms-report-by-campaign",
    GET_TARGETED_EMPLOYEES:
      "/api/v1/campaign-management/campaign/get-targeted-employees",
    GET_DEALER_CLIFICATION:
      "/api/v1/campaign-management/campaign/get-dealer-classifications",
    GET_ZONE: "/api/v1/campaign-management/campaign/get-zone",
    GET_REGION: "/api/v1/campaign-management/campaign/get-region",
    GET_AREA: "/api/v1/campaign-management/campaign/get-area",
    GET_VEHICLE_TYPE: "/api/v1/campaign-management/customer/get-vehicle-type",
    GET_CUSTOMER_COUNT:
      "/api/v1/campaign-management/campaign/employee/get-customer-count",
    GET_CIP_CUSTOMER_COUNT:
      "/api/v1/campaign-management/campaign/get-cip-coustomer-count",
    GET_TARGETS: "/api/v1/campaign-management/campaign/get-targets",
    NOTIFICATION_REPORT:
      "/api/v1/campaign-management/campaign/get-campaign-notification-report",
    GET_PRODUCTS: "/api/v1/campaign-management/purchase-sms/products",
    BUY_SMS_BY_POINTS: "/api/v1/campaign-management/purchase-sms/by-points",
    BUY_SMS_ONLINE: "/api/v1/campaign-management/purchase-sms/online-web",
    ORDER_HISTORY: "/api/v1/campaign-management/purchase-sms/order-history",
    SMS_CREDIT: "/api/v1/campaign-management/purchase-sms/sms-credit",
    STATE_BY_GST: "/api/v1/campaign-management/purchase-sms/state-by-gst",
    DOWNLOAD_ORDER_INVOICE:
      "/api/v1/campaign-management/purchase-sms/download-invoice",
    CANCEL_TRANSACTION:
      "/api/v1/campaign-management/purchase-sms/cancel-transaction",
    GET_ALL_CAMPAIGN_DETAIL: "/api/v1/user-api/get-email-campaign",
    GET_DEALERS_DETAILS: "/api/v1/user-api/get-dealers-details",
    GET_FLEET_DETAILS: "/api/v1/fleet-register/get-all-fleet-data",
    GET_STATE_CITY_DISTRICT_MASTER:
      "/api/v1/user-api/get-state-city-district-master",
    GET_ADVANTAGE_BAADSHAH_EMPLOYEE_MASTER:
      "/api/v1/user-api/get-advantage-baadshah-employee-master",
  },
  CIP_INTRACTION: {
    ADD_CIP: "/api/v1/cip-register/add-cip",
    ADD_CIP_DETAILS: "/api/v1/cip-register/add-cip-details",
    GET_CIP: "/api/v1/cip-register/get-cip",
    GET_CIP_DETAIL_SUMMARY: "/api/v1/cip-register/get-cip-detail-summary",
    GET_CIP_DETAIL: "/api/v2/cip-register/get-cip-detail",
    UPDATE_CIP_DETAIL: "/api/v1/cip-register/update-cip-detail",
    GET_BRAND: "/api/v1/cip-register/get-brand",
    GET_BRAND_PATTERN: "/api/v1/cip-register/get-brand-pattern",
    SEND_BILL_SETTLMENT:
      "/api/v1/cip-register/send-summary-for-bill-settlement",
    DOWNLOAD_SUMMARY: "/api/v1/cip-register/download-summary",
    GET_CIP_CATEGORY: "/api/v2/cip-register/get-cip-category",
    GET_INVITE_CLASSIFICATION:
      "/api/v2/cip-register/get-invitee-classification",
    GET_ACTIVITY: "/api/v2/cip-register/get-activity",
    GET_CIP_V2: "/api/v2/cip-register/get-cip",
    GET_CIP_SUMMARY: "/api/v2/cip-register/get-cip-detail-summary",
    GET_CIP_CO_ATTENDEE_SUMMARY: "/api/v2/cip-register/get-co-attendee-list",
    ADD_CO_ATTENDEE: "/api/v2/cip-register/add-co-attendee",
    UPDATE_CO_ATTENDEE: "/api/v2/cip-register/update-co-attendee",
    CANCEL_CIP: "/api/v2/cip-register/cancel-cip",
    DELETE_CIP_DEATIL: "/api/v2/cip-register/delete-cip-detail",
    ADD_CIP_V2: "/api/v2/cip-register/add-cip",
    DELETE_CIP_CO_ATTENDEE: "/api/v2/cip-register/delete-co-attendee",
    END_CIP: "/api/v2/cip-register/end-cip",
    UPDATE_CIP_DETAIL_V2: "/api/v2/cip-register/update-cip-detail",
    GET_TRUCK_APM: "/api/v2/cip-register/get-truck-apm",
    ADD_CIP_DETAILS_V2: "/api/v2/cip-register/add-cip-details",
  },
  TRADE_SCHEME: {
    GET_DEALER_SCHEME: `${crmPointPrefix}/scheme/get-dealer-scheme`,
    GET_SCHEME_PERCENTAGE: `${crmPointPrefix}/scheme/get-scheme-percentage`,
    GET_SCHEME_DEALER_LIST: `${crmPointPrefix}/scheme/get-scheme-dealer-list`,
    DOWNLOAD_TRADE_SCHEME_EXCEL: `${crmPointPrefix}/scheme/download-trade-scheme-excel`,
    GET_SCHEMES: `${crmPointPrefix}/scheme/get-schemes`,
  },
  GIFT_CATALOG: {
    GET_GIFT_FILTER_DETAILS: `${giftCatalogPrefix}/get-gift-filter-detail`,
    GET_GIFT_ITEMS: `${giftCatalogPrefix}/get-gift-item-list`,
    GET_GIFT_ITEM_DETAIL: `${giftCatalogPrefix}/get-gift-item-detail`,
    GET_PRODUCT_LIST: `${giftCatalogPrefix}/get-product-list`,
    UPLOAD_PRODUCT_IMAGE: `${giftCatalogPrefix}/upload-product-image`,
    UPDATE_DEFAULT_IMAGE: `${giftCatalogPrefix}/update-default-image`,
    UPLOAD_GIFT_ITEMS: `${giftCatalogPrefix}/upload-gift-items`,
    UPDATE_GIFT_ITEM: `${giftCatalogPrefix}/update-gift-items`,
    PLACE_ORDER_POINTS: `/api/v1/crm-points/order/place-orde-v2`,
    GET_ORDERS: `${crmPointPrefix}/order/order-history`,
    GET_DASHOBARD_COUNTS: `${crmPointPrefix}/order/get-redeemption-dashboard`,
    GET_ADDRESS: `${crmPointPrefix}/get-address`,
    ADD_UPDATE_ADDRESS: `${crmPointPrefix}/add-update-address`,
    PLACE_ORDER: `${crmPointPrefix}/order/place-order`,
    GET_ORDERS_BY_FILTER: `${crmPointPrefix}/order/orders`,
    USER_UPDATE_DETAIL: `${crmPointPrefix}/update-user-detail`,
  },
  ANALYSIS: {
    API_URL: "https://jkanalysis.jktyrecrm.in",
    COMPARE: "/api/compare",
    GET_ZONES: "/api/get_zones",
    GET_REGIONS: "/api/get_regions",
  },
  ADD_CUSTOMER_NEW: {
    GET_SERVICE: "/api/v1/campaign-management/customer/get-customer-service",
    GET_PURPOSE: "/api/v1/campaign-management/customer/get-customer-purpose",
    GET_CUSTOMER_DETAIL:
      "/api/v1/campaign-management/customer/get-customer-detail",
    ADD_CUSTOMER_WITH_VISIT_HISTORY:
      "/api/v1/campaign-management/customer/add-customer-with-visit-history",
    ADD_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY:
      "/api/v1/campaign-management/customer/add-customer-with-visit-history",
    ADD_CUSTOMER_WITH_VISIT_HISTORY_SERVICE:
      "/api/v1/campaign-management/customer/add-customer-with-visit-history",
    ADD_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE:
      "/api/v1/campaign-management/customer/add-customer-with-visit-history",
    EXISTING_CUSTOMER_WITH_VISIT_HISTORY_INQUIRY:
      "/api/v1/campaign-management/customer/existing-customer-visit-history",
    EXISITING_CUSTOMER_WITH_VISIT_HISTORY_SERVICE:
      "/api/v1/campaign-management/customer/existing-customer-visit-history",
    EXISTING_CUSTOMER_WITH_VISIT_HISTORY_TYREPURCHASE:
      "/api/v1/campaign-management/customer/existing-customer-visit-history",
    GET_CUSTOMER_WISE_TRADE_HISTORY:
      "/api/v1/campaign-management/customer/get-customer-vehicle-trade-history",
    GET_BRANDS: "/api/v1/cip-register/get-brand?is_all=true",
    GET_BRANDS_RADIAL: "/api/v1/cip-register/get-brand?construction=radial",
    GET_DEALER_SERVICES:
      "/api/v1/campaign-management/customer/get-dealer-service",
    ADD_DEALER_RATING: "/api/v1/campaign-management/customer/add-rating",
    ADD_REFF_CUSTOMER:
      "/api/v1/campaign-management/customer/add-reference-or-success-sms-to-customer",
    SAVE_SERVICE:
      "/api/v1/campaign-management/customer/save-dealer-service-setting",
    CLOSE_TYRE_PURCHASE_UPCOMING_DUE:
      "/api/v1/campaign-management/customer/close-tyre-purchase-upcoming-due",
    GET_DEFAULT_DEALER_REFERAL_REWARD:
      "/api/v1/campaign-management/customer/get-default-dealer-referal-reward",
    SET_DEFAULT_DEALER_REFERAL_REWARD:
      "/api/v1/campaign-management/customer/set-dealer-referal-reward",
  },
  BANNER: {
    ADD_EDIT_BANNER: "/api/v1/user/add-update-banner",
    GET_TYPES_OF_BANNER: "/api/v1/user/get-types-for-banner",
    GET_BANNER: "/api/v1/user/get-banner",
    ACTIVE_INACTIVE_BANNER: "/api/v1/user/banner-active-inactive",
    GET_BANNER_VIEW_CLICK: "api/v1/user/banner-view-click",
    GET_ALL_BANNER: "/api/v1/user/get-all-banner",
    GET_BANNER_ELIGIBLE_DEALERS: "/api/v1/user/get-banner-eligible-dealers",
    GET_BANNER_DEALERS: "/api/v1/user/get-banner-dealers",
    GET_BANNER_ACTIONS: "/api/v1/user/get-banner-action",
  },
  CUSTOMER_REFERNCE: {
    GET_DEALER_INFO: "/api/v1/campaign-management/customer/get-dealer-info",
    ADD_REFF_CUSTOMER:
      "/api/v1/campaign-management/customer/add-reference-customer",
  },
  USER_UPDATE: {
    UPDATE_PASSWORD_TO_DEFAULT: "/api/v1/user-api/set-password-to-default",
    UPDATE_CLASS: "/api/v1/user-api/class-change-api",
    UPDATE_STATUS: "/api/v1/user-api/status-change-api",
    UPDATE_DATA: "/api/v1/user/upload-users",
    UPLOAD_FLEET: "/api/v1/user/insert-fleet-data",
    UPDATE_FM: "/api/v1/user/upload-fm",
    MAP_SAP: "/api/v1/user-api/map-sapid",
    EMAIL_SEND: "/api/v1/user/email-utility",
    ADD_CITY: "/api/v1/user/add-city",
    UPLOAD_RMS_DEALER_MAPPING: "/api/v1/user/upload-rms-dealer-mapping-data",
    UPLOAD_POINTS: "/api/v1/crm-points/upload-points-redemption",
    REDEMPTION_FILE: "/api/v1/crm-points/get-redemption-files",
    WHATSAPP_UTILITY: "/api/v1/user/whatsapp-utility",
    ADD_WHATSAPP_TEMPLATE: "/api/v2/whatsapp/add-template",
  },
  SMS_REPORT: {
    GET_SMS_REPORT: "/api/v1/sms-process/sms-report",
    GET_SMS_REPORT_DETAIL: "/api/v1/sms-process/sms-report-detail",
  },
  POSTER: {
    GET_POSTER: "/api/v1/poster/get-poster",
    GET_POSTER_DETAIL: "/api/v1/poster/get-poster-detail",
    ADD_EDIT_POSTER_DETAIL: "/api/v1/poster/add-update-poster-detail",
    DOWNLOAD_POSTER: "/api/v1/poster/download-poster",
    DOWNLOAD_POSTER_DETAIL: "/api/v1/poster/add-download-details",
    GET_POSTER_CATEGORY: "/api/v1/poster/get-poster-category",
    GET_POSTER_LIST: "/api/v1/poster/get-poster-list",
    ADD_EDIT_POSTER: "/api/v1/poster/add-update-poster",
    UPDATE_POSTER_IMAGE: "/api/v1/poster/upload-poster-image",
    ACTIVE_INACTIVE_POSTER: "/api/v1/poster/poster-active-inactive",
    GET_POSTER_TARGET_USER: "/api/v1/poster/get-target-users",
    GET_POSTER_DOWNLOAD_USER: "/api/v1/poster/get-downloaded-users",
    GET_USER_COUNT: "/api/v1/poster/get-user-count",
  },
  APPROVAL: {
    GET_POINTS_LIST: "/api/v3/exceptional-points/get-special-points-list",
    GET_PARTICULARS: "/api/v1/exceptional-points/get-particulars",
    FILE_UPLOAD: "/api/v1/user/upload-profile-image?type=special_points",
    GET_DESCRIPTION: "/api/v1/exceptional-points/get-descriptions",
    GET_CATEGORY: "/api/v1/exceptional-points/get-category",
    SEARCH_DEALER: "/api/v1/user/search-dealer",
    SEARCH_FLEET: "/api/v1/user/baadshah/search-fleet",
    ADD_POINTS: "/api/v3/exceptional-points/upload-special-points",
    EDIT_POINTS: "/api/v3/exceptional-points/edit-special-points",
    ADD_DESCRIPTION: "/api/v1/exceptional-points/add-new-description",
    SUBMIT_TO_JK: "/api/v3/exceptional-points/approve-reject-special-point",
    DELETE_POINT: "/api/v3/exceptional-points/delete-special-point",
    GET_POINTS_DETAIL:
      "/api/v3/exceptional-points/get-approve-reject-special-point-details",
    APPROVE_REJECT: "/api/v3/exceptional-points/ho-submit-reject-special-point",
    FILE_UPLOAD_CN: "/api/v3/exceptional-points/upload-cn-number",
  },
  WHATSAPP: {
    GET_CONTACTS: "/api/v1/whatsapp/whatsapp-contacts",
  },
  RETAIL_MARKETING_SYSTEM: {
    UPLOAD_RETAIL_PHOTOS: "/api/v1/retail-branding/upload-retail-images",
    GET_STATE_DISTRICT: "/api/v1/retail-branding/get-state-district",
    ADD_UPDATE_RETAIL: "/api/v1/retail-branding/add-update-retail",
    GET_ALL_RETAIL: "/api/v1/retail-branding/get-all-retail",
    GET_RETAIL_DETAIIL: "/api/v1/retail-branding/get-retail-detail",
    GET_DEALER_LIST: "/api/v1/retail-branding/get-vendor-dealer",
    ADD_UPDATE_RETAIL_STATUS: "/api/v1/retail-branding/update-retail-status",
    GET_CLUB_PRICE: "/api/v1/retail-branding/get-club",
    ADD_UPDATE_RETAIL_INSTALLATION:
      "/api/v1/retail-branding/add-update-retail-installation",
    GET_MINI_RETAIL_DETAIL: "/api/v1/retail-branding/get-mini-rms",
    GET_MINI_RETAIL_HO_DETAIL: "/api/v1/retail-branding/get-all-mini-rms",
    UPLOAD_RETAIL_IMAGES: "/api/v1/retail-branding/upload-retail-images",
    ADD_MINI_RETAIL_DETAIL: "/api/v1/retail-branding/add-mini-rms",
    GET_MINI_DEALER_LIST: "/api/v1/user/search-dealer",
    GET_VENDOR_LIST: "/api/v1/retail-branding/get-vendor-list",
  },
  ADMINFUNCTIONALITY: {
    GET_SKU_LIST: "/api/v1/crm-points/get-sku-list",
    ADD_SKU_DATA: "/api/v1/crm-points/add-pcr-sku",
    UPDATE_PCR_SKU_STATUS: "/api/v1/crm-points/update-pcr-sku-status",
    GET_PCR_SKU_HISTORY: "/api/v1/crm-points/get-pcr-sku-history",
    DOWNLOAD_PCR_SKU_HISTORY: "/api/v1/crm-points/download-pcr-sku-history",
  },
  FLEET_COMMUNICATION: {
    GENERATE_ALL_FLEET_SCORECARD:
      "/api/v1/crm-points/baadshah/generate-all-fleet-score-card",
  },
  GENERATIVE_AI: {
    POST_QUESTION: "/lms",
    SAVE_QUESTION: "/lms/save",
    SAVED_QUESTIONS: "/lms/charts",
    SAVE_CHART_ORDER: "/lms/charts/order",
  },
};
