import { request } from "./Request";
import { routes } from "../constants/constant.route";

const API_URL = process.env.REACT_APP_API_URL;

export default class GenerativeAIService {
  static getAnswer(req) {
    return request(
      "POST",
      `${API_URL}${routes.GENERATIVE_AI.POST_QUESTION}`,
      null,
      req,
      null
    );
  }
  static saveQuery(req) {
    const user = JSON.parse(localStorage.getItem("user-detail"));

    return request(
      "POST",
      `${API_URL}${routes.GENERATIVE_AI.SAVE_QUESTION}`,
      null,
      { ...req, user_id: user.uuid },
      null
    );
  }
  static getSavedQueries() {
    const user = JSON.parse(localStorage.getItem("user-detail"));
    return request(
      "GET",
      `${API_URL}${routes.GENERATIVE_AI.SAVED_QUESTIONS}?user_id=${user.uuid}`,
      null,
      null,
      null
    );
  }

  static saveChartOrderApi(req) {
    return request(
      "POST",
      `${API_URL}${routes.GENERATIVE_AI.SAVE_CHART_ORDER}`,
      null,
      { ...req },
      null
    );
  }
}
