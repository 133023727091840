import React from "react";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";

// services
import HeadOfficeService from "../../services/HeadOfficeService";
import StorageService from "../../services/Storage";

// components
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

// utils
import { getYearString } from "../../utils/utils";

// css
import "./HoDashboardFilter.css";
import { Roles } from "../../constants/constant.role";

const defaultControls = {
  area: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  month: {
    value: "all",
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  year: {
    value: null,
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  region: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  zone: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  isAllIndia: {
    value: true,
    valid: null,
    touched: false,
    nullValue: null,
    invalidEmail: null,
    required: true,
    showErrorMsg: false,
    autoFocus: false,
  },
  isFilterReset: false,
};

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
let defaultYearValue = null;
let currentYearString = getYearString(currentYear.toString());
let nextYearString = getYearString((currentYear + 1).toString());
if (currentMonth >= 1 && currentMonth <= 3) {
  currentYearString = getYearString((currentYear - 1).toString());
  nextYearString = getYearString(currentYear.toString());
}

const allMonths = [
  { key: "April", value: 4 },
  { key: "May", value: 5 },
  { key: "June", value: 6 },
  { key: "July", value: 7 },
  { key: "August", value: 8 },
  { key: "September", value: 9 },
  { key: "October", value: 10 },
  { key: "November", value: 11 },
  { key: "December", value: 12 },
  { key: "January", value: 1 },
  { key: "February", value: 2 },
  { key: "March", value: 3 },
];

// const index = allMonths.findIndex((m) => m.value === currentMonth);
// const months = allMonths.slice(0, index + 1);

class HoDashboardFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regionData: [],
      monthOptions: JSON.parse(JSON.stringify(allMonths)),
      areaData: [],
      controls: JSON.parse(JSON.stringify(defaultControls)),
      yearOptions: [],
      isLoading: false,
      dealerRole: null,
      initialYear: "",
    };
    if (this.props.fromGiftCatalog) {
      this.setFilter(this.props.filterControls);
    }
  }

  // componentDidMount() { }

  generateYearOptions = () => {
    const { controls } = this.state;
    const { year } = controls;
    const minYear = 2019;
    const date = new Date();
    // let currentYear = date.getFullYear() - 1;
    // let NextYear = date.getFullYear();
    let currentYear = date.getFullYear();
    let NextYear = date.getFullYear() + 1;
    const currentMonth = date.getMonth() + 1;

    if (currentMonth < 4) {
      NextYear -= 1;
    }
    if (currentMonth === 4) {
      year.value = currentYear.toString();
    } else {
      year.value = currentYear.toString();
    }

    defaultYearValue = year.value;
    if (currentMonth < 4) {
      currentYear -= 1;
      year.value = currentYear.toString();
    }

    defaultYearValue = year.value;
    if (year.value === new Date().getFullYear().toString()) {
      const index = allMonths.findIndex((m) => m.value === currentMonth);
      const months = allMonths.slice(0, index + 1);
      this.setState({ monthOptions: months });
    }

    const options = [];
    for (let i = minYear; i < NextYear; i++) {
      const startYear = i;
      const endYear = i + 1;
      const endYearString = endYear.toString().slice(2);
      const obj = {
        value: i.toString(),
        key: `${startYear}-${endYearString}`,
      };
      options.push(obj);
    }
    this.setState({
      yearOptions: options,
      controls,
      initialYear: controls.year.value,
    });
  };

  componentDidMount() {
    const userDetail = StorageService.getUserDetail();
    const { type } = userDetail;
    this.generateYearOptions();
    this.setState({ dealerRole: type }, () => {
      this.getHeadOfficeReport({});
    });
  }

  setFilter = (controls_filter) => {
    const keys = Object.keys(controls_filter);
    let { controls } = this.state;
    if (
      keys.length === 0 ||
      (controls_filter.hasOwnProperty("month") && keys.length === 1) ||
      (controls_filter.hasOwnProperty("year") && keys.length === 1) ||
      (controls_filter.hasOwnProperty("month") &&
        controls_filter.hasOwnProperty("year") &&
        keys.length === 2)
    ) {
      controls = JSON.parse(JSON.stringify(defaultControls));
      if (controls_filter.hasOwnProperty("month")) {
        controls.month = controls_filter.month;
      } else if (controls_filter.hasOwnProperty("year")) {
        controls.year = controls_filter.year;
      }
      this.setState({ regionData: [], areaData: [] }, () => {
        this.getHeadOfficeReport({});
      });
      // this.setState({ controls }, () => {
      //  else {
      // }
      // });
      // return;
    } else if (controls_filter && Object.keys(controls_filter).length > 0) {
      controls = controls_filter;
    }

    if (this.props.navigation && this.props.navigation.length > 0) {
      this.props.navigation.forEach(async (element) => {
        if (element.type === "zone") {
          // controls.zone.value = this.state.controls.zone.value = element.ref;
          controls.zone.value = element.ref;
          controls.region.value = "";
          controls.area.value = "";
          controls.isAllIndia.value = false;
          this.setState({ areaData: [] });
          // await this.onZoneChange({ refName: element.ref, type: 'zone' })
          await this.getHeadOfficeReport({
            refName: element.ref,
            type: "zone",
          });
        } else if (element.type === "region") {
          controls.region.value = this.state.controls.region.value =
            element.ref;
          controls.area.value = "";
          await this.getHeadOfficeReport({
            refName: element.ref,
            type: "region",
          });
        } else if (element.type === "area") {
          controls.area.value = this.state.controls.area.value = element.ref;
        }
      });
    }
    this.setState(
      {
        controls,
      },
      () => {
        // this.generateYearOptions();
      }
    );
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    controls[controlName].value = controlValue;
    controls[controlName].touched = true;
    this.setState({ controls });
    // this.handleValidation();
  };

  handleValidation = () => { };

  setCheckBoxValue = () => {
    // const { controls } = this.state;
    // controls.keepMeLoggenIn = !controls.keepMeLoggenIn;
    // this.setState({ controls });
  };

  closeModal = () => {
    this.props.closeModal(true);
  };

  getHeadOfficeReport = (obj) => {
    const { type = null, refName = null } = obj;
    this.setState({
      isLoading: true,
    });
    HeadOfficeService.geImmediateDealer(type, refName)
      .then((res) => {
        let { regionData, areaData, dealerRole } = this.state;
        if (res.data && res.data.data && res.data.data.length > 0) {
          if (type === "region" || dealerRole === Roles.REGION_MANAGER_ROLE) {
            areaData = res.data.data;
          } else if (
            type === "zone" ||
            dealerRole === Roles.ZONE_MANAGER_ROLE ||
            dealerRole === Roles.ZTM_ROLE
          ) {
            regionData = res.data.data;
          }
        } else if (type === "region") {
          areaData = [];
        } else if (type === "zone") {
          regionData = [];
        }
        this.setState({ regionData, areaData, isLoading: false });
      })
      .catch((e) => {
        console.error("e", e);
        this.setState({
          isLoading: false,
        });
      });
  };

  onZoneChange = (obj) => {
    const { refName } = obj;
    const { controls } = this.state;
    controls.zone.value = refName;
    controls.region.value = "";
    controls.area.value = "";
    controls.isAllIndia.value = false;
    this.setState({ controls, areaData: [], regionData: [] });
    this.getHeadOfficeReport(obj);
  };

  onChangeRegion = (e) => {
    const { controls } = this.state;
    controls.region.value = e.target.value;
    controls.area.value = "";
    this.setState({ controls });
    this.getHeadOfficeReport({
      refName: controls.region.value,
      type: "region",
    });
  };

  onChangeMonth = (e) => {
    const { controls } = this.state;
    const { month } = controls;
    month.value = e.target.value;
    this.setState({ controls });
    // this.getHeadOfficeReport({ refName: controls.region.value, type: 'region' });
  };

  onChangeYear = async (e) => {
    const { controls } = this.state;
    const { year, month } = controls;
    year.value = e.target.value;
    const date = new Date();
    let yearValue = date.getFullYear();
    // let currentYear = currentYear;
    if (currentMonth < 4) {
      yearValue -= 1;
    }

    if (year.value === yearValue.toString()) {
      const index = allMonths.findIndex((m) => m.value === currentMonth);
      const months = allMonths.slice(0, index + 1);
      const selectedMonthIndex = months.findIndex(
        (m) => m.value.toString() === month.value
      );
      if (selectedMonthIndex < 0) {
        month.value = "all";
      }
      await this.setState({ monthOptions: months });
    } else {
      await this.setState({ monthOptions: allMonths });
    }
    await this.setState({ controls });

    // this.getHeadOfficeReport({ refName: controls.region.value, type: 'region' });
  };

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls } = this.state;
    controls[controlName].value = e.target.value;
    controls[controlName].touched = true;
    this.setState({ controls });
  };

  applyFilter = () => {
    const { controls } = this.state;
    const { year } = controls;
    controls.isFilterReset = false;
    this.setState({ controls });
    if (!year.value) controls.year.value = this.state.initialYear;
    this.props.applyFilter(controls);
    // let { controls } = this.state;
    // if (controls.area.value) {
    //   this.props.applyFilter({ type: 'area', refName: controls.area.value })
    // } else if (controls.region.value) {
    //   this.props.applyFilter({ type: 'region', refName: controls.region.value })
    // } else if (controls.zone.value) {
    //   this.props.applyFilter({ type: 'zone', refName: controls.zone.value })
    // } else {
    //   this.props.applyFilter({})
    // }
  };

  resetFilter = () => {
    const { controls, dealerRole } = this.state;
    controls.zone.value = "";
    controls.region.value = "";
    controls.area.value = "";
    controls.isAllIndia.value = true;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      this.setState({ controls, areaData: [], regionData: [] });
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      this.setState({ controls, areaData: [] });
    } else if (dealerRole === Roles.ZTM_ROLE) {
      this.setState({ controls, areaData: [] });
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      this.setState({ controls });
    }
    controls.month.value = "all";
    controls.year.value = defaultYearValue;
    controls.isFilterReset = true;
    // this.setState({ controls, areaData: [], regionData: [] });
    this.props.applyFilter(this.state.controls);
  };

  onChangeIsAllIndia = (e) => {
    const { controls } = this.state;
    controls.zone.value = "";
    controls.region.value = "";
    controls.area.value = false;
    controls.isAllIndia.value = !!e.target.value;
    this.setState({ controls, areaData: [], regionData: [] });
  };

  render() {
    const {
      regionData,
      areaData,
      dealerRole,
      yearOptions,
      monthOptions,
    } = this.state;
    const { controls } = this.state;
    const { area, zone, region, month, year } = controls;

    const controlToDisplay = {
      head: false,
      zone: false,
      region: false,
      area: false,
      month_year: true,
    };
    if (this.props.fromGiftCatalog) {
      controlToDisplay.month_year = false;
    }
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      controlToDisplay.head = true;
      controlToDisplay.zone = true;
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.PROJECT_MANAGER_ROLE) {
      controlToDisplay.head = true;
      controlToDisplay.zone = true;
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.ZTM_ROLE) {
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      controlToDisplay.area = true;
    }
    const yearOptionToDisplay = yearOptions.map((y) => (
      <option value={y.value} defaultValue={year.value}>
        {y.key}
      </option>
    ));
    const monthOptionToDisplay = monthOptions.map((m) => (
      <option value={m.value} defaultValue={month.value}>
        {m.key}
      </option>
    ));

    return (
      <>
        {this.state.isLoading && <CustomSpinner marginTop="0%" />}
        {controlToDisplay.month_year && (
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            {/* <Row style={{ marginLeft: 0, marginRight: 0 }}> */}
            <Col>
              <FormGroup>
                <Label>Month</Label>
                <br />
                <select
                  name="month"
                  className="form-control"
                  value={month.value}
                  onChange={(e) => {
                    this.onChangeMonth(e);
                  }}
                >
                  <option value="all" selected>
                    All
                  </option>
                  {monthOptionToDisplay}
                  {/* {
                  months.map((item, j) => (
                    <option className="text-capitalize" value={item.value}>{item.key}</option>
                  ))
                } */}
                </select>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Year</Label>
                <br />
                <select
                  name="year"
                  className="form-control"
                  value={year.value}
                  onChange={(e) => {
                    this.onChangeYear(e);
                  }}
                >
                  {yearOptionToDisplay}
                </select>
              </FormGroup>
            </Col>
          </Row>
        )}
        {/* <Row style={{ marginLeft: 0, marginRight: 0 }}>
          
        </Row> */}
        {controlToDisplay.head && (
          <Row style={{ marginTop: "3px", marginLeft: 0, marginRight: 0 }}>
            <Col xl="12" xs="12" ms="12">
              <div>
                <label className="radion-container">
                  <input
                    type="radio"
                    name="all_india"
                    value={controls.isAllIndia.value}
                    onChange={(e) => {
                      this.onChangeIsAllIndia(e);
                    }}
                    checked={controls.isAllIndia.value}
                  />
                  <span className="radio-checkmark" />
                </label>
                <span style={{ marginLeft: "2px" }}>All India</span>
              </div>
            </Col>
          </Row>
        )}
        {controlToDisplay.zone && (
          <Row style={{ marginTop: "15px", marginLeft: 0, marginRight: 0 }}>
            <Col xl="12" xs="12" ms="12">
              <FormGroup>
                <Label>Zone</Label>
                <div>
                  <label className="radion-container">
                    North
                    <input
                      type="radio"
                      name="dealerType"
                      value="nz"
                      onChange={() => {
                        this.onZoneChange({ refName: "nz", type: "zone" });
                      }}
                      checked={zone.value === "nz"}
                    />
                    <span className="radio-checkmark" />
                  </label>
                  &nbsp;&nbsp;
                  <label className="radion-container">
                    South 1
                    <input
                      type="radio"
                      name="dealerType"
                      value="sz1"
                      onChange={() => {
                        this.onZoneChange({ refName: "sz1", type: "zone" });
                      }}
                      checked={zone.value === "sz1"}
                    />
                    <span className="radio-checkmark" />
                  </label>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <label className="radion-container">
                    South 2
                    <input
                      type="radio"
                      name="dealerType"
                      value="sz2"
                      onChange={() => {
                        this.onZoneChange({ refName: "sz2", type: "zone" });
                      }}
                      checked={zone.value === "sz2"}
                    />
                    <span className="radio-checkmark" />
                  </label>
                  &nbsp;&nbsp;
                  <label className="radion-container">
                    East
                    <input
                      type="radio"
                      name="dealerType"
                      value="ez"
                      onChange={() => {
                        this.onZoneChange({ refName: "ez", type: "zone" });
                      }}
                      checked={zone.value === "ez"}
                    />
                    <span className="radio-checkmark" />
                  </label>
                  &nbsp;&nbsp;
                  <label className="radion-container">
                    West
                    <input
                      type="radio"
                      name="dealerType"
                      value="wz"
                      onChange={() => {
                        this.onZoneChange({ refName: "wz", type: "zone" });
                      }}
                      checked={zone.value === "wz"}
                    />
                    <span className="radio-checkmark" />
                  </label>
                  &nbsp;&nbsp;
                  <label className="radion-container">
                    Central
                    <input
                      type="radio"
                      name="dealerType"
                      value="cz"
                      onChange={() => {
                        this.onZoneChange({ refName: "cz", type: "zone" });
                      }}
                      checked={zone.value === "cz"}
                    />
                    <span className="radio-checkmark" />
                  </label>

                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
        {controlToDisplay.region && (
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col>
              <FormGroup>
                <Label>Region</Label>
                <br />
                <select
                  name="cars"
                  className="form-control"
                  value={region.value}
                  onChange={(e) => {
                    this.onChangeRegion(e);
                  }}
                >
                  <option value="" disabled="true" selected>
                    Select
                  </option>
                  {regionData.map((item, j) => (
                    <option
                      className="text-capitalize"
                      value={item.business_name}
                    >
                      {item.business_name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
        )}
        {controlToDisplay.area && (
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col>
              <FormGroup>
                <Label>Territory</Label>
                <br />
                <select
                  name="area"
                  className="form-control"
                  value={area.value}
                  onChange={(e) => {
                    this.handleInputChange(e);
                  }}
                >
                  <option value="" disabled="true" selected>
                    Select
                  </option>
                  {areaData.map((item, j) => (
                    <option
                      value={item.business_name}
                      className="text-capitalize"
                    >
                      {item.business_name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row style={{ marginLeft: 0, marginRight: 0, position: "fixed", bottom: "70px", width: "100%" }}>
          <Col xl="12" xs="12" md="12" className="text-align-right mt-3">
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
              color="dark"
              onClick={() => this.resetFilter()}
            >
              Reset
            </Button>
            &nbsp;&nbsp;
            <Button
              className="mb-1 mr-1 btn-pill action-button profile-save-button"
              color="warning"
              onClick={() => {
                this.applyFilter();
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default HoDashboardFilter;
