import React, { useEffect, useState } from "react";
import "./Analysis.css";
import AnalysisPageTitle from "../../components/Analysis/AnalysisPageTitle/AnalysisPageTitle";
import {
  Container,
  FormGroup,
  Input,
  Row,
  Col,
  Collapse,
  Button,
} from "reactstrap";
import ComparisonYear from "../../components/Analysis/ComparisonYear/ComparisonYear";
import ComparisonQuarter from "../../components/Analysis/ComparisonQuarter/ComparisonQuarter";
import ComparisonMonth from "../../components/Analysis/ComparisonMonth/ComparisonMonth";
import GraphAnalysis from "../../components/Analysis/GraphAnalysis/GraphAnalysis";
// import { Collapse } from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { routes } from "../../constants/constant.route";

const Analysis = () => {
  const cmpYearInitState = { first_year: "", sec_year: "" };
  const cmpQrterInitState = {
    first_year: "",
    sec_year: "",
    first_quarter: "",
    sec_quarter: "",
  };
  const cmpMonthInitState = {
    first_year: "",
    sec_year: "",
    first_month: "",
    sec_month: "",
  };
  const [percentage, setPercentage] = useState(10);
  const [filterRadio, setFilterRadio] = useState("yoy");
  const [cmpYear, setCmpYear] = useState(cmpYearInitState);
  const [cmpQuarter, setCmpQuarter] = useState(cmpQrterInitState);
  const [cmpMonth, setCmpMonth] = useState(cmpMonthInitState);
  const [zone, setZone] = useState("");
  const [region, setRegion] = useState("");
  const [area, setArea] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tyreCategory, setTyreCategory] = useState("");
  const [classification, setClassification] = useState("");
  const [classificationList, setClassificationList] = useState([
    "TP",
    "PD",
    "CO",
    "TR",
    "NT",
    "SW",
    "XW",
    "PP",
    "HC",
    "IC",
    "FW",
    "TW",
    "EC",
    "DB",
    "RD",
    "RN",
    "HD",
    "MS",
    "FM",
    "WV",
    "MB",
  ]);
  const [tyreCategoryList, setTyreCategoryList] = useState([
    "SCV",
    "PCR",
    "LCV",
    "TRUCK-RADIAL",
    "TRUCK-BIAS",
    "2-3W",
    "FARM",
    "OTR",
  ]);
  const [chartHeading, setChartHeading] = useState(
    "Comparative Dashboard for dealers count"
  );
  const [chartLoading, setChartLoading] = useState(false);
  const [chartData2, setChartData2] = useState({
    first_year: "",
    sec_year: "",
    perc_filter: 0,
    zone: "",
    region: "",
    tyre_category: "",
    classification: "",
    first_month: "",
    sec_month: "",
  });
  const [chartData, setChartData] = useState({
    perc_filter: "",
    filterRadio: "",
    first_year: "",
    sec_year: "",
    first_month: "",
    sec_month: "",
  });
  const [chartCategories, setChartCategories] = useState([
    "Less then 0%",
    "More than 0%",
  ]);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Dealeres Count",
      data: [0, 0],
    },
  ]);

  const [showFilter, setShowFilter] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });

  useEffect(() => {
    getZoneList();
    // getTyreCategoryList();
  }, []);

  const getZoneList = async () => {
    try {
      // const { data } = await AnalysisService.getZoneList();
      // const json = data;
      const dataRes = await fetch(
        `${routes.ANALYSIS.API_URL}${routes.ANALYSIS.GET_ZONES}`,
        {
          method: "GET",
        }
      );
      const json = await dataRes.json();
      if (json) {
        console.log(json.Zones);
        setZoneList(json.Zones);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getRegionList = async (q) => {
    try {
      const dataRes = await fetch(
        `${routes.ANALYSIS.API_URL}${routes.ANALYSIS.GET_REGIONS}/?zone=${q}`,
        // `${API_URL1}/api/get_regions/?zone=${q}`,
        {
          method: "GET",
        }
      );
      if (dataRes) {
        const json = await dataRes.json();

        console.log(json);
        setRegionList(json["Regions"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePercentage = (e) => {
    const { name, value } = e.target;
    setPercentage(value);
  };

  const handleGetPercent = (e) => {
    console.log("percentage", percentage);
  };

  const handleChangeFilterRadio = (e) => {
    const { name, value } = e.target;
    setFilterRadio(value);

    // set intital state
    setCmpYear(cmpYearInitState);
    setCmpQuarter(cmpQrterInitState);
    setCmpMonth(cmpMonthInitState);
  };

  const handleChangeCmpYear = (e) => {
    const { name, value } = e.target;
    setCmpYear({ ...cmpYear, [name]: value });
  };
  const getCmpYearData = (e) => {
    console.log("cmpYear", cmpYear);
  };

  const handleChangeCmpQuarter = (e) => {
    const { name, value } = e.target;
    setCmpQuarter({ ...cmpQuarter, [name]: value });
  };

  const getCmpQuarterData = (e) => {
    console.log("cmpQuarter", cmpQuarter);
  };

  const handleChangeCmpMonth = (e) => {
    const { name, value } = e.target;
    setCmpMonth({ ...cmpMonth, [name]: value });
  };

  const getCmpMonthData = (e) => {
    console.log("cmpMonth", cmpMonth);
  };

  const downloadMaxDealerPurchase = () => {
    console.log("max");
  };

  const resetFilter = () => {
    setCmpYear(cmpYearInitState);
    setCmpQuarter(cmpQrterInitState);
    setCmpMonth(cmpMonthInitState);
    setPercentage("");
    setZone("");
    setArea("");
    setRegion("");
    setTyreCategory("");
    setClassification("");
  };

  const getChartData = async () => {
    try {
      setChartLoading(true);
      // let endPoint = "yoy_new";
      // let endPoint = "compare";
      let obj1 = {
        ...cmpYear,
        perc_filter: Number(percentage),
        zone: zone,
        region: region,
        tyre_category: tyreCategory,
        classification: classification,
      };
      if (filterRadio === "qoq") {
        obj1 = {
          ...obj1,
          ...cmpQuarter,
        };
      }
      if (filterRadio === "mom") {
        obj1 = {
          ...obj1,
          ...cmpMonth,
        };
      }
      let year1 = `${obj1.first_year}-${(Number(obj1.first_year) + 1)
        .toString()
        .slice(2, 4)}`;
      let year2 = `${obj1.sec_year}-${(Number(obj1.sec_year) + 1)
        .toString()
        .slice(2, 4)}`;
      let heading1 = `Comparative Dashboard for dealers count between <FY ${year1}> and <FY ${year2}>`;
      if (filterRadio === "qoq") {
        heading1 = `Comparative Dashboard for dealers count between <Quarter ${obj1.first_quarter.slice(
          1,
          2
        )} FY ${year1}> and <Quarter ${obj1.sec_quarter.slice(
          1,
          2
        )} FY ${year2}>`;
      }
      // str.charAt(0).toUpperCase() + str.slice(1)
      if (filterRadio === "mom") {
        let m1 =
          obj1.first_month.charAt(0).toUpperCase() + obj1.first_month.slice(1);
        let m2 =
          obj1.sec_month.charAt(0).toUpperCase() + obj1.sec_month.slice(1);
        heading1 = `Comparative Dashboard for dealers count between <${obj1.first_month} FY ${year1}> and <${obj1.sec_month} FY ${year2}>`;
      }
      console.log(obj1);
      if (obj1.first_year && obj1.sec_year && obj1.perc_filter) {
        // const dataRes = await AnalysisService.getYOYDealersCount(obj1);
        const headers = {
          "content-type": "application/json",
        };
        const dataRes = await fetch(
          `${routes.ANALYSIS.API_URL}${routes.ANALYSIS.COMPARE}`,
          // `http://192.168.1.86:8000/api/${endPoint}`,
          {
            method: "POST",
            // body: obj1,
            body: JSON.stringify(obj1),
            headers: headers,
          }
        );

        console.log(dataRes);
        setChartLoading(false);
        if (dataRes.ok) {
          const json = await dataRes.json();

          console.log(json);
          // data: [
          //   json[`dealers count with less than ${obj1.perc_filter}%`],
          //   json[`dealers count with more than ${obj1.perc_filter}%`],
          // ],
          setChartSeries([
            {
              name: "Dealeres Count",
              data: [
                json[`less than ${obj1.perc_filter}%`],
                json[`more than ${obj1.perc_filter}%`],
              ],
            },
          ]);
          setChartHeading(heading1);
          setChartCategories([
            `Less then ${obj1.perc_filter}%`,
            `More than ${obj1.perc_filter}%`,
          ]);
          let obj2 = { ...chartData, ...obj1, filterRadio: filterRadio };
          setChartData(obj2);
          setChartData2(obj1);
        } else {
          setChartHeading("Comparative Dashboard for dealers count");
          setChartCategories(["Less then 0%", "More than 0%"]);
          setChartData({
            perc_filter: "",
            filterRadio: "",
            first_year: "",
            sec_year: "",
            first_month: "",
            sec_month: "",
          });
          setChartSeries([
            {
              name: "Dealeres Count",
              data: [0, 0],
            },
          ]);
        }
      }
      setChartLoading(false);
    } catch (error) {
      console.log(error);
      setChartLoading(false);
    }
  };

  return (
    <div className="analysisPage" id="analysisPage">
      {/* Page Heading */}
      <AnalysisPageTitle
        title="JK Tyre Dealer Business Comparative Dashboard"
        subTitle="The comparisons are based on Average Per Month (APM) Offtake Analysis"
        headIcon="./images/custom-loader.png"
      />
      <div className="alnalysisAreaWrap">
        <Container fluid className="p-0">
          <Row className="m-0" style={{ width: "100%" }}>
            {/* <GraphAnalysis1 /> */}
            <Col className="p-0" lg={8}>
              <div className="graphAnalysis1">
                {chartLoading ? (
                  <CustomSpinner />
                ) : (
                  <GraphAnalysis
                    chartSeries={chartSeries}
                    categories={chartCategories}
                    chartHeading={chartHeading}
                    chartData={chartData}
                    chartData2={chartData2}
                  // downloadMaxDealerPurchase={downloadMaxDealerPurchase}
                  />
                )}
              </div>
            </Col>
            {/* Analysis Area */}
            <Col className="p-0" xs={12} md={8} lg={4}>
              <div className="filterWrap card">
                <p className="filter-heading">FILTER</p>
                <form>
                  <div className="filter-body">
                    <div style={{ margin: "25px 0 25px 0" }}>
                      <p
                        className={showFilter[1] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            1: !showFilter[1],
                            2: false,
                            3: false,
                            4: false,
                            5: false,
                            6: false,
                            7: false,
                          })
                        }
                      >
                        Percentage
                        <span style={{ float: "right" }}>
                          {!showFilter[1] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[1]}>
                        <div className="analysisBlock left mb-3 d-flex align-items-center ">
                          <Input
                            name="percentage"
                            id="percentage"
                            type="number"
                            value={percentage}
                            onChange={handleChangePercentage}
                            placeholder="Enter Percentage"
                            style={{ width: "175px" }}
                          />
                          <span className="ml-2">%</span>
                        </div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "25px" }}>
                      <p
                        className={showFilter[2] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            2: !showFilter[2],
                            1: false,
                            3: false,
                            4: false,
                            5: false,
                            6: false,
                            7: false,
                          })
                        }
                      >
                        Comparison
                        <span style={{ float: "right" }}>
                          {!showFilter[2] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[2]}>
                        <div className="analysisBlock FilterRightBlock left mb-3">
                          <div className="radioBlockWrap mb-4  mt-1">
                            <label
                              className={
                                "container" +
                                (filterRadio == "yoy" ? " font-bold " : "")
                              }
                            >
                              YOY
                              <input
                                type="radio"
                                name="filterBtnRadio"
                                value="yoy"
                                checked={filterRadio === "yoy"}
                                onChange={handleChangeFilterRadio}
                              />
                              <span
                                className="checkmark"
                                style={{ top: "5px", left: "5px" }}
                              />
                            </label>
                            <label
                              className={
                                "container" +
                                (filterRadio == "qoq" ? " font-bold " : "")
                              }
                            >
                              QOQ
                              <input
                                type="radio"
                                name="filterBtnRadio"
                                value="qoq"
                                checked={filterRadio === "qoq"}
                                onChange={handleChangeFilterRadio}
                              />
                              <span
                                className="checkmark"
                                style={{ top: "5px", left: "34%" }}
                              />
                            </label>
                            <label
                              className={
                                "container" +
                                (filterRadio == "mom" ? " font-bold " : "")
                              }
                            >
                              MOM
                              <input
                                type="radio"
                                name="filterBtnRadio"
                                value="mom"
                                checked={filterRadio === "mom"}
                                onChange={handleChangeFilterRadio}
                              />
                              <span
                                className="checkmark"
                                style={{ top: "5px", left: "67.5%" }}
                              />
                            </label>
                          </div>
                          <div className="radioDataBlock">
                            {filterRadio === "yoy" ? (
                              <ComparisonYear
                                cmpYear={cmpYear}
                                setCmpYear={setCmpYear}
                                handleChangeCmpYear={handleChangeCmpYear}
                                getCmpYearData={getCmpYearData}
                              />
                            ) : (
                              ""
                            )}
                            {filterRadio === "qoq" ? (
                              <ComparisonQuarter
                                cmpQuarter={cmpQuarter}
                                setCmpQuarter={setCmpQuarter}
                                handleChangeCmpQuarter={handleChangeCmpQuarter}
                                getCmpQuarterData={getCmpQuarterData}
                              />
                            ) : (
                              ""
                            )}
                            {filterRadio === "mom" ? (
                              <ComparisonMonth
                                cmpMonth={cmpMonth}
                                setCmpMonth={setCmpMonth}
                                handleChangeCmpMonth={handleChangeCmpMonth}
                                getCmpMonthData={getCmpMonthData}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "20px" }}>
                      <p
                        className={showFilter[3] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            3: !showFilter[3],
                            1: false,
                            2: false,
                            4: false,
                            5: false,
                            6: false,
                            7: false,
                          })
                        }
                      >
                        Geography
                        <span style={{ float: "right" }}>
                          {!showFilter[3] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[3]}>
                        <div className="analysisBlock left mb-3">
                          <Row className="yearWrap">
                            <Col className="mb-2 flex">
                              <label>Select Zone</label>
                              <select
                                className="form-control select"
                                name="zone"
                                value={zone}
                                onChange={(e) => {
                                  setZone(e.target.value);
                                  getRegionList(e.target.value);
                                }}
                              >
                                <option value="">
                                  Select Zone
                                </option>
                                {zoneList.length > 0 &&
                                  zoneList.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </select>
                              {/* <span className='select-dwn-icon'><FaChevronDown/></span> */}
                            </Col>
                            <Col className="mb-2 flex">
                              <label>Select Region</label>
                              <select
                                className="form-control select"
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                              >
                                <option value="">
                                  Select Region
                                </option>
                                {regionList.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </select>
                            </Col>
                            {/* <Col className="mb-2 flex">
                            <label>Select Territory</label>
                            <select
                              className="form-control select"
                              name="area"
                              value={area}
                              onChange={(e) => {
                                setArea(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {zoneList.length > 0 &&
                                zoneList.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </Col> */}
                          </Row>
                        </div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "25px" }}>
                      <p
                        className={showFilter[7] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            7: !showFilter[7],
                            1: false,
                            2: false,
                            3: false,
                            4: false,
                            5: false,
                            6: false,
                          })
                        }
                      >
                        Tyre Category
                        <span style={{ float: "right" }}>
                          {!showFilter[7] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[7]}>
                        <div className="analysisBlock left mb-3">
                          <Row className="yearWrap">
                            <Col className="mb-2 flex">
                              <label>Select Tyre Category</label>
                              <select
                                className="form-control select"
                                name="tyreCategory"
                                value={tyreCategory}
                                onChange={(e) => {
                                  setTyreCategory(e.target.value);
                                }}
                              >
                                <option value="">
                                  Select Category
                                </option>
                                {/* <option value="LCV">LCV</option>
                              <option value="OTR">OTR</option>
                              <option value="PTR">PTR</option> */}
                                {/* 'SCV' 'PCR' 'LCV' 'TRUCK-RADIAL' 'TRUCK-BIAS' '2-3W' 'FARM' 'OTR */}
                                {tyreCategoryList.length > 0 &&
                                  tyreCategoryList.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </select>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "25px" }}>
                      <p
                        className={showFilter[4] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            4: !showFilter[4],
                            1: false,
                            2: false,
                            3: false,
                            5: false,
                            6: false,
                            7: false,
                          })
                        }
                      >
                        Classifications
                        <span style={{ float: "right" }}>
                          {!showFilter[4] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[4]}>
                        <div className="analysisBlock left mb-3">
                          <Row className="yearWrap">
                            <Col className="mb-2 flex">
                              <label>Classifications</label>
                              <select
                                className="form-control select"
                                name="tyreCategory"
                                value={classification}
                                onChange={(e) => {
                                  setClassification(e.target.value);
                                }}
                              >
                                <option value="">
                                  Select classifications
                                </option>
                                {/* <option value="CO">CO</option>
                              <option value="EC">EC</option>
                              <option value="EN">EN</option> */}
                                {classificationList.length > 0 &&
                                  classificationList.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </select>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "25px" }}>
                      <p
                        className={showFilter[5] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            5: !showFilter[5],
                            2: false,
                            3: false,
                            4: false,
                            1: false,
                            6: false,
                            7: false,
                          })
                        }
                      >
                        Clubs
                        <span style={{ float: "right" }}>
                          {!showFilter[5] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[5]}>
                        <div className="analysisBlock left mb-3">Clubs</div>
                      </Collapse>
                    </div>

                    <div style={{ marginBottom: "25px" }}>
                      <p
                        className={showFilter[6] ? "shade2" : "shade"}
                        onClick={() =>
                          setShowFilter({
                            6: !showFilter[6],
                            1: false,
                            2: false,
                            3: false,
                            4: false,
                            5: false,
                            7: false,
                          })
                        }
                      >
                        Graphic Analysis
                        <span style={{ float: "right" }}>
                          {!showFilter[6] ? (
                            <FaPlusCircle />
                          ) : (
                            <FaMinusCircle />
                          )}
                        </span>
                      </p>
                      <Collapse isOpen={showFilter[6]}>
                        <div className="analysisBlock left mb-3">
                          Graphic Analysis
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxWidth: "200px",
                      margin: "12px 15px auto auto",
                    }}
                  >
                    <Button
                      color="warning"
                      className="filterHeading2"
                      outline
                      onClick={resetFilter}
                    >
                      Reset
                    </Button>
                    <Button
                      color="warning"
                      type="submit"
                      className="filterHeading"
                      onClick={(e) => {
                        e.preventDefault();
                        getChartData();
                      }}
                    >
                      Get Data
                    </Button>
                    {/* <div className="filterHeading">Get Data</div>*/}
                  </div>
                </form>
              </div>
            </Col>

            {/* <GraphAnalysis2 /> */}
            <Col className="p-0" lg={8}>
              <div className="graphAnalysis2 mb-3">
                {chartLoading ? (
                  <CustomSpinner />
                ) : (
                  <GraphAnalysis
                    chartSeries={chartSeries}
                    categories={chartCategories}
                    chartHeading={chartHeading}
                    chartData={chartData}
                    chartData2={chartData2}
                  // downloadMaxDealerPurchase={downloadMaxDealerPurchase}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Analysis;
