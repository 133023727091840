
import React, { Fragment } from 'react';
import { Button, FormGroup, Label, Row, Col } from 'reactstrap';

// services
import UserService from '../../services/UserService';
import StorageService from '../../services/Storage';

// components
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';

//utils
import { getYearString } from '../../utils/utils';

// css
import './TradeSchemeFilter.css';
import { Roles } from '../../constants/constant.role';

const defaultControls = {
  "area": {
    "value": "",
    "valid": null,
    "touched": false,
    "nullValue": null,
    "invalidEmail": null,
    "required": true,
    "showErrorMsg": false,
    "autoFocus": false
  },
  "review_achievement": {
    "value": "month",
    "valid": null,
    "touched": false,
    "nullValue": null,
    "invalidEmail": null,
    "required": true,
    "showErrorMsg": false,
    "autoFocus": false
  },
  "region": {
    "value": "",
    "valid": null,
    "touched": false,
    "nullValue": null,
    "invalidEmail": null,
    "required": true,
    "showErrorMsg": false,
    "autoFocus": false
  },
  "zone": {
    "value": "",
    "valid": null,
    "touched": false,
    "nullValue": null,
    "invalidEmail": null,
    "required": true,
    "showErrorMsg": false,
    "autoFocus": false
  },
  "isAllIndia": {
    "value": true,
    "valid": null,
    "touched": false,
    "nullValue": null,
    "invalidEmail": null,
    "required": true,
    "showErrorMsg": false,
    "autoFocus": false
  },
}

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();

let currentYearString = getYearString(currentYear.toString());
let nextYearString = getYearString((currentYear + 1).toString());
if (currentMonth >= 1 && currentMonth <= 3) {
  currentYearString = getYearString((currentYear - 1).toString());
  nextYearString = getYearString((currentYear).toString());
}

// const index = allMonths.findIndex(m => m.value === currentMonth);
// const months = allMonths.slice(0, index + 1);

class TradeSchemeFilter extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      regionData: [],
      zoneData: [],
      areaData: [],
      controls: JSON.parse(JSON.stringify(defaultControls)),
      dealerRole: null,
      isLoading: false,
      businessName: {
        zone: null,
        region: null,
        area: null
      }
    }
  }

  async componentDidMount() {
    const { navigation, filterControls, reviewAchievement } = this.props;
    const userDetail = StorageService.getUserDetail();
    const dealerType = userDetail.type;
    const obj = {
      type: dealerType
    }
    await this.getAreaRegionZone(obj);
    let { controls } = this.state;
    controls.review_achievement.value = reviewAchievement;
    this.setState({ controls });

    this.setControlValue(navigation);
    // // this.setFilter(filterControls, navigation);
    this.setState({ dealerRole: dealerType });
  }

  setControlValue = (navigation) => {
    const { controls, businessName } = this.state;
    if (navigation && navigation.length > 0) {
      navigation.forEach(async (element) => {
        if (element.role === 'ZM') {
          controls.zone.value = element.uuid;
          businessName.zone = element.name;
          await this.getAreaRegionZone({ uuid: element.uuid, type: Roles.ZONE_MANAGER_ROLE }, element.name)
        } else if (element.role === 'ztm') {
          controls.zone.value = element.uuid;
          businessName.zone = element.name;
          await this.getAreaRegionZone({ uuid: element.uuid, type: Roles.ZONE_MANAGER_ROLE }, element.name)
        } else if (element.role === 'SM') {
          controls.region.value = element.uuid;
          businessName.region = element.name;
          await this.getAreaRegionZone({ uuid: element.uuid, type: Roles.REGION_MANAGER_ROLE }, element.name);
        } else if (element.role === 'AM') {
          controls.area.value = element.uuid;
          businessName.area = element.name;
        }
      });
    }
    this.setState({ controls, businessName });
  }

  // setFilter = (controls_filter, navigation) => {
  //   const keys = Object.keys(controls_filter);
  //   if (keys.length === 0) {
  //     this.setState({ controls: JSON.parse(JSON.stringify(defaultControls)) });
  //     return;
  //   }
  //   let { controls } = this.state;
  //   if (controls_filter && Object.keys(controls_filter).length > 0) {
  //     controls = controls_filter;
  //   }
  //   if (navigation && navigation.length > 0) {
  //     navigation.forEach(element => {
  //       if (element.role === 'ZM') {
  //         this.onZoneChange(element.uuid, element.name)
  //       } else if (element.role === 'SM') {
  //         controls.region.value = element.uuid;
  //         this.getAreaRegionZone({ uuid: element.uuid, type: 'region_manager' }, element.name);
  //       } else if (element.role === 'AM') {
  //         controls.area.value = element.uuid;
  //       }
  //     });
  //   }
  //   this.setState({
  //     controls: controls
  //   })
  // }

  handleValidation = () => {

  }


  setCheckBoxValue = () => {
    // const { controls } = this.state;
    // controls.keepMeLoggenIn = !controls.keepMeLoggenIn;
    // this.setState({ controls });
  }


  closeModal = () => {
    this.props.closeModal(true);
  }

  getAreaRegionZone = (obj) => {
    const { type = null, uuid = null } = obj;
    this.setState({
      isLoading: true
    })
    UserService.getAreaRegionZone(uuid)
      .then(res => {
        let { regionData, areaData, zoneData } = this.state;
        if (res.data && res.data.data && res.data.data.length > 0) {
          if (type === Roles.HEAD_OFFICE_ROLE) {
            zoneData = res.data.data;
          }
          if (type === Roles.ZONE_MANAGER_ROLE) {
            regionData = res.data.data;
          }
          if (type === Roles.ZTM_ROLE) {
            regionData = res.data.data;
          }
          if (type === Roles.REGION_MANAGER_ROLE) {
            areaData = res.data.data;
          }
        } else {
          if (type === Roles.REGION_MANAGER_ROLE) {
            areaData = [];
          } else {
            regionData = [];
          }
        }
        this.setState({ regionData, areaData, isLoading: false, zoneData });
      }).catch(e => {
        console.error("e", e);
        this.setState({
          isLoading: false
        })
      })
  }

  onZoneChange = (uuid, business_name) => {
    // const { uuid } = obj;
    let { controls, businessName } = this.state;
    controls.zone.value = uuid;
    controls.region.value = '';
    controls.area.value = '';
    businessName.region = null;
    businessName.area = null;
    businessName.zone = business_name;
    // controls.isAllIndia.value = false;
    this.setState({ controls, areaData: [], regionData: [] });
    const obj = { type: Roles.ZONE_MANAGER_ROLE, uuid }
    this.getAreaRegionZone(obj);
  }

  onChangeRegion = (e) => {
    let { controls, regionData, businessName } = this.state;
    controls.region.value = e.target.value;
    controls.area.value = '';
    const index = regionData.findIndex(r => r.uuid === e.target.value);
    if (index > -1) {
      businessName.region = regionData[index].business_name;
    }
    this.setState({ controls, businessName });
    this.getAreaRegionZone({ uuid: controls.region.value, type: 'region_manager' });
  }

  handleInputChange = (e) => {
    const controlName = e.target.name;
    const { controls, businessName, areaData } = this.state;
    controls[controlName].value = e.target.value;
    controls[controlName].touched = true;
    const index = areaData.findIndex(a => a.uuid === e.target.value);
    if (index > -1) {
      businessName.area = areaData[index].business_name;
    }
    this.setState({ controls, businessName });
  }

  applyFilter = () => {
    this.props.applyFilter(this.state.controls, this.state.businessName);
    // let { controls } = this.state;
    // if (controls.area.value) {
    //   this.props.applyFilter({ type: 'area', refName: controls.area.value })
    // } else if (controls.region.value) {
    //   this.props.applyFilter({ type: 'region', refName: controls.region.value })
    // } else if (controls.zone.value) {
    //   this.props.applyFilter({ type: 'zone', refName: controls.zone.value })
    // } else {
    //   this.props.applyFilter({})
    // }
  }

  resetFilter = () => {
    let { controls } = this.state;
    controls.zone.value = '';
    controls.region.value = '';
    controls.area.value = '';
    controls.review_achievement.value = 'month'
    // controls.isAllIndia.value = true;
    this.setState({ controls, areaData: [], regionData: [] });
    this.props.applyFilter(this.state.controls);
  }

  // onChangeIsAllIndia = (e) => {
  //   let { controls } = this.state;
  //   controls.zone.value = '';
  //   controls.region.value = '';
  //   controls.area.value = false;
  //   controls.isAllIndia.value = !!e.target.value;
  //   this.setState({ controls, areaData: [], regionData: [] });
  // }

  render() {
    const { regionData, areaData, zoneData } = this.state;
    const { controls, dealerRole } = this.state;
    const { area, zone, region, review_achievement } = controls;
    const controlToDisplay = {
      zone: false,
      region: false,
      area: false
    }
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      controlToDisplay.zone = true;
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.ZTM_ROLE) {
      controlToDisplay.region = true;
      controlToDisplay.area = true;
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      controlToDisplay.area = true;
    }
    const zoneRadioButton = zoneData.map(z =>
      <Fragment>
        <label class="container-rb">{z.business_name}
          <input type="radio" name="zone" value={z.uuid}
            onChange={(e) => { this.onZoneChange(z.uuid, z.business_name) }}
            checked={zone.value === z.uuid}
          />
          <span class="checkmark-rb"></span>
        </label>&nbsp;&nbsp;
      </Fragment>
    )

    return (
      <div id="trade-scheme-filter">
        <>
          {this.state.isLoading && <CustomSpinner></CustomSpinner>}

          <Row style={{ marginTop: '15px', marginLeft: 0, marginRight: 0 }}>
            <Col>
              <FormGroup>
                <Label>Review Achievement</Label>
                <div>
                  <label class="container-rb">Month
                    <input type="radio" name="review_achievement" value="month"
                      onChange={this.handleInputChange}
                      checked={review_achievement.value === "month"}
                    />
                    <span class="checkmark-rb "></span>
                  </label>&nbsp;&nbsp;
                  <label class="container-rb">Quarter
                    <input type="radio" name="review_achievement" value="quarter"
                      onChange={this.handleInputChange}
                      checked={review_achievement.value === "quarter"}
                    />
                    <span class="checkmark-rb "></span>
                  </label>&nbsp;&nbsp;
                </div>
              </FormGroup>
            </Col>
          </Row>

          {
            controlToDisplay.zone === true &&
            <Row style={{ marginTop: '15px', marginLeft: 0, marginRight: 0 }}>
              <Col xl="12" xs="12" ms="12">
                <FormGroup>
                  <Label>Zone</Label>
                  <div>
                    {zoneRadioButton}
                  </div>
                </FormGroup>
              </Col>
            </Row>}
          {
            controlToDisplay.region === true &&
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col>
                <FormGroup>
                  <Label>Region</Label><br />
                  <select name="cars" className="form-control"
                    value={region.value}
                    onChange={(e) => { this.onChangeRegion(e) }}>
                    <option value="" disabled="true" selected>Select a region</option>
                    {
                      regionData.map((item, j) => (
                        <option className="text-capitalize" value={item.uuid} >{item.business_name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>}

          {
            controlToDisplay.area === true &&
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col>
                <FormGroup>
                  <Label>Territory</Label><br />
                  <select name="area" className="form-control" value={area.value}
                    onChange={(e) => { this.handleInputChange(e) }}>
                    <option value="" disabled="true" selected>Select an area</option>
                    {
                      areaData.map((item, j) => (
                        <option value={item.uuid} className="text-capitalize">{item.business_name}</option>
                      ))
                    }
                  </select>
                </FormGroup>
              </Col>
            </Row>}

          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col xl="12" xs="12" md="12" className="text-align-right mt-3" >
              <Button className="mb-1 mr-1 btn-pill action-button profile-cancel-button" color="dark" onClick={() => this.resetFilter()}>Reset</Button>&nbsp;&nbsp;
              <Button className="mb-1 mr-1 btn-pill action-button profile-save-button" color="warning" onClick={() => { this.applyFilter() }}>Apply</Button>
            </Col>
          </Row>
        </>
      </div>
    );
  }
}

export default TradeSchemeFilter; 