import React, { Component, Fragment } from "react";
import Ionicon from "react-ionicons";
import { Card, Row, Col, CardBody, FormGroup } from "reactstrap";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import _ from "lodash";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import PrimaryPcrPointsReport from "./PrimaryPcrPointsReport";
import PrimaryOfftakeSummary from "./PrimaryOfftakeSummary";
import PrimaryTwoThreeWheelerPointsReport from "./PrimaryTwoThreeWheelerPointsReport";
import PrimaryTruckBusPointsReports from "./PrimaryTruckBusPointsReports";
import PrimaryLcvPointsReport from "./PrimaryLcvPointsReport";
import PrimaryScvPointsReport from "./PrimaryScvPointsReport";
import PrimaryFarmPonitsReport from "./PrimaryFarmPonitsReport";
import PrimaryTruckBusRadialReports from "./PrimaryTruckBusRadialReports";

import DashboardService from "../../../services/DashboardService";
import PrimaryPointsReportService from "../../../services/PrimaryPointsReportService";
import StorageService from "../../../services/Storage";
import GoogleAnalticsService from "../../../services/GoogleAnalticsService";

import classnames from "classnames";
import {
  formatNumber,
} from "../../../utils/utils";

import { messages } from "../../../constants/constant.message";


// import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import "../PrimaryPointsReport.css";
import UserService from "../../../services/UserService";
import { Roles } from "../../../constants/constant.role";

const { NO_OFFTAKE_SUMMARY } = messages.REPORT;


let sapId = null;
let delearName = null;
class PrimaryPointsReport extends Component {
  primaryPointsReports = [];
  offTakeSummary = {
    totalEarnedPoints: 0,
    pointsSummary: [],
  };
  isMobile = false;
  constructor(props) {
    super(props);

    this.state = {
      userDetail: StorageService.getUserDetail(),
      totalPrimaryPoints: 0,
      totalPrivionalPoints: 0,
      totalPoints: 0,
      redeemEnable: false,
      redeemDays: 0,
      isLoading: false,
      manageUserLevel: [],
      currentUser: null,
      randomKey: null,
      dealerRole: null,
      displayLabels: null,
      yearOptions: [],
      year: null,
      year_name: null,
      headerYear: null,
      labels: {},
      totals: {},
      currentClassification: '',
    };
  }
  componentDidMount() {
    GoogleAnalticsService.pageView('Point Summary View');
    this.checkUserAgent();
    // let searchParam = this.props.location.search;
    const userDetail = StorageService.getUserDetail();
    const { type, display_type } = userDetail;
    this.setState({ dealerRole: type });
    if (this.props.from === 'page') {
      const query = new URLSearchParams(this.props.location.search);
      sapId = query.get("id");
      delearName = query.get("name");
      const id = query.get("k");
      const year = query.get("y");

      // classification name
      if (sapId) {


        this.setState({ isLoader: true });
        let userObj = {
          id: sapId || "",
        }
        UserService.getUserInfo(userObj)
          .then((data) => {
            const userData = data.data.data;
            this.setState({ currentClassification: userData.display_type })
          })
          .catch((e) => {
            console.error("e", e);
            this.setState({ isLoader: false });
          });

      } else {
        this.setState({ currentClassification: display_type })
      }

      if (year && year !== "null") {
        this.setState({ year });
      }
      let manageUserLevel = [];
      let currentUser = null;
      if (id) {
        manageUserLevel = StorageService.getNavigationLevelDetail(id);
        if (manageUserLevel.length > 0) {
          currentUser = manageUserLevel[manageUserLevel.length - 1];
          if (currentUser && currentUser.uuid) {
            sapId = currentUser.uuid;
            delearName = currentUser.name;
          }
        }
        this.setState({ currentUser, manageUserLevel, randomKey: id });
      }
    }
    if (this.props.from === 'modal') {
      if (this.props.sapId) {
        sapId = this.props.sapId
      }
    }
    this.filterAPI();
    // if (searchParam) {
    //   let keys = searchParam.split('&');
    //   let idString = keys[0];
    //   let nameString = keys[1];
    //   let id = idString.split('=');
    //   sapId = id[1];

    //   let name = nameString ? nameString.split('=') : null;
    //   delearName = name ? decodeURI(name[1]) : null;

    // }

    // this.getPrimaryPoints();
    // this.getDelaerReport();
    // this.getRedeemData();
    // this.getOfftakeSummary();
    // this.getDisplayLabel();
  }

  checkUserAgent() {
    this.isMobile = false;
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.isMobile = true;
      return "mobile";
    } else if (/Chrome/i.test(ua)) {
      return "chrome";
    } else {
      return "other";
    }
  }

  sumPcrOfftakeAndPoints(arr) {
    let offtake = 0;
    let totalPoints = 0;
    let totalEligibleOfftake = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints +=
        item.pcr1213Points +
        item.pcr1416Points +
        item.pcrPremiumPoints +
        item.consistencyBonusPoints +
        item.slabJumpPoints +
        item.schemePoints +
        item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  sumFarmOfftakeAndPoints(arr) {
    let offtake = 0;
    let totalPoints = 0;
    let totalEligibleOfftake = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints +=
        item.shresthPoints +
        item.sonaPoints +
        item.prithviPoints +
        item.consistencyBonusPoints +
        item.slabJumpPoints +
        item.schemePoints +
        item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  sumOfTbbOfftakePoints(arr) {
    let offtake = 0;
    let totalPoints = 0;
    let totalEligibleOfftake = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints += item.biasPointsTotal;
      // totalPoints += item.jrmPoints
      //   + item.jxmPoints + item.jrxPoints +
      //   item.jxlPoints + item.xlmPoints +
      //   item.consistencyBonusPoints + item.slabJumpPoints +
      //   item.schemePoints + item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  sumOfTbrOfftakePoints(arr) {
    let offtake = 0;
    let totalPoints = 0;
    let totalEligibleOfftake = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints +=
        item.radialPointsTotal +
        item.approvalPoints +
        item.schemePoints +
        item.consistencyBonusPoints +
        item.slabJumpPoints;
      // totalPoints +=
      //   item.depPoints + item.jdyPoints +
      //   item.uh5Points + item.consistencyBonusPoints +
      //   item.slabJumpPoints + item.schemePoints +
      //   item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  sumLcvOfftakeAndPoints(arr) {
    let offtake = 0;
    let totalPoints = 0;
    let totalEligibleOfftake = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints +=
        item.biasPoints +
        item.radialPoints +
        item.consistencyBonusPoints +
        item.slabJumpPoints +
        item.schemePoints +
        item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  sumTwoThreeWheelOfftakeAndPoints(arr) {
    let offtake = 0;
    let totalEligibleOfftake = 0;
    let totalPoints = 0;
    arr.forEach((item) => {
      offtake += item.totalWholeMonthSales;
      totalEligibleOfftake += item.eligibleSales;
      totalPoints +=
        item.motorCyclePoints +
        item.scooterPoints +
        item.threeWheelersPoints +
        item.consistencyBonusPoints +
        item.slabJumpPoints +
        item.schemePoints +
        item.approvalPoints;
    });
    return { offtake, totalPoints, totalEligibleOfftake };
  }

  async getDelaerReport(year) {
    try {
      this.setState({ isLoader: true });
      const { data } = await PrimaryPointsReportService.getDelearReport(
        sapId,
        year
      );
      if (data) {
        let res = data.data;
        this.primaryPointsReports = [];
        if (this.props.from === 'modal') {
          this.props.setModalTitle(res.duration_label);
        }
        if (data.data && data.data.labels) {
          this.setState({ labels: data.data.labels })
        }
        if (data.data && data.data.totals) {
          this.setState({ totals: data.data.totals })
        }
        if (data.data && data.data.q1) {
          data.data.q1.quarter = 1;
          this.primaryPointsReports.push(data.data.q1);
        }
        if (data.data && data.data.q2) {
          data.data.q2.quarter = 2;
          this.primaryPointsReports.push(data.data.q2);
        }
        if (data.data && data.data.q3) {
          data.data.q3.quarter = 3;
          this.primaryPointsReports.push(data.data.q3);
        }
        if (data.data && data.data.q4) {
          data.data.q4.quarter = 4;
          this.primaryPointsReports.push(data.data.q4);
        }
        this.primaryPointsReports = this.primaryPointsReports.reverse();
        if (this.primaryPointsReports.length === 5) {
          this.primaryPointsReports.pop();
        }
        // this.setState({ isLoader: false });
      } else {
        // this.setState({ isLoader: false });
      }
      this.primaryPointsReports.forEach((element) => {
        //Sum offtake And Totalpoints

        //PCR
        let result = this.sumPcrOfftakeAndPoints(element.data.report.pcr);
        element.pcrOfftake = result.offtake;
        element.pcrTotalPoints = result.totalPoints;
        element.pcrTotalEligibleOfftake = result.totalEligibleOfftake;

        //FARM
        result = this.sumFarmOfftakeAndPoints(element.data.report.farm);
        element.farmOfftake = result.offtake;
        element.farmTotalPoints = result.totalPoints;
        element.farmTotalEligibleOfftake = result.totalEligibleOfftake;

        // //LCV
        result = this.sumLcvOfftakeAndPoints(element.data.report.lcv);
        element.lcvOfftake = result.offtake;
        element.lcvTotalPoints = result.totalPoints;
        element.lcvTotalEligibleOfftake = result.totalEligibleOfftake;

        //SCV
        result = this.sumLcvOfftakeAndPoints(element.data.report.scv);
        element.scvOfftake = result.offtake;
        element.scvTotalPoints = result.totalPoints;
        element.scvTotalEligibleOfftake = result.totalEligibleOfftake;

        //truck/bus TBB
        result = this.sumOfTbbOfftakePoints(element.data.report.tbb);
        element.tbbOfftake = result.offtake;
        element.tbbTotalPoints = result.totalPoints;
        element.tbbTotalEligibleOfftake = result.totalEligibleOfftake;

        //truck/bus TBR
        result = this.sumOfTbrOfftakePoints(element.data.report.tbr);
        element.tbrOfftake = result.offtake;
        element.tbrTotalPoints = result.totalPoints;
        element.tbrTotalEligibleOfftake = result.totalEligibleOfftake;

        //twoWheeler
        result = this.sumTwoThreeWheelOfftakeAndPoints(
          element.data.report.two_three_wheelers
        );
        element.twoWheelerOfftake = result.offtake;
        element.twoWheelerTotalPoints = result.totalPoints;
        element.twoWheelerTotalEligibleOfftake = result.totalEligibleOfftake;
      });
      this.setState((prevState) => {
        return { ...prevState };
      });
    } catch (error) {
      console.log(error);
    }
  }

  getPrimaryPoints = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getPrimaryPoints(sapId, year)
      .then((data) => {
        const res = data.data.data;

        const primaryPoints = res.primaryPoints;
        const provisionalPoints = res.provisionalPoints;
        // this.setState({ isLoader: false });
        // const year = res.year;
        this.setState((prevState) => {
          return {
            ...prevState,
            totalPrimaryPoints: primaryPoints,
            totalPrivionalPoints: provisionalPoints,
            totalPoints: primaryPoints + provisionalPoints,
          };
        });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
      });
  };

  getOfftakeSummary = (year) => {
    this.setState((prevState) => {
      return { ...prevState, isLoading: true };
    });
    this.setState({ isLoader: true });
    PrimaryPointsReportService.getOfftakeSummary(sapId, year)
      .then((data) => {
        this.offTakeSummary = {
          totalEarnedPoints: 0,
          pointsSummary: data.data.data,
        };
        // this.setState({ isLoader: false });
        this.offTakeSummary.pointsSummary = _.filter(
          this.offTakeSummary.pointsSummary,
          (element) => {
            return (
              element.label.indexOf("uarter") !== -1 || element.points !== 0
            );
          }
        );
        this.setState((prevState) => {
          return { ...prevState, isLoader: false };
        });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
        this.setState((prevState) => {
          return { ...prevState, isLoading: false };
        });
      });
  };

  redirectToAbout = () => {
    this.props.history.push("/about");
  };

  getRedeemData = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getRedeemData(year)
      .then((data) => {
        const {
          advantageMessage = null,
          redeemEnable,
          redeemDays = 0,
        } = data.data.data;
        const redeemData = data.data.data;
        this.setState({
          advantageMessage,
          redeemEnable,
          redeemDays,
          redeemData,
        });
        // this.setState({ isLoader: false });
        // const { redeemEnable, redeemDays = 0 } = data.data.data;
        // this.setState((prevState) => {
        //   return { ...prevState, redeemEnable: redeemEnable, redeemDays: redeemDays };
        // });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
      });
  };

  getDisplayLabel = (year) => {
    this.setState({ isLoader: true });
    DashboardService.getDisplayLabel(year)
      .then((data) => {
        const labels = data.data && data.data.data ? data.data.data : null;
        // this.setState({ isLoader: false });
        this.setState({ displayLabels: labels });
      })
      .catch((e) => {
        this.setState({ isLoader: false });
      });
  };

  gotoPrimaryReportAllPage = () => {
    this.props.history.push(`/offtake-score-card-report?y=${this.state.year}`);
  };

  getDataFromNavigation = (index) => {
    let { randomKey,
      // manageUserLevel
    } = this.state;
    // manageUserLevel = manageUserLevel.slice(0, index)
    // const userData = manageUserLevel[index - 1];
    // if (userData && userData.uuid) {
    this.props.history.push(
      `/offtake-score-card-report?id=${randomKey}&i=${index}&y=${this.state.year}`
    );
    // let uuid = userData.uuid;
    // this.setState({ page: 1, currentUser: userData, manageUserLevel });
    // this.getAllDealerReport(1, null, null, uuid);
    // }
  };

  getPageTitle = () => {
    const { dealerRole } = this.state;
    if (dealerRole === Roles.HEAD_OFFICE_ROLE) {
      return "Head Office";
    } else if (dealerRole === Roles.ZONE_MANAGER_ROLE) {
      return "Zone Manager";
    } else if (dealerRole === Roles.ZTM_ROLE) {
      return "Zonal Trade Marketing";
    } else if (dealerRole === Roles.REGION_MANAGER_ROLE) {
      return "Region Manager";
    } else if (dealerRole === Roles.SALES_OFFICER_ROLE) {
      return "Sales Officer";
    } else {
      return "Territory Manager";
    }
  };

  redeemAction = (action) => {
    if (action === "pcr-scheme") {
      this.props.history.push("/trade-scheme");
    }
    if (action === "gift-catalogue") {
      this.props.history.push("/redemption-catalog");
    }
  };

  filterAPI = () => {
    const { year } = this.state;

    let filter = {
      y: year ? year : null,
    };
    this.setState({ isLoader: true });
    DashboardService.filterAPI(filter)
      .then((res) => {
        if (res.data.data) {
          // this.setState({ isLoader: false });

          this.setState(
            {
              yearOptions: res.data.data.year ? res.data.data.year : [],
            },
            () => {
              if (
                !this.state.year &&
                !this.state.headerYear &&
                this.state.yearOptions &&
                this.state.yearOptions[0]
              ) {
                this.setState(
                  {
                    year: this.state.yearOptions[0].value,
                    year_name: this.state.yearOptions[0].name,
                  },
                  () => {
                    this.getPrimaryPoints(this.state.year);
                    this.getDelaerReport(this.state.year);
                    this.getRedeemData(this.state.year);
                    this.getOfftakeSummary(this.state.year);
                    this.getDisplayLabel(this.state.year);
                  }
                );
              } else {
                this.getPrimaryPoints(this.state.year);
                this.getDelaerReport(this.state.year);
                this.getRedeemData(this.state.year);
                this.getOfftakeSummary(this.state.year);
                this.getDisplayLabel(this.state.year);
              }
            }
          );
        } else {
          this.setState({ isLoader: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        console.log("err", err);
      });
  };

  onChangeYear = (e) => {
    this.setState(
      {
        year: e && e.target && e.target.value,
      },
      () => {
        // this.filterAPI();

        this.getPrimaryPoints(this.state.year);
        this.getDelaerReport(this.state.year);
        this.getRedeemData(this.state.year);
        this.getOfftakeSummary(this.state.year);
        this.getDisplayLabel(this.state.year);
      }
    );
  };

  render() {
    const pageTitle = this.getPageTitle();
    const {
      redeemData,
      advantageMessage,
      dealerRole,
      displayLabels,
      labels,
      totals,
      // userDetail,
      currentClassification
    } = this.state;

    const navigation =
      this.state.manageUserLevel.length > 0 ? (
        this.state.manageUserLevel.map((m, index) => (
          <Fragment>
            <span>
              <Ionicon
                icon="ios-arrow-forward"
                fontSize="34px"
                color="#fdbb1f"
              />
            </span>
            {m.role !== "dealer" && (
              <span
                className="prev-page-link cursor-pointer title-case"
                onClick={this.getDataFromNavigation.bind(this, index + 1)}
              >
                {`Offtake scorecard of ${m.name} (${m.role})`}
              </span>
            )}
            {m.role === "dealer" && (
              <span className="current-page title-case">
                {`Report of ${m.name}`}
              </span>
            )}
          </Fragment>
        ))
      ) : (
        <Fragment>
          <span>
            <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
          </span>
          <span className="current-page title-case">
            Report of {delearName}
          </span>
        </Fragment>
      );

    return (
      <div id="primary-point-report">
        {this.state.isLoader && <CustomSpinner />}
        {this.props.from === 'page' && sapId && (
          <Row>
            <Col>
              <span
                className="prev-page-link cursor-pointer title-case"
                onClick={this.gotoPrimaryReportAllPage}
              >
                Offtake scorecard of {pageTitle}
              </span>
              {navigation}
              {/* <span>
              <Ionicon icon="ios-arrow-forward" fontSize="34px" color="#fdbb1f" />
            </span>
            <span className="current-page">Report of {delearName}</span> */}
            </Col>
          </Row>
        )}
        {this.props.from === 'page' && <Row style={{ marginBottom: "-27px" }}>
          <Col xl="10" md="8" xs="12" className="Points-Report">
            Points Report
          </Col>
          {this.state.yearOptions.length > 1 && (
            <Col xl="2" md="4" xs="12" style={{ paddingLeft: "0px" }}>
              <Row>
                <div className="fin-label">Financial Year</div>
                &nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto",
                    }}
                    onChange={(e) => {
                      this.onChangeYear(e);
                    }}
                  >
                    {this.state.yearOptions.map((item) => (
                      <option value={item.value} selected>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col>
          )}
        </Row>}
        {this.props.from === 'page' && dealerRole === "dealer" && advantageMessage && <br />}
        {this.props.from === 'page' && dealerRole === "dealer" && advantageMessage && (
          <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
            <CardBody
              className={`gradient-title ${redeemData.action ? "cursor-pointer" : ""
                }`}
              onClick={
                redeemData.action
                  ? this.redeemAction.bind(this, redeemData.action)
                  : null
              }
            >
              <Row>
                <Col>
                  <div className="gradient-sub-title">{advantageMessage}</div>
                  {/* <span className="gradient-sub-title">Your current achievement for PCR trade scheme is 100%</span> */}
                </Col>
                {/* <Col className="text-align-right">
              <span className="gradient-sub-title">Check your eligibility for PCR trade scheme now!</span>
            </Col> */}
              </Row>
            </CardBody>
          </Row>
        )}

        {this.props.from === 'page' && <>
          <div className="points-card-wrapp">
            <div
              className={classnames(
                { "mr-3": !this.isMobile },
                "points-card total-points-card"
              )}
            >
              <div className="points-img-wrapp">
                <img src="images/Web_Total Points.svg" alt="" />
              </div>
              <div className="points-data-wrapp">
                <span className="points-label total-point-lable">
                  Total Points
                </span>
                <span className="points" style={{ color: "#495057" }}>
                  {formatNumber(this.state.totalPoints)}
                </span>
              </div>
            </div>

            <div
              className={classnames({ "mr-3": !this.isMobile }, "points-card")}
              style={{ flexWrap: "nowrap" }}
            >
              <div className="points-img-wrapp">
                <img src="images/Web_Earned Points.svg" alt="" />
              </div>
              <div className="points-data-wrapp" style={{ width: "100%" }}>
                <span
                  className="points-label earned-label"
                  style={{ width: "100%" }}
                >
                  Points (
                  {displayLabels && displayLabels.year_till_previous_quarter})
                </span>
                <span className="points">
                  {" "}
                  {formatNumber(this.state.totalPrimaryPoints)}
                </span>
              </div>
            </div>

            <div className="points-card" style={{ flexWrap: "nowrap" }}>
              <div className="points-img-wrapp">
                <img src="images/Web_Earned points Q2.svg" alt="" />
              </div>
              <div
                className="points-data-wrapp"
                style={{
                  width: "100%",
                  paddingRight: this.isMobile ? "0px" : "15px",
                }}
              >
                <span
                  className="points-label Provisional-label"
                  style={{ width: "100%" }}
                >
                  Provisional Points ({displayLabels && displayLabels.quarter})
                </span>
                <span className="points">
                  {formatNumber(this.state.totalPrivionalPoints)}
                </span>
                <span className="These-Points-are-pr">
                  *These Points are provisional. They will get added to your total
                  earned points at the end of Q2 if you are eligible.
                </span>
              </div>
            </div>
          </div>
        </>}

        {/* {userDetail && userDetail.display_type && */}
        {this.props.from === 'page' && currentClassification &&

          <div className="gradient-title gradientBgImg mb-2">
            <Row>
              <Col>
                <div className="gradient-sub-title" style={{ padding: '15px' }}>
                  <b>Current Classification: </b> {" "}
                  {/* {userDetail.display_type} */}
                  {currentClassification}
                </div>
              </Col>
            </Row>
          </div>

        }

        {this.props.from === 'page' && <div>
          <span className="Youre-yet-to-reach">
            You’re yet to reach your turnover goal.{" "}
            <b
              onClick={this.redirectToAbout}
              className="Learn-more-about"
              style={{ color: "#495057" }}
            >
              Learn more about how you can reach the goal quicker
            </b>
          </span>
        </div>}
        {this.props.from === 'modal' && <div>
          {this.state.yearOptions.length > 1 && (
            <Col style={{ paddingLeft: "0px" }}>
              <Row className="year-filter-position-modal">
                <div className="fin-label">Financial Year</div>&nbsp;
                <FormGroup>
                  <select
                    name="month"
                    className="form-control cursor-pointer"
                    value={this.state.year}
                    style={{
                      fontSize: "13px",
                      width: "110px",
                      color: "#6e777f",
                      marginLeft: "auto",
                    }}
                    onChange={(e) => {
                      this.onChangeYear(e);
                    }}
                  >
                    {this.state.yearOptions.map((item) => (
                      <option value={item.value} selected>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </Col>
          )}
        </div>}
        <div>
          <Tabs
            defaultActiveKey="0"
            className="primary-report-tabs"
            renderTabBar={() => (
              <ScrollableInkTabBar
                extraContent={
                  <></>
                  //   <div className="d-flex-row Download-Full-Report-wrapp">
                  //     <img src="images/group-6.svg" alt="" />
                  //     &nbsp;
                  // <span className="Download-Full-Report">Download Full Report</span>
                  //   </div>
                }
              />
            )}
            renderTabContent={() => <TabContent />}
          >
            <TabPane tab="Points Summary" key="0" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.offTakeSummary &&
                this.offTakeSummary.pointsSummary &&
                this.offTakeSummary.pointsSummary.length > 0 && (
                  <>
                    <PrimaryOfftakeSummary
                      offTakeSummary={this.offTakeSummary}
                      totalEarnedPoints={this.state.totalPrimaryPoints + this.state.totalPrivionalPoints}
                    />
                  </>
                )}

              {!(this.offTakeSummary &&
                this.offTakeSummary.pointsSummary &&
                this.offTakeSummary.pointsSummary.length > 0) && (
                  <>
                    <Card className="text-align-center">
                      <CardBody>
                        {NO_OFFTAKE_SUMMARY}
                      </CardBody>
                    </Card>
                  </>
                )}

            </TabPane>
            <TabPane tab="PCR" key="1" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryPcrPointsReport
                    primaryPointsReports={this.primaryPointsReports}
                    year={this.state.year}
                    pcrPoints={this.pcrPoints}
                    labels={labels.pcr}
                    totals={totals}
                  />
                )}
            </TabPane>

            <TabPane tab="TBB" key="2" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryTruckBusPointsReports
                    truckBusPoints={this.primaryPointsReports}
                    labels={labels.tbb}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
            <TabPane tab="TBR" key="3" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryTruckBusRadialReports
                    truckBusPoints={this.primaryPointsReports}
                    labels={labels.tbr}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
            <TabPane tab="LCV" key="4" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryLcvPointsReport
                    lcvPoints={this.primaryPointsReports}
                    labels={labels.lcv}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
            <TabPane tab="SCV" key="5" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryScvPointsReport
                    scvPoints={this.primaryPointsReports}
                    labels={labels.scv}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
            <TabPane tab="2 / 3 Wheeler" key="6" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryTwoThreeWheelerPointsReport
                    twoThreeWheelerPoints={this.primaryPointsReports}
                    labels={labels.two_three_wheelers}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
            <TabPane tab="Farm" key="7" style={this.props.from === 'modal' ? { overflowY: 'auto', height: '67vh' } : {}}>
              {this.primaryPointsReports &&
                this.primaryPointsReports.length > 0 && (
                  <PrimaryFarmPonitsReport
                    farmPoints={this.primaryPointsReports}
                    labels={labels.farm}
                    totals={totals}
                    year={this.state.year}
                  />
                )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default PrimaryPointsReport;
